import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { resources, defaultLanguage, defaultNS } from "@interface/i18n";
import { getLastUsedLanguage } from "@helpers/localStorageHelpers";

const savedLang = getLastUsedLanguage();

i18n.use(initReactI18next).init({
  resources,
  compatibilityJSON: "v3",
  defaultNS: defaultNS,
  lng: savedLang || defaultLanguage,
  fallbackLng: defaultLanguage,
  keySeparator: ".",
  debug: false,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
});

export default i18n;
