import { CalendarDateFormat } from "@interface/formats";
import {
  getDescriptionValue,
  getNameValue,
  isBaselineStudyEvent
} from "@interface/helpers";
import {
  MetaDataVersion,
  StudyEventData,
  StudyEventDef,
  StudyEventType,
  StudyLanguage,
  User,
  UserRole
} from "@interface/types";
import moment from "moment";
import { getStudyEventRTCDetails } from "./durationHelpers";
import { isEventComplete } from "./subjectHelpers";

export type CalendarInvite = {
  description: string;
  duration: number;
  endDatetime: string;
  startDatetime: string;
  title: string;
};

export type CurrentEventDetails = {
  idStudyEvent: number;
  studyEventName: string;
  studyEventDescription: string;
  studyEventRepeatKey?: string;
  isBaselineEvent: boolean;
  idForm?: number;
  eventStartable: boolean;
  continueEvent: boolean;
  studyEventStartableLater: boolean;
  boundByTimingConstraint: boolean;
  startDate: Date;
  studyEventCalendarInvite: CalendarInvite;
};

export const isParticipantUser = (user: User): boolean => {
  // Check if this user is a master admin
  if (user.super) {
    return false;
  }
  // If the user is not enrolled in any workspace, default to study user.
  if (user.workspaceRoles.length === 0) {
    return false;
  }
  //This user must be a participant for all their workspaces to be considered a partcicipant
  return user.workspaceRoles.every((wsRole) => {
    return wsRole.userRole.userRole === UserRole.StudyParticipant;
  });
};

export const getCurrentEventDetails = (
  metaDataVersion: MetaDataVersion,
  studyEventsData: StudyEventData[],
  subjectLocked: boolean,
  primaryLanguageTag: string,
  gasFormId: number,
  currentLanguage?: StudyLanguage
): CurrentEventDetails | undefined => {
  for (const studyEventRef of metaDataVersion.Protocol.StudyEventRefs) {
    const studyEventDatum: StudyEventData | undefined = studyEventsData.find(
      (studyEventDatum: StudyEventData) => {
        return studyEventDatum.idStudyEvent === studyEventRef.idStudyEvent;
      }
    );
    const studyEventDef = metaDataVersion.StudyEventDefs.find(
      (studyEventDef: StudyEventDef) => {
        return (
          studyEventDef.idStudyEvent === studyEventRef.idStudyEvent &&
          studyEventDef.Type === StudyEventType.Scheduled
        );
      }
    );

    if (!studyEventDef) {
      // throw new Error("StudyEventDef not found for StudyEventRef");
      continue;
    }

    if (
      studyEventDatum &&
      isEventComplete(studyEventDatum, studyEventDef, metaDataVersion)
    ) {
      // If event is already completed then proceed and skip to the next
      continue;
    }

    //if (isTimingDurationViolated)

    const continueEvent: boolean = (() => {
      if (!studyEventDatum) {
        return false;
      }
      // If event was complete we would not have reached here hence the event
      // is not complete therefore return true
      return true; // !isEventComplete(studyEventDatum, studyEventDef);
    })();

    const {
      startDate,
      studyEventStartableNow,
      studyEventStartableLater,
      boundByTimingConstraint
    } = getStudyEventRTCDetails(
      metaDataVersion.Protocol.TimingConstraints,
      studyEventsData,
      studyEventDef.idStudyEvent
    );

    if (!studyEventStartableLater) {
      // If this event had a PostWindow and can no longer be started continue to the next event
      continue;
    }

    const eventStartable: boolean = (() => {
      switch (true) {
        // Check if subject is locked
        case subjectLocked:
          return false;
        // Check if study event cannot be started now or later
        case !studyEventStartableNow:
          return false;
        default:
          return true;
      }
    })();

    const studyEventName = getNameValue(
      studyEventDef.NameTranslations,
      studyEventDef.Name,
      currentLanguage
    );

    const studyEventDescription = getDescriptionValue(
      studyEventDef.Descriptions,
      primaryLanguageTag,
      currentLanguage
    );

    const calendarStartDate = moment(startDate);

    const calendarEndDate = calendarStartDate.clone().add(30, "minutes");
    const duration = moment
      .duration(calendarEndDate.diff(calendarStartDate))
      .asHours();

    // Build the event Link
    const eventLink = window.location.href.replace(
      "overview",
      `studyevent/${studyEventDef.idStudyEvent}`
    );

    const studyEventCalendarInvite: CalendarInvite = {
      description: `${studyEventDescription}<br/><a href=${eventLink}>Click Here to Start!</a> `,
      duration,
      endDatetime: calendarEndDate.format(CalendarDateFormat),
      startDatetime: calendarStartDate.format(CalendarDateFormat),
      title: `myGoalNav: ${studyEventName}`
    };

    const isBaselineEvent = isBaselineStudyEvent(
      studyEventDef.idStudyEvent,
      gasFormId,
      metaDataVersion.StudyEventDefs
    );

    return {
      idStudyEvent: studyEventDef.idStudyEvent,
      studyEventName,
      studyEventDescription,
      studyEventRepeatKey: studyEventDatum?.RepeatKey,
      isBaselineEvent,
      idForm: isBaselineEvent ? gasFormId : undefined,
      eventStartable,
      studyEventStartableLater,
      continueEvent,
      boundByTimingConstraint,
      startDate,
      studyEventCalendarInvite
    };
  }

  return undefined;
};
