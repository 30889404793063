import LoadingSpinner from "@client/components/LoadingSpinner";
import React, { useCallback, useMemo, useState } from "react";

type Props = {
  children?: JSX.Element | JSX.Element[];
};

type ContextProps = {
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
};

const LoadingSpinnerContext = React.createContext<ContextProps>({
  isLoading: false,
  setLoading: () => {
    throw new Error(
      `Cannot be completed as Loading Spinner provider has not initialized`
    );
  }
});

export const LoadingSpinnerProvider = ({ children }: Props) => {
  const [depth, setDepth] = useState<number>(0);

  const isLoading = depth > 0;

  const setLoading = useCallback(
    (isLoading: boolean) => {
      if (isLoading) {
        setDepth((prev) => prev + 1);
      } else {
        setDepth((prev) => (prev > 0 ? prev - 1 : 0));
      }
    },
    [setDepth]
  );

  const value = useMemo(() => {
    return { isLoading, setLoading };
  }, [isLoading, setLoading]);

  return (
    <LoadingSpinnerContext.Provider value={value}>
      {children}
      <LoadingSpinner isLoading={isLoading} />
    </LoadingSpinnerContext.Provider>
  );
};

export const useLoadingSpinner = (): ContextProps => {
  const context = React.useContext(LoadingSpinnerContext);
  if (context === undefined) {
    throw new Error(
      "useLoadingSpinner must be used within an LoadingSpinnerProvider"
    );
  }
  return context;
};
