import React from "react";
import {
  Outlet,
  Navigate,
  useLocation,
  useSearchParams
} from "react-router-dom";
import routes, { SSO_STATE, SSO_STATE_VERIFY_PARAM } from "@routes/routes";
import { useAuth } from "@providers";
import { isParticipantUser } from "@helpers/participantHelpers";

type LocationState = {
  from: {
    pathname: string;
  };
};

const PublicRoute = (): JSX.Element => {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const state = searchParams.get(SSO_STATE);

  const stateValues = new URLSearchParams(state ?? "");

  let homeRoute: string;
  if (user && isParticipantUser(user)) {
    homeRoute = routes.participant;
  } else {
    homeRoute = routes.studies;
  }
  const location = useLocation();

  let { from } = (location.state as LocationState) || {
    from: { pathname: homeRoute }
  };
  if (!from) {
    from = { pathname: homeRoute };
  }

  //When the SSO verification popup is open, we dont want to see a flash of the full site before it closes.
  const ssoVerificationInProgress =
    location.pathname === routes.ssoRedirect &&
    stateValues.get(SSO_STATE_VERIFY_PARAM) === "true";

  return user && !ssoVerificationInProgress ? (
    <Navigate to={from} />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
