import { useConfirmation, useStudy } from "@client/providers";
import {
  EVENT_LEVEL_QUERY,
  FORM_LEVEL_QUERY,
  ITEM_GROUP_LEVEL_QUERY,
  SUBJECT_LEVEL_QUERY
} from "@interface/constants";
import { Annotation } from "@interface/types";
import { t } from "i18next";
import { useSearchParams as useSearchParamsRouterDom } from "react-router-dom";

export const useSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParamsRouterDom();

  const confirm = useConfirmation();
  const { study } = useStudy();

  const getSearchParam = (key: string) => {
    return searchParams.has(key)
      ? (searchParams.get(key) as string)
      : undefined;
  };

  const getSearchParamsURLString = () => {
    return searchParams.toString() ? `?${searchParams.toString()}` : "";
  };

  const updateSearchParam = (key: string, value: string) => {
    if (!searchParams.has(key)) {
      searchParams.append(key, value);
    }
    setSearchParams(searchParams);
  };

  const deleteSearchParam = (key: string) => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const deleteAllSearchParams = () => {
    searchParams.forEach((value: string, key: string) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
  };

  /** Deep Link Query Specific Functions **/
  const isQueryValid = (key: string, queries: Annotation[]) => {
    const paramValue = getSearchParam(key);
    if (!paramValue) {
      return false;
    }

    //If: we think the query exists AND it does
    //Or: we think the query does not exist AND it does not
    //Then: This is a valid state so return true
    if (
      (paramValue.includes("true") && queries.length > 0) ||
      (paramValue.includes("false") && queries.length === 0)
    ) {
      return true;
    }
    return false;
  };

  const noQueryExistsDialog = () => {
    return confirm({
      title: t("dataCollection.annotationDoesNotExistTitle"),
      body: t("dataCollection.annotationDoesNotExist", {
        supportEmail: study?.supportEmail
          ? study.supportEmail
          : t("forms:study.supportEmail")
      }),
      disableCancelButton: true
    })
      .catch((error) => console.log(error)) //catch for user clicking away from dialog without button
      .finally(() => {
        clearQuerySearchParams();
      });
  };

  const clearQuerySearchParams = () => {
    if (searchParams.has(SUBJECT_LEVEL_QUERY)) {
      searchParams.delete(SUBJECT_LEVEL_QUERY);
    }

    if (searchParams.has(EVENT_LEVEL_QUERY)) {
      searchParams.delete(EVENT_LEVEL_QUERY);
    }
    if (searchParams.has(FORM_LEVEL_QUERY)) {
      searchParams.delete(FORM_LEVEL_QUERY);
    }
    if (searchParams.has(ITEM_GROUP_LEVEL_QUERY)) {
      searchParams.delete(ITEM_GROUP_LEVEL_QUERY);
    }
    setSearchParams(searchParams);
  };

  return {
    searchParams,
    isQueryValid,
    updateSearchParam,
    getSearchParam,
    getSearchParamsURLString,
    deleteSearchParam,
    deleteAllSearchParams,
    clearQuerySearchParams,
    noQueryExistsDialog
  };
};
