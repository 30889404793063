import React, { useCallback, useEffect, useState } from "react";
import { defineAbility, subject } from "@casl/ability";
import {
  defineAbilityForStudy,
  StudyAbility,
  StudyActions
} from "@interface/auth";
import { useAuth } from "./AuthProvider";
import { useWorkspace } from "./WorkspaceProvider";
import { useStudy } from "./StudyProvider";

type ContextProps = {
  studyCan: (ability: StudyActions) => boolean;
  studyCannot: (ability: StudyActions) => boolean;
  studyAbility: StudyAbility | undefined;
};

export const StudyAbilityContext = React.createContext<ContextProps>({
  studyCan: () => {
    return false;
  },
  studyCannot: () => {
    return true;
  },
  studyAbility: undefined
});

export const StudyAbilityProvider = ({
  children
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { currentWorkspace } = useWorkspace();
  const { user } = useAuth();
  const { study } = useStudy();

  const [studyAbility, setStudyAbility] = useState<StudyAbility>(
    defineAbility(() => {
      return;
    }) as StudyAbility
  );

  const studyCan = useCallback(
    (ability: StudyActions): boolean => {
      if (!study) {
        return false;
      }

      return studyAbility.can(ability, subject("StudyDetails", study));
    },
    [study, studyAbility]
  );

  const studyCannot = useCallback(
    (ability: StudyActions): boolean => {
      if (!study) {
        return false;
      }

      return studyAbility.cannot(ability, subject("StudyDetails", study));
    },
    [study, studyAbility]
  );

  useEffect(() => {
    if (currentWorkspace && user && study && study.StudyUser) {
      defineAbilityForStudy(
        user,
        currentWorkspace.idWorkspace,
        study.OID,
        study.StudyUser
      ).then((ability) => setStudyAbility(ability.studyAbility));
    }
  }, [currentWorkspace, user, study]);

  return (
    <StudyAbilityContext.Provider
      value={{ studyCan, studyCannot, studyAbility }}
    >
      {children}
    </StudyAbilityContext.Provider>
  );
};

export const useStudyAbility = (): ContextProps => {
  const context = React.useContext(StudyAbilityContext);
  if (context === undefined) {
    throw new Error(
      "useStudyAbility must be used within an StudyAbilityProvider"
    );
  }
  return context;
};
