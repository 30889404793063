import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

const uidValidator =
  /([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/i;
const idMdvValidator = /([0-9]+)/;

// USING CUSTOM ROUTE TO VALIDATE IS A TEMPORARY FIX
// REACT ROUTER HAS SAID THEY ARE ADDING ADVANCED VALIDATION IN THE FUTURE
const ValidateRoute = (): JSX.Element => {
  const { inviteId, idWorkspace, studyId, idMetaDataVersion } = useParams();
  let validRoute = true;

  const validateParam = (param: string, validator: RegExp) => {
    if (validRoute) {
      return validator.test(param);
    }
    return false;
  };

  if (inviteId) {
    validRoute = validateParam(inviteId, uidValidator);
  }
  if (idWorkspace) {
    validRoute = validateParam(idWorkspace, uidValidator);
  }
  if (studyId) {
    validRoute = validateParam(studyId, uidValidator);
  }
  if (idMetaDataVersion) {
    validRoute = validateParam(idMetaDataVersion, idMdvValidator);
  }
  return (
    <>
      {validRoute ? (
        <Outlet />
      ) : (
        <Navigate
          to={"./"}
          state={{
            error: "Invalid Route"
          }}
        />
      )}
    </>
  );
};

export default ValidateRoute;
