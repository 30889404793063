export const openCenteredPopup = (
  url: URL,
  width: number,
  height: number,
  target?: string
) => {
  //https://stackoverflow.com/a/16861050
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const screenWidth = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const screenHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = screenWidth / window.screen.availWidth;
  const left = (screenWidth - width) / 2 / systemZoom + dualScreenLeft;
  const top = (screenHeight - height) / 2 / systemZoom + dualScreenTop;

  const options = `
  popup=true,
  width=${width / systemZoom},
  height=${height / systemZoom},
  top=${top},
  left=${left}`;

  return window.open(url, target, options);
};
