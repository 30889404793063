import React, { useState } from "react";

type StudySidebarContext = {
  studySidebar: boolean;
  setStudySidebar: (state: boolean) => void;
};

const StudySidebarContext = React.createContext<StudySidebarContext>({
  studySidebar: false,
  setStudySidebar: (state: boolean) => {
    throw new Error(
      `Cannot set ${state} as StudySidebarContext has not been initialized`
    );
  }
});

const StudySidebarProvider = ({
  children
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [studySidebar, setStudySidebar] = useState<boolean>(false);
  return (
    <StudySidebarContext.Provider value={{ studySidebar, setStudySidebar }}>
      {children}
    </StudySidebarContext.Provider>
  );
};

const useStudySideBar = (): StudySidebarContext => {
  const context = React.useContext(StudySidebarContext);
  if (context === undefined) {
    throw new Error(
      "useStudySidebar must be used within an StudySidebarContextProvider"
    );
  }
  return context;
};

export { StudySidebarContext, StudySidebarProvider, useStudySideBar };
