import React from "react";
import { Check } from "@mui/icons-material";
import { Avatar, Button, Paper, Theme, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles, useAuth } from "@client/providers";
import { AuthResponseUnverifiedEmailPayload } from "@interface/types";
import { getAuthCardStyle } from "@client/helpers/themeHelpers";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: getAuthCardStyle(theme),
  verifyHeader: {
    margin: theme.spacing(1)
  },
  confirmationIcon: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main
  },
  submit: {
    fontSize: "1.2em",
    margin: theme.spacing(3, 0, 2)
  }
}));

interface IUnverifiedEmailViewProps {
  responsePayload: AuthResponseUnverifiedEmailPayload;
}

const UnverifiedEmailView = ({
  responsePayload
}: IUnverifiedEmailViewProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { resendEmailVerification } = useAuth();

  return (
    <Paper className={classes.paper}>
      <Avatar className={classes.confirmationIcon}>
        <Check />
      </Avatar>
      <Typography
        component="h1"
        variant="h4"
        align="center"
        className={classes.verifyHeader}
      >
        {t("forms:signUp.verifyEmailHeader")}
      </Typography>
      <Trans
        i18nKey="forms:signUp.verifyEmailDescription"
        values={{ email: responsePayload.email }}
        components={{ bold: <strong />, t: <Typography paragraph /> }}
      />
      <Button
        onClick={() => {
          resendEmailVerification(responsePayload.email);
        }}
        variant="contained"
        color="secondary"
        className={classes.submit}
      >
        {t("actions.resendEmail")}
      </Button>
    </Paper>
  );
};
export default UnverifiedEmailView;
