import React from "react";
import {
  FormControl,
  FormLabel,
  OutlinedInput,
  FormHelperText,
  Typography
} from "@mui/material";
import { UseFormRegister } from "react-hook-form";
import selectors from "@interface/constants/selectors.json";
import { setSelectorOptions } from "@interface/helpers";

interface RHFInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
  register: UseFormRegister<any>; //eslint-disable-line @typescript-eslint/no-explicit-any -- RHF types are strange
  name: string;
  label: string;
  helperText?: string | JSX.Element;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  multiline?: boolean;
  fullWidth?: boolean;
  minRows?: number;
  maxRows?: number;
  InputProps?: { endAdornment?: JSX.Element; startAdornment?: JSX.Element };
  FormHelperTextProps?: { className: string };
  color?: "primary" | "secondary";
}

const RHFInput = ({
  register,
  name,
  label,
  helperText,
  error = false,
  disabled = false,
  required = false,
  multiline = false,
  fullWidth = false,
  InputProps: muiInputProps,
  FormHelperTextProps: muiFormHelperTextProps,
  ...rest
}: RHFInputProps): JSX.Element => {
  const { ref: inputRef, ...rhfInputProps } = register(name);
  return (
    <FormControl
      variant="outlined"
      error={error}
      disabled={disabled}
      required={required}
      fullWidth={fullWidth}
    >
      <FormLabel>
        <Typography variant="body1" component="span">
          {label}
        </Typography>
      </FormLabel>
      <OutlinedInput
        multiline={multiline}
        inputRef={inputRef}
        {...rest}
        {...muiInputProps}
        {...rhfInputProps}
      />
      <FormHelperText
        data-testid={setSelectorOptions(
          selectors.components.RHFInput.inputError,
          name
        )}
        {...muiFormHelperTextProps}
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default RHFInput;
