import React, { useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  Tabs,
  Tab,
  ButtonGroup,
  Menu,
  MenuItem
} from "@mui/material";
import {
  Delete,
  PostAdd,
  Beenhere,
  Notifications,
  Edit,
  ArrowDropDown
} from "@mui/icons-material";
import {
  Annotation,
  AnnotationOptions,
  AnnotationResult,
  AnnotationType
} from "@interface/types";
import { useConfirmation } from "@providers/ConfirmationServiceProvider";
import { useStudy, useStudyAbility, useStudyLang } from "@providers";
import { useSearchParams } from "@client/hooks/useSearchParams";
import { displayComments } from "@client/helpers/queryHelpers";
import QueryHistory from "@client/components/QueryHistory";

const AnnotationServiceContext = React.createContext<
  (options: AnnotationOptions) => Promise<AnnotationResult>
>(Promise.reject);

type Props = {
  children: JSX.Element | JSX.Element[];
};

const AnnotationServiceProvider = ({ children }: Props): JSX.Element => {
  const { study } = useStudy();
  const { t } = useTranslation();
  const confirm = useConfirmation();
  const [annotationOptions, setAnnotationOptions] =
    useState<AnnotationOptions>();
  const [commentText, setCommentText] = useState<string>("");
  const [editMode, setEditMode] = useState<number | undefined>();
  const [currentTab, setCurrentTab] = useState(0);
  const { studyCannot } = useStudyAbility();
  const { clearQuerySearchParams } = useSearchParams();
  const { currentLanguage, primaryLanguageTag } = useStudyLang();

  const awaitingPromiseRef = useRef<{
    resolve: (value: AnnotationResult | PromiseLike<AnnotationResult>) => void;
    reject: () => void;
  }>();

  const [resolveAnchorEl, setResolveAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const resolveOpen = (event: React.MouseEvent<HTMLElement>) => {
    setResolveAnchorEl(event.currentTarget);
  };

  const resolveClose = () => {
    setResolveAnchorEl(null);
  };

  const clearAllState = () => {
    setAnnotationOptions(undefined);
    setCommentText("");
    clearQuerySearchParams();
    setCurrentTab(0);
    setEditMode(undefined);
  };

  const openPicker = useCallback((options: AnnotationOptions) => {
    setAnnotationOptions(options);
    return new Promise<AnnotationResult>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const handleCancel = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    clearAllState();
  };

  const handleNotify = () => {
    if (!annotationOptions?.notifyCallback) {
      return;
    }

    confirm({
      title: t("dataCollection.annotationNotifyTitle"),
      body: t("dataCollection.annotationNotifyBody")
    })
      .then(() => {
        annotationOptions.notifyCallback!(
          annotationOptions.queryMode
            ? AnnotationType.Query
            : AnnotationType.Comment
        );
      })
      .catch(() => {
        /*Cancelled*/
      });
  };

  const handleResolve = (unresolvable: boolean) => {
    if (!awaitingPromiseRef.current || !annotationOptions) {
      return;
    }
    if (annotationOptions.readOnlyMode && !annotationOptions.queryMode) {
      awaitingPromiseRef.current.reject();
    } else {
      awaitingPromiseRef.current.resolve({
        commentText,
        resolveChecked: !unresolvable,
        unresolvableChecked: unresolvable
      });
    }

    clearAllState();
  };

  const handleConfirm = () => {
    if (!awaitingPromiseRef.current || !annotationOptions) {
      return;
    }

    if (annotationOptions.readOnlyMode && !annotationOptions.queryMode) {
      awaitingPromiseRef.current.reject();
    } else {
      awaitingPromiseRef.current.resolve({
        commentText,
        resolveChecked: false,
        annotationId: editMode
      });
    }

    clearAllState();
  };

  const handleRemove = (annotationId: number) => {
    if (!annotationOptions) {
      return;
    }
    const type = {
      type: annotationOptions.queryMode
        ? t("dataCollection.annotationQueryTitle")
        : t("dataCollection.annotationCommentTitle")
    };
    confirm({
      title: t("dataCollection.annotationDeleteTitle", type),
      body: t("dataCollection.annotationDeleteBody", type)
    })
      .then(() => {
        annotationOptions.removeAnnotation(
          annotationId,
          annotationOptions.queryMode
            ? AnnotationType.Query
            : AnnotationType.Comment
        );
        handleCancel();
      })
      .catch(() => {
        /*Cancelled*/
      });
  };

  const isConfirmDisabled = (): boolean => {
    if (!annotationOptions) {
      return true;
    }
    if (annotationOptions.readOnlyMode && !annotationOptions.queryMode) {
      return true;
    }
    if (annotationOptions.queryMode && studyCannot("respondToQuery")) {
      return true;
    }
    if (!annotationOptions.queryMode && studyCannot("addComment")) {
      return true;
    }

    return !commentText || commentText.trim() === "";
  };

  const isResolveDisabled = (): boolean => {
    if (!annotationOptions) {
      return true;
    }
    if (annotationOptions.resolveAllowed) {
      //return !commentText || commentText.trim() === "";
      return false;
    }

    return true;
  };

  const getAnnotationActionButtons = (a: Annotation) => {
    if (annotationOptions === undefined) {
      return null;
    }

    switch (a.Type) {
      case AnnotationType.Comment:
        return (
          <>
            <Tooltip title={t("dataCollection.annotationEdit") as string}>
              <IconButton
                size="small"
                onClick={() => {
                  setEditMode(a.id);
                  setCommentText(a.Comment);
                }}
                disabled={studyCannot("addComment")}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("dataCollection.annotationDelete") as string}>
              <IconButton
                size="small"
                onClick={() => handleRemove(a.id)}
                disabled={studyCannot("removeComment")}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        );
      case AnnotationType.Query:
        if (
          annotationOptions.currentUserID === a.userId &&
          (annotationOptions.queryMode || !annotationOptions.readOnlyMode)
        ) {
          return (
            <Tooltip title={t("dataCollection.annotationDelete") as string}>
              <IconButton
                size="small"
                onClick={() => handleRemove(a.id)}
                disabled={studyCannot("removeQuery")}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          );
        }
    }
    return null;
  };

  const getAnnotationDisplay = () => {
    if (!annotationOptions) {
      return null;
    }
    const sortedAnnotations = annotationOptions.annotations.sort((a, b) => {
      return new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime();
    });

    const comments = sortedAnnotations.map((a) => {
      return (
        <List key={a.id}>
          <ListItem alignItems="flex-start">
            {displayComments(a, primaryLanguageTag, currentLanguage, true)}
            <ListItemSecondaryAction>
              {getAnnotationActionButtons(a)}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      );
    });
    return annotationOptions.queryMode ? (
      getTabbedQueryDisplay(comments)
    ) : (
      <Grid container>
        <Grid item xs={12}>
          {comments}
          {comments.length === 0 ? (
            <Grid container>
              <span
                style={{
                  fontStyle: "italic",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                {t("dataCollection.noComments")}
              </span>
            </Grid>
          ) : null}
        </Grid>
        <Grid container item xs={12} style={{ paddingTop: "15px" }}>
          {getAddNewDisplay()}
        </Grid>
      </Grid>
    );
  };

  const getTabbedQueryDisplay = (comments: JSX.Element[]) => {
    if (!annotationOptions || !study) {
      return null;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            value={currentTab}
            onChange={(_, newTab: number) => setCurrentTab(newTab)}
            variant="fullWidth"
          >
            <Tab label={t("study.openQueries")} />
            <Tab label={t("dataCollection.history")} />
          </Tabs>
        </Grid>
        {getQueryTabContent(comments)}
      </Grid>
    );
  };

  const getQueryTabContent = (comments: JSX.Element[]) => {
    if (currentTab === 0) {
      return (
        <Grid container role="tabpanel">
          <Grid item xs={12}>
            {comments}
            {comments.length === 0 ? (
              <Grid container>
                <span
                  style={{
                    fontStyle: "italic",
                    paddingTop: "15px",
                    paddingBottom: "15px"
                  }}
                >
                  {t("dataCollection.noComments")}
                </span>
              </Grid>
            ) : null}
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: "15px" }}>
            {getAddNewDisplay()}
          </Grid>
        </Grid>
      );
    }

    if (currentTab === 1 && study && annotationOptions) {
      return (
        <QueryHistory
          OID={study.OID}
          idData={annotationOptions.idData}
          dataLevel={annotationOptions.dataLevel}
        />
      );
    }

    return null;
  };

  const getAddNewDisplay = () => {
    if (!annotationOptions) {
      return null;
    }
    if (annotationOptions.readOnlyMode && !annotationOptions.queryMode) {
      return null;
    }

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              placeholder={
                annotationOptions.queryMode
                  ? t("dataCollection.annotationAddNewQuery")
                  : t("dataCollection.annotationAddNew")
              }
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              inputProps={{ maxLength: 65000 }}
              fullWidth
              multiline
              minRows={2}
              maxRows={5}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </React.Fragment>
    );
  };

  const getDialogTitle = (): string => {
    if (!annotationOptions) {
      return "";
    }

    let title: string = annotationOptions.queryMode
      ? t("dataCollection.annotationQueryTitle")
      : t("dataCollection.annotationCommentTitle");

    if (annotationOptions.dataName) {
      title = `${title}: ${annotationOptions.dataName}`;
    }

    return title;
  };

  return (
    <>
      <AnnotationServiceContext.Provider value={openPicker}>
        {children}
      </AnnotationServiceContext.Provider>
      <Dialog
        data-testid="annotation-modal"
        fullWidth
        open={Boolean(annotationOptions)}
        onClose={handleCancel}
      >
        {annotationOptions ? (
          <React.Fragment>
            <DialogTitle data-testid="annotation-title">
              {getDialogTitle()}
            </DialogTitle>
            <Divider />
            <DialogContent>{getAnnotationDisplay()}</DialogContent>
            <DialogActions>
              <Button
                data-testid="annotation-cancel"
                onClick={handleCancel}
                variant="outlined"
              >
                {t("dataCollection.annotationCancel")}
              </Button>

              {annotationOptions &&
              annotationOptions.queryMode &&
              annotationOptions.annotations.length > 0 ? (
                <>
                  {annotationOptions.resolveAllowed &&
                  annotationOptions.notifyCallback !== undefined ? (
                    <Button
                      data-testid="annotation-notify"
                      onClick={handleNotify}
                      color="secondary"
                      variant="outlined"
                      disabled={false} //TODO disabled when all users are inactive
                      startIcon={<Notifications />}
                    >
                      {t("dataCollection.annotationNotify")}
                    </Button>
                  ) : null}
                  <ButtonGroup>
                    <Button
                      data-testid="annotation-resolve"
                      onClick={() => handleResolve(false)}
                      color="secondary"
                      variant="outlined"
                      disabled={isResolveDisabled()}
                      startIcon={<Beenhere />}
                    >
                      {t("dataCollection.annotationResolved")}
                    </Button>
                    <Button
                      data-testid="annotation-more-resolve-options"
                      color="secondary"
                      size="small"
                      onClick={resolveOpen}
                    >
                      <ArrowDropDown />
                    </Button>
                  </ButtonGroup>
                  <Menu
                    open={Boolean(resolveAnchorEl)}
                    anchorEl={resolveAnchorEl}
                    onClose={() => {
                      resolveClose();
                    }}
                  >
                    <MenuItem
                      data-testid="annotation-unresolvable"
                      disabled={
                        isResolveDisabled() ||
                        commentText === "" ||
                        commentText.trim() === ""
                      }
                      onClick={() => {
                        handleResolve(true);
                        resolveClose();
                      }}
                    >
                      {t("dataCollection.annotationUnresolvable")}
                    </MenuItem>
                  </Menu>
                </>
              ) : null}
              <Button
                data-testid="annotation-ok"
                onClick={handleConfirm}
                color="primary"
                variant="outlined"
                startIcon={<PostAdd />}
                disabled={isConfirmDisabled()}
              >
                {editMode
                  ? t("dataCollection.annotationEdit")
                  : t("dataCollection.annotationPost")}
              </Button>
            </DialogActions>
          </React.Fragment>
        ) : (
          <div></div>
        )}
      </Dialog>
    </>
  );
};

const useAnnotation = (): ((
  options: AnnotationOptions
) => Promise<AnnotationResult>) => React.useContext(AnnotationServiceContext);

export { AnnotationServiceProvider, useAnnotation };
