import React from "react";
import { Link, Typography, Theme } from "@mui/material";
import { CSSObject } from "tss-react";
import { makeStyles } from "@providers";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routes from "@routes/routes";

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "auto",
    color: theme.palette.grey["500"],
    height: theme.spacing(6),
    justifyContent: "center",
    alignItems: "flex-end",
    [theme.breakpoints.up("sm")]: {
      "& > span": { marginRight: theme.spacing(3) },
      "& > div": { marginLeft: theme.spacing(3) }
    }
  },
  captionText: {
    ...theme.typography.caption
  } as CSSObject,
  links: {
    "& > a:first-of-type": { marginRight: theme.spacing(1) },
    "& > a:last-of-type": { marginLeft: theme.spacing(1) },
    "@media print": {
      display: "none"
    }
  }
  // gnLogo: {
  //   /* center the svg inside container */
  //   marginTop: "auto",
  //   marginBottom: "auto",
  //   /* center the svg inside container */
  //   marginRight: theme.spacing(1),
  //   width: 23,
  //   height: 25
  // },
  // poweredBy: {
  //   display: "flex"
  // },
  // break: {
  //   flexBasis: "100%",
  //   height: 0
  // }
}));

const AppFooter = (): JSX.Element => {
  const { t } = useTranslation();

  const { classes } = useStyles();

  const year = new Date().getFullYear();

  return (
    <footer className={classes.wrapper}>
      {/* <div className={classes.poweredBy}>
        <GNLogo className={classes.gnLogo} viewBox="0 0 430 604" />
        <div>
          <Typography variant="caption">{t("footer.poweredBy")}</Typography>
          <Typography variant="subtitle2">GoalNav</Typography>
        </div>
      </div>
      <div className={classes.break}></div> */}
      <Typography variant="caption">
        &copy;{year} {t("footer.copyright")}
      </Typography>
      <div className={classes.links}>
        <Link
          component={RouterLink}
          to={routes.privacyPolicy}
          target="_blank"
          color="inherit"
          fontSize={12}
        >
          {t("privacy.header")}
        </Link>
        <Link
          component={RouterLink}
          to={routes.termsOfService}
          target="_blank"
          color="inherit"
          fontSize={12}
        >
          {t("tos.header")}
        </Link>
      </div>
    </footer>
  );
};
export default AppFooter;
