import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import routes from "@routes/routes";
import { useAuth } from "@providers";
import { isParticipantUser } from "@helpers/participantHelpers";

const ParticipantRoute = (): JSX.Element | null => {
  const { user } = useAuth();
  const userHasRequiredRole = user && isParticipantUser(user);

  if (!user) {
    return null;
  }

  return (
    <>
      {userHasRequiredRole ? (
        <Outlet />
      ) : (
        <Navigate
          to={!user ? routes.signin : routes.root}
          state={{
            from: !user ? location : undefined,
            error: !user
              ? "User is not logged in"
              : "User does not have required role"
          }}
        />
      )}
    </>
  );
};

export default ParticipantRoute;
