import {
  AnalyticsAttribute,
  AnalyticsEvent,
  AnalyticsMetric
} from "@interface/types";
import { Analytics } from "@aws-amplify/analytics";
import {
  EventAttributes,
  EventMetrics,
  AnalyticsEvent as PinpointEvent
} from "@aws-amplify/analytics/lib-esm/types";
import { useStudy } from "@client/providers";

export const useAnalytics = () => {
  const { study } = useStudy();
  //Used to add the hostname atribute to the passed event attributes
  const wrapAttributes = (attributes: EventAttributes | undefined) => {
    if (!attributes) {
      attributes = {};
    }
    attributes[AnalyticsAttribute.Host] = window.location.hostname;

    if (globalThis.LastEventType) {
      attributes[AnalyticsAttribute.LastEventType] = globalThis.LastEventType;
    }

    //If we are in a study context, add the apptype, if not already set.
    if (study && attributes[AnalyticsAttribute.AppType] === undefined) {
      attributes[AnalyticsAttribute.AppType] = study.AppType;
    }

    return attributes;
  };

  const wrapMetrics = (metrics: EventMetrics | undefined) => {
    if (!metrics) {
      metrics = {};
    }
    if (!globalThis.LastEventTimestamp) {
      metrics[AnalyticsMetric.TimeSinceLastEvent] = 0;
    } else {
      metrics[AnalyticsMetric.TimeSinceLastEvent] = Math.floor(
        (new Date().getTime() - globalThis.LastEventTimestamp) / 1000
      );
    }
    globalThis.LastEventTimestamp = new Date().getTime();
    // console.log(metrics); // debugging purposes only
    return metrics;
  };

  const sendEvent = async (
    eventType: AnalyticsEvent,
    attributes?: EventAttributes,
    metrics?: EventMetrics
  ) => {
    const event: PinpointEvent = {
      name: eventType,
      attributes: wrapAttributes(attributes),
      metrics: wrapMetrics(metrics)
    };
    Analytics.record(event);

    globalThis.LastEventType = eventType;

    //Events arent sent in local, so log it now so we can see it.
    if (process.env.NODE_ENV === "development") {
      console.debug(`Analytics Event Send: ${JSON.stringify(event)}`);
    }

    return;
  };

  return { sendEvent };
};
