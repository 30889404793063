import { StudyEventType } from "../types";
export const getSortedScheduledEventDefs = (studyEventDefs, studyEventRefs) => {
    return studyEventRefs
        .sort((a, b) => {
        if (a.OrderNumber === b.OrderNumber) {
            return 0;
        }
        if (!a.OrderNumber) {
            return 1;
        }
        if (!b.OrderNumber) {
            return -1;
        }
        return a.OrderNumber - b.OrderNumber;
    })
        .reduce((sortedStudyEventDefs, studyEventRef) => {
        const studyEventDef = studyEventDefs.find((studyEventDef) => {
            return (studyEventDef.idStudyEvent === studyEventRef.idStudyEvent &&
                studyEventDef.Type === StudyEventType.Scheduled);
        });
        if (!studyEventDef) {
            return sortedStudyEventDefs;
        }
        sortedStudyEventDefs.push(studyEventDef);
        return sortedStudyEventDefs;
    }, []);
};
export const getSortedFormDefs = (metaDataVersion, studyEventDef) => {
    return sortFormRefs(studyEventDef.FormRefs).reduce((sortedFormDefs, formRef) => {
        const formDef = metaDataVersion.FormDefs.find((formDef) => {
            return formDef.idForm === formRef.idForm;
        });
        if (!formDef) {
            return sortedFormDefs;
        }
        sortedFormDefs.push(formDef);
        return sortedFormDefs;
    }, []);
};
export const sortFormRefs = (formRefs) => {
    return formRefs.sort((a, b) => {
        if (a.OrderNumber && b.OrderNumber) {
            return a.OrderNumber - b.OrderNumber;
        }
        return 0;
    });
};
export const getSortedItemGroupDefs = (metaDataVersion, formDef) => {
    if (!formDef.ItemGroupRefs) {
        throw new Error("ItemGroupRefs are missing in FormDef");
    }
    return formDef.ItemGroupRefs.sort((a, b) => {
        if (a.OrderNumber && b.OrderNumber) {
            return a.OrderNumber - b.OrderNumber;
        }
        return 0;
    }).reduce((sortedItemGroupDefs, itemGroupRef) => {
        const itemGroupDef = metaDataVersion.ItemGroupDefs.find((itemGroupDef) => {
            return itemGroupDef.idItemGroup === itemGroupRef.idItemGroup;
        });
        if (!itemGroupDef) {
            return sortedItemGroupDefs;
        }
        sortedItemGroupDefs.push(itemGroupDef);
        return sortedItemGroupDefs;
    }, []);
};
export const getSortedItemDefs = (metaDataVersion, itemGroupDef) => {
    return itemGroupDef.ItemRefs.sort((a, b) => {
        if (a.OrderNumber && b.OrderNumber) {
            return a.OrderNumber - b.OrderNumber;
        }
        return 0;
    }).reduce((sortedItemDefs, itemRef) => {
        const itemDef = metaDataVersion.ItemDefs.find((itemDef) => {
            return itemDef.idItem === itemRef.idItem;
        });
        if (!itemDef) {
            return sortedItemDefs;
        }
        sortedItemDefs.push(itemDef);
        return sortedItemDefs;
    }, []);
};
export const getSortedStudyUsers = (studyUsers) => {
    return studyUsers.sort((a, b) => a.userRole.level - b.userRole.level ||
        a.userRole.userRole.localeCompare(b.userRole.userRole) ||
        a.email.localeCompare(b.email, "en", { sensitivity: "base" }));
};
export const getSortedAdminViewUsers = (users) => {
    return users.sort((a, b) => a.userRole.level - b.userRole.level ||
        a.userRole.userRole.localeCompare(b.userRole.userRole) ||
        a.email.localeCompare(b.email, "en", { sensitivity: "base" }));
};
export const sortStudyEventDataByTimestamp = (studyEventsData, reverse = false) => {
    return studyEventsData.sort((a, b) => {
        if (reverse) {
            return (Date.parse(b.Timestamp) -
                Date.parse(a.Timestamp));
        }
        return (Date.parse(a.Timestamp) -
            Date.parse(b.Timestamp));
    });
};
//Get the event order including any unscheduled events that has been added
//Orders scheduled events by their ref order, then attempts to insert any unscheduled events by data timestamp
export const getOrderedEvents = (studyEventDefs, studyEventRefs, studyEventsData) => {
    const sortedEvents = getSortedScheduledEventDefs(studyEventDefs, studyEventRefs).map((e) => {
        return { idStudyEvent: e.idStudyEvent, repeatKey: undefined };
    });
    //Shot-cut: No data, so our order is just the scheduled event order
    if (studyEventsData.length === 0) {
        return sortedEvents;
    }
    //Order our existing data
    const orderedData = sortStudyEventDataByTimestamp(studyEventsData);
    //Find out where our unscheduled events fall into the event order
    //Loop the ordered data and insert any unscheduled events we have data for into the sort order
    //Start with a sort index of 0 as we may be inserting unscheduled events at the start
    let currentSortIndex = 0;
    for (let dataIndex = 0; dataIndex < orderedData.length; dataIndex++) {
        const data = orderedData[dataIndex];
        //We only consider data for unscheduled events
        if (Boolean(data.RepeatKey)) {
            //insert our unscheduled event at the current sort index
            if (currentSortIndex > sortedEvents.length - 1) {
                //We hit the end, just push
                sortedEvents.push({
                    idStudyEvent: data.idStudyEvent,
                    repeatKey: data.RepeatKey
                });
            }
            else {
                //Insert at the given index
                sortedEvents.splice(currentSortIndex, 0, {
                    idStudyEvent: data.idStudyEvent,
                    repeatKey: data.RepeatKey
                });
            }
            currentSortIndex++;
        }
        else {
            //We arent an unscheduled event, so the next slot should be our current location in the sorted list + 1
            currentSortIndex =
                sortedEvents.findIndex((e) => e.idStudyEvent === orderedData[dataIndex].idStudyEvent) + 1;
        }
    }
    //Our array will have all added unscheduled events in it now
    return sortedEvents;
};
export const sortStudyParams = (params) => {
    return [...params].sort((a, b) => a.ParameterName.localeCompare(b.ParameterName));
};
