import React from "react";
import { AuthProvider } from "@providers/AuthProvider";
import { SnackBarProvider } from "@providers/SnackBarProvider";
import WorkspaceProvider from "@client/providers/WorkspaceProvider";
import ThemeProvider from "@providers/ThemeProvider";
import { ConfirmationServiceProvider } from "@providers/ConfirmationServiceProvider";
import { WorkspaceAbilityProvider } from "@providers/WorkspaceAbilityProvider";
import { LoadingSpinnerProvider } from "./LoadingSpinnerProvider";

type Props = {
  children: JSX.Element | JSX.Element[];
};

const AppProviders = ({ children }: Props): JSX.Element => {
  return (
    <WorkspaceProvider>
      <ThemeProvider>
        <SnackBarProvider>
          <AuthProvider>
            <ConfirmationServiceProvider>
              <WorkspaceAbilityProvider>
                <LoadingSpinnerProvider>{children}</LoadingSpinnerProvider>
              </WorkspaceAbilityProvider>
            </ConfirmationServiceProvider>
          </AuthProvider>
        </SnackBarProvider>
      </ThemeProvider>
    </WorkspaceProvider>
  );
};

export default AppProviders;
