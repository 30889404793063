export var ActionMode;
(function (ActionMode) {
    ActionMode["VerifyEmail"] = "verifyEmail";
    ActionMode["ResetPassword"] = "resetPassword";
})(ActionMode || (ActionMode = {}));
export var AnnotationStatus;
(function (AnnotationStatus) {
    AnnotationStatus["Open"] = "Open";
    AnnotationStatus["Closed"] = "Closed";
    AnnotationStatus["Removed"] = "Removed";
    AnnotationStatus["SystemClosed"] = "SystemClosed";
    AnnotationStatus["Unresolvable"] = "Unresolvable";
})(AnnotationStatus || (AnnotationStatus = {}));
export var AnnotationType;
(function (AnnotationType) {
    AnnotationType["Comment"] = "Comment";
    AnnotationType["Query"] = "Query";
})(AnnotationType || (AnnotationType = {}));
export var AnnotationQueryType;
(function (AnnotationQueryType) {
    AnnotationQueryType["Manual"] = "Manual";
    AnnotationQueryType["System"] = "System";
})(AnnotationQueryType || (AnnotationQueryType = {}));
export var AppType;
(function (AppType) {
    AppType["CT"] = "CT";
    AppType["CTHybrid"] = "CT-Hybrid";
    AppType["RWE"] = "RWE";
    AppType["Clinic"] = "Clinic";
    AppType["ClinicHybrid"] = "Clinic-Hybrid"; //Limited
})(AppType || (AppType = {}));
export var ArmRefType;
(function (ArmRefType) {
    ArmRefType["Blinded"] = "Blinded";
    ArmRefType["Unblinded"] = "Unblinded";
})(ArmRefType || (ArmRefType = {}));
export var AuthStatus;
(function (AuthStatus) {
    AuthStatus[AuthStatus["GeneralError"] = 0] = "GeneralError";
    AuthStatus[AuthStatus["PasswordError"] = 1] = "PasswordError";
    AuthStatus[AuthStatus["EmailError"] = 2] = "EmailError";
    AuthStatus[AuthStatus["NetworkError"] = 3] = "NetworkError";
    AuthStatus[AuthStatus["ManyAttemptsError"] = 4] = "ManyAttemptsError";
    AuthStatus[AuthStatus["Success"] = 5] = "Success";
    AuthStatus[AuthStatus["UnverifiedEmail"] = 6] = "UnverifiedEmail";
    AuthStatus[AuthStatus["UserNotFound"] = 7] = "UserNotFound";
    AuthStatus[AuthStatus["InviteError"] = 8] = "InviteError";
    AuthStatus[AuthStatus["UserCancelled"] = 9] = "UserCancelled";
    AuthStatus[AuthStatus["SignupRequired"] = 10] = "SignupRequired";
    AuthStatus[AuthStatus["MFARequired"] = 11] = "MFARequired";
    AuthStatus[AuthStatus["LocalAccount"] = 12] = "LocalAccount";
    AuthStatus[AuthStatus["SSOAccount"] = 13] = "SSOAccount";
})(AuthStatus || (AuthStatus = {}));
export var Comparator;
(function (Comparator) {
    Comparator["LT"] = "LT";
    Comparator["LE"] = "LE";
    Comparator["GT"] = "GT";
    Comparator["GE"] = "GE";
    Comparator["EQ"] = "EQ";
    Comparator["NE"] = "NE";
    Comparator["IN"] = "IN";
    Comparator["NOTIN"] = "NOTIN";
})(Comparator || (Comparator = {}));
export var ControlType;
(function (ControlType) {
    ControlType["InputField"] = "InputField";
    ControlType["MultiLineInput"] = "MultiLineInput";
    ControlType["RadioButton"] = "RadioButton";
    ControlType["DropDown"] = "DropDown";
    ControlType["Switch"] = "Switch";
    ControlType["CheckBox"] = "CheckBox";
    ControlType["DatePicker"] = "DatePicker";
    ControlType["DateTimePicker"] = "DateTimePicker";
    ControlType["Time"] = "Time";
    ControlType["YesNo"] = "YesNo";
    ControlType["OrdinalScale"] = "OrdinalScale";
    ControlType["Terms"] = "Terms";
})(ControlType || (ControlType = {}));
export var DataLevel;
(function (DataLevel) {
    DataLevel["Subject"] = "Subject";
    DataLevel["Event"] = "Event";
    DataLevel["Form"] = "Form";
    DataLevel["ItemGroup"] = "ItemGroup";
})(DataLevel || (DataLevel = {}));
export var DataType;
(function (DataType) {
    DataType["Integer"] = "integer";
    DataType["Float"] = "float";
    DataType["Text"] = "text";
    DataType["String"] = "string";
})(DataType || (DataType = {}));
export var EditPoint;
(function (EditPoint) {
    EditPoint["Monitoring"] = "Monitoring";
    EditPoint["DataManagement"] = "DataManagement";
    EditPoint["DBAudit"] = "DBAudit";
})(EditPoint || (EditPoint = {}));
export var ExportOptions;
(function (ExportOptions) {
    ExportOptions["Study"] = "studyData";
    ExportOptions["Admin"] = "adminData";
    ExportOptions["Clinical"] = "clinicalData";
})(ExportOptions || (ExportOptions = {}));
export var FormType;
(function (FormType) {
    FormType["Standard"] = "Standard";
    FormType["GAS"] = "GAS";
    FormType["Intake"] = "Intake";
})(FormType || (FormType = {}));
export var GasContext;
(function (GasContext) {
    GasContext["Symptom"] = "Symptom";
    GasContext["Goal"] = "Goal";
    GasContext["Challenge"] = "Challenge";
})(GasContext || (GasContext = {}));
export var Granularity;
(function (Granularity) {
    Granularity["Y"] = "Y";
    Granularity["M"] = "M";
    Granularity["D"] = "D";
    Granularity["TH"] = "TH";
    Granularity["TM"] = "TM";
    Granularity["TS"] = "TS";
})(Granularity || (Granularity = {}));
export var IECriteriaCategory;
(function (IECriteriaCategory) {
    IECriteriaCategory["Inclusion"] = "Inclusion";
    IECriteriaCategory["Exclusion"] = "Exclusion";
})(IECriteriaCategory || (IECriteriaCategory = {}));
export var InviteStatus;
(function (InviteStatus) {
    InviteStatus["Pending"] = "Pending";
    InviteStatus["Claimed"] = "Claimed";
    InviteStatus["Open"] = "Open";
})(InviteStatus || (InviteStatus = {}));
export var ItemDataType;
(function (ItemDataType) {
    ItemDataType["Text"] = "Text";
    ItemDataType["Integer"] = "Integer";
    ItemDataType["Float"] = "Float";
    ItemDataType["Date"] = "Date";
    ItemDataType["Time"] = "Time";
    ItemDataType["DateTime"] = "DateTime";
    ItemDataType["Boolean"] = "Boolean";
})(ItemDataType || (ItemDataType = {}));
export var LocationType;
(function (LocationType) {
    LocationType["Sponsor"] = "Sponsor";
    LocationType["Site"] = "Site";
    LocationType["CRO"] = "CRO";
    LocationType["Lab"] = "Lab";
    LocationType["Other"] = "Other";
})(LocationType || (LocationType = {}));
export var MetaDataVersionStatus;
(function (MetaDataVersionStatus) {
    MetaDataVersionStatus["Draft"] = "Draft";
    MetaDataVersionStatus["Published"] = "Published";
})(MetaDataVersionStatus || (MetaDataVersionStatus = {}));
export var MethodDefType;
(function (MethodDefType) {
    MethodDefType["Computation"] = "Computation";
    MethodDefType["Imputation"] = "Imputation";
    MethodDefType["Transpose"] = "Transpose";
    MethodDefType["Other"] = "Other";
})(MethodDefType || (MethodDefType = {}));
export var MethodExpressionType;
(function (MethodExpressionType) {
    MethodExpressionType["GoalCount"] = "GoalCount";
})(MethodExpressionType || (MethodExpressionType = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["StartableNow"] = "StartableNow";
    NotificationType["StartableWindowClosing"] = "StartableWindowClosing";
    NotificationType["DurationExpiring"] = "DurationExpiring";
    NotificationType["Invite"] = "Invite";
    NotificationType["Verify"] = "Verify";
    NotificationType["Reset"] = "Reset";
    NotificationType["ResetSuccess"] = "ResetSuccess";
})(NotificationType || (NotificationType = {}));
export var SignatureMeaning;
(function (SignatureMeaning) {
    SignatureMeaning["Authorship"] = "Authorship";
    SignatureMeaning["Approval"] = "Approval";
    SignatureMeaning["SourceDataVerification"] = "SDV";
})(SignatureMeaning || (SignatureMeaning = {}));
export var SignatureMethodology;
(function (SignatureMethodology) {
    SignatureMethodology["Digital"] = "Digital";
    SignatureMethodology["Electronic"] = "Electronic";
})(SignatureMethodology || (SignatureMethodology = {}));
export var SiteStatus;
(function (SiteStatus) {
    SiteStatus["Available"] = "Available";
    SiteStatus["Deactivated"] = "Deactivated";
})(SiteStatus || (SiteStatus = {}));
export var SoftHard;
(function (SoftHard) {
    SoftHard["Soft"] = "Soft";
    SoftHard["Hard"] = "Hard";
})(SoftHard || (SoftHard = {}));
export var StudyEventType;
(function (StudyEventType) {
    StudyEventType["Scheduled"] = "Scheduled";
    StudyEventType["Unscheduled"] = "Unscheduled";
    StudyEventType["Common"] = "Common";
    StudyEventType["Intake"] = "Intake";
})(StudyEventType || (StudyEventType = {}));
export var StudyFeatureKey;
(function (StudyFeatureKey) {
    StudyFeatureKey["LinkedTrainingStudy"] = "LinkedTrainingStudy";
})(StudyFeatureKey || (StudyFeatureKey = {}));
export var StudyParameterOptions;
(function (StudyParameterOptions) {
    StudyParameterOptions["SubjectKeyPattern"] = "SubjectKeyPattern";
    StudyParameterOptions["HelpContent"] = "HelpContent";
})(StudyParameterOptions || (StudyParameterOptions = {}));
export var StudyPhase;
(function (StudyPhase) {
    StudyPhase["PhaseI"] = "Phase I";
    StudyPhase["PhaseII"] = "Phase II";
    StudyPhase["PhaseIII"] = "Phase III";
    StudyPhase["PhaseIV"] = "Phase IV";
    StudyPhase["Registry"] = "Registry";
    StudyPhase["Other"] = "Other";
    StudyPhase["NotApplicable"] = "Not Applicable";
})(StudyPhase || (StudyPhase = {}));
export var StudyStatus;
(function (StudyStatus) {
    StudyStatus["Draft"] = "Draft";
    StudyStatus["Sandbox"] = "Sandbox";
    StudyStatus["Published"] = "Published";
    StudyStatus["Hold"] = "Hold";
    StudyStatus["Locked"] = "Locked";
})(StudyStatus || (StudyStatus = {}));
export var SystemAnnotationType;
(function (SystemAnnotationType) {
    SystemAnnotationType["Soft"] = "Soft";
})(SystemAnnotationType || (SystemAnnotationType = {}));
export var SystemAuditRecordAction;
(function (SystemAuditRecordAction) {
    SystemAuditRecordAction["LockData"] = "LockData";
    SystemAuditRecordAction["UnlockData"] = "UnlockData";
})(SystemAuditRecordAction || (SystemAuditRecordAction = {}));
export var TimingConstraintType;
(function (TimingConstraintType) {
    TimingConstraintType["StartToStart"] = "StartToStart";
    TimingConstraintType["StartToFinish"] = "StartToFinish";
    TimingConstraintType["FinishToStart"] = "FinishToStart";
    TimingConstraintType["FinishToFinish"] = "FinishToFinish";
})(TimingConstraintType || (TimingConstraintType = {}));
export var TransactionType;
(function (TransactionType) {
    TransactionType["Insert"] = "Insert";
    TransactionType["Update"] = "Update";
    TransactionType["Remove"] = "Remove";
    TransactionType["Upsert"] = "Upsert";
    TransactionType["Context"] = "Context";
})(TransactionType || (TransactionType = {}));
export var UserAttributeKey;
(function (UserAttributeKey) {
    UserAttributeKey["CompletedTraining"] = "CompletedTraining";
})(UserAttributeKey || (UserAttributeKey = {}));
export var UserPermission;
(function (UserPermission) {
    UserPermission["Read"] = "R";
    UserPermission["ReadWrite"] = "RW";
})(UserPermission || (UserPermission = {}));
export var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "Admin";
    UserRole["Designer"] = "Designer";
    UserRole["Director"] = "Director";
    UserRole["DataManager"] = "Data Manager";
    UserRole["Investigator"] = "Investigator";
    UserRole["DataEntry"] = "Data Entry";
    UserRole["ClinicalResearchCoordinator"] = "Clinical Research Coordinator";
    UserRole["DataMonitor"] = "Data Monitor";
    UserRole["Sponsor"] = "Sponsor";
    UserRole["StudyParticipant"] = "Study Participant";
})(UserRole || (UserRole = {}));
export var UserScope;
(function (UserScope) {
    UserScope["Global"] = "Global";
    UserScope["Local"] = "Local";
})(UserScope || (UserScope = {}));
export var UserStatus;
(function (UserStatus) {
    UserStatus["Invited"] = "Invited";
    UserStatus["Approval"] = "Approval";
    UserStatus["Available"] = "Available";
    UserStatus["Deactivated"] = "Deactivated";
})(UserStatus || (UserStatus = {}));
export var VerifyAction;
(function (VerifyAction) {
    VerifyAction["SignData"] = "SignData";
    VerifyAction["LockUnlockData"] = "LockUnlockData";
    VerifyAction["ChangeStudyStatus"] = "ChangeStudyStatus";
    VerifyAction["HardDeleteUser"] = "HardDeleteUser";
    VerifyAction["HardDeleteWorkspace"] = "HardDeleteWorkspace";
    VerifyAction["SourceDataVerification"] = "SourceDataVerification";
})(VerifyAction || (VerifyAction = {}));
export var WorkspaceParameterKey;
(function (WorkspaceParameterKey) {
    WorkspaceParameterKey["PrimaryColour"] = "PrimaryColour";
    WorkspaceParameterKey["SecondaryColour"] = "SecondaryColour";
    WorkspaceParameterKey["PrimaryColourDark"] = "PrimaryColourDark";
    WorkspaceParameterKey["PrimaryColourLight"] = "PrimaryColourLight";
    WorkspaceParameterKey["SecondaryColourDark"] = "SecondaryColourDark";
    WorkspaceParameterKey["SecondaryColourLight"] = "SecondaryColourLight";
    WorkspaceParameterKey["TonalOffset"] = "TonalOffset";
    WorkspaceParameterKey["BackgroundDefault"] = "BackgroundDefault";
    WorkspaceParameterKey["VanityDomain"] = "VanityDomain";
})(WorkspaceParameterKey || (WorkspaceParameterKey = {}));
export var WorkspaceStatus;
(function (WorkspaceStatus) {
    WorkspaceStatus["Available"] = "Available";
    WorkspaceStatus["Deactivated"] = "Deactivated";
})(WorkspaceStatus || (WorkspaceStatus = {}));
export var YesNo;
(function (YesNo) {
    YesNo["Yes"] = "Yes";
    YesNo["No"] = "No";
})(YesNo || (YesNo = {}));
