export var TrainingModuleName;
(function (TrainingModuleName) {
    TrainingModuleName["Introduction"] = "Introduction";
    TrainingModuleName["OpenStudy"] = "OpenStudy";
    TrainingModuleName["AddSubject"] = "AddSubject";
    TrainingModuleName["ViewSubjects"] = "ViewSubjects";
    TrainingModuleName["Screening"] = "Screening";
    TrainingModuleName["StandardForm"] = "StandardForm";
    TrainingModuleName["Baseline"] = "Baseline";
    TrainingModuleName["Followup"] = "Followup";
})(TrainingModuleName || (TrainingModuleName = {}));
