import {
  SubjectData,
  AddAnnotationRequest,
  RemoveAnnotationRequest,
  AnnotationReminderRequest,
  QueryHistoryRequest,
  Annotation
} from "@interface/types";
import FetchClient from "./FetchClient";

const ANNOTATION_BASE = "/api/studies";

export const addAnnotationCall = async (
  request: AddAnnotationRequest
): Promise<SubjectData> => {
  const studyOID = request.OID;
  const idMetaDataVersion = request.metaDataVersionId;
  const subjectKey = request.SubjectKey;
  const path = `${ANNOTATION_BASE}/${studyOID}/clinicaldata/mdv/${idMetaDataVersion}/subjectdata/${subjectKey}/annotations`;

  return await new FetchClient().post<SubjectData>({
    path: path,
    body: request
  });
};

export const deleteAnnotationCall = async (
  request: RemoveAnnotationRequest
): Promise<SubjectData> => {
  const studyOID = request.OID;
  const idMetaDataVersion = request.metaDataVersionId;
  const subjectKey = request.SubjectKey;
  const path = `${ANNOTATION_BASE}/${studyOID}/clinicaldata/mdv/${idMetaDataVersion}/subjectdata/${subjectKey}/annotations`;

  return await new FetchClient().delete<SubjectData>({
    path: path,
    body: request
  });
};

export const sendQueryReminderNotification = async (
  request: AnnotationReminderRequest
): Promise<void> => {
  const studyOID = request.OID;
  const idMetaDataVersion = request.metaDataVersionId;
  const subjectKey = request.SubjectKey;
  const path = `${ANNOTATION_BASE}/${studyOID}/clinicaldata/mdv/${idMetaDataVersion}/subjectdata/${subjectKey}/annotations/sendReminder`;

  return await new FetchClient().post({
    path: path,
    body: request
  });
};

export const getQueryHistory = async (
  request: QueryHistoryRequest
): Promise<Annotation[]> => {
  const studyOID = request.OID;

  const path = `${ANNOTATION_BASE}/${studyOID}/clinicaldata/queryHistory`;
  return new FetchClient().post<Annotation[]>({ path, body: request });
};
