export const DateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const DateFormat = "YYYY-MM-DD";
export const TimeFormat = "HH:mm:ss";
export const LongDateTimeFormat = "MMMM DD, YYYY";
// Used to convert JS stringified Date objects
export const DateObjectFormat = "YYYY-MM-DDTHH:mm:ss.SSSSZ";
// Used for sending dates to iCal, Google, Yahoo etc. Calendars
export const CalendarDateFormat = "YYYYMMDDTHHmmssZ";
export const dateFormatOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric"
    //timeZone: "UTC"
};
export const dateTimeFormatOptions = {
    ...dateFormatOptions,
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short"
};
export const dateFormatMonthAndYearOnly = {
    month: "long",
    year: "numeric"
};
export const formatDate = (date) => {
    //jc:en-us should be dynamic should not be static string
    return date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric"
    });
};
