import {
  DisplayStudy,
  SaveStudyDetailsRequest,
  Study,
  ChangeStudyStatusRequest
} from "@interface/types";
import FetchClient from "./FetchClient";

const STUDY_BASE = "/api/studies";

export const getStudiesForWorkspaceAndUser = async (): Promise<
  DisplayStudy[]
> => {
  const path = `${STUDY_BASE}/`;
  return new FetchClient().get<DisplayStudy[]>({ path });
};

export const getStudyById = async (studyOID: string): Promise<Study> => {
  const path = `${STUDY_BASE}/${studyOID}`;

  return new FetchClient().get<Study>({ path: path });
};

export const deleteStudyById = async (studyOID: string): Promise<void> => {
  const path = `${STUDY_BASE}/${studyOID}`;

  await new FetchClient().delete({ path: path });
  return;
};

export const createStudy = async (
  request: SaveStudyDetailsRequest
): Promise<void> => {
  const path = `${STUDY_BASE}/`;

  await new FetchClient().post({ path, body: request });
  return;
};

export const updateExistingStudy = async (
  studyOID: string,
  request: SaveStudyDetailsRequest
): Promise<Study> => {
  const path = `${STUDY_BASE}/${studyOID}`;

  return new FetchClient().put<Study>({ path, body: request });
};

export const changeStudyStatus = async (
  studyOID: string,
  request: ChangeStudyStatusRequest
): Promise<Study> => {
  const path = `${STUDY_BASE}/${studyOID}/status`;

  return new FetchClient().put<Study>({ path, body: request });
};

export const duplicateStudy = async (studyOID: string): Promise<void> => {
  const path = `${STUDY_BASE}/${studyOID}`;

  await new FetchClient().copy({ path });
  return;
};
