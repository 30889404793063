const stringValidator = /^[A-Z0-9À-ÿ :(),._+/'-]*$/i; //only letters, numbers, spaces, slash, dash, comma, period, apostrophe allowed
const questionValidator = /^[A-Z0-9À-ÿ :(),._+/'-?]*$/i; //only letters, numbers, spaces, slash, dash, comma, period, apostrophe and question mark allowed
const postalValidator = /^[0-9A-Z ,-/]*$/i; //only letters, numbers, spaces, slash, dash, comma allowed
const phoneValidator = /^[0-9() +-]*$/i; //only numbers
const languageTagValidator = /^[A-Z -]+$/i;
const subjectKeyValidator = /^[0-9A-Z -]+$/i;
const ISO8601Duration = /^P(?!$)(\d+(?:\.\d+)?Y)?(\d+(?:\.\d+)?M)?(\d+(?:\.\d+)?W)?(\d+(?:\.\d+)?D)?(T(?=\d)(\d+(?:\.\d+)?H)?(\d+(?:\.\d+)?M)?(\d+(?:\.\d+)?S)?)?$/i;
const hexColourValidator = /^#(?:[0-9A-F]{3}){1,2}$/i;
const subdomainValidator = /^[A-Z0-9](?:[A-Z0-9\-]{0,61}[A-Z0-9])?/i;
const domainValidator = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,63}$)/i; //https://stackoverflow.com/a/20204811
const onlyNumbersOrEmpty = /^\d*$/i;
export { stringValidator, questionValidator, ISO8601Duration, subjectKeyValidator, postalValidator, phoneValidator, languageTagValidator, hexColourValidator, subdomainValidator, domainValidator, onlyNumbersOrEmpty };
