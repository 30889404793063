import React, { useState } from "react";
import {
  Button,
  Typography,
  Paper,
  Theme,
  InputAdornment,
  Link
} from "@mui/material";
import { makeStyles, useWorkspace } from "@providers";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import RHFInput from "@client/components/RHFInput";
import {
  getLastUsedSubdomain,
  setLastUsedSubdomain
} from "@helpers/localStorageHelpers";
import { ReactComponent as GNLogo } from "@client/images/logo.svg";
import selectors from "@interface/constants/selectors.json";
import { getAuthCardStyle } from "@client/helpers/themeHelpers";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: getAuthCardStyle(theme),
  gnLogo: {
    margin: theme.spacing(1),
    width: 45,
    height: 45
  },
  verifyHeader: {
    margin: theme.spacing(1)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(7)
  },
  titleHeader: {
    width: "75%",
    textAlign: "center"
  },
  submit: {
    fontSize: "1.2em",
    margin: theme.spacing(3, 0, 2)
  },
  urlBorder: {
    borderStyle: "solid",
    borderColor: theme.palette.grey[400],
    borderRadius: theme.shape.borderRadius,
    borderWidth: 4,
    marginTop: theme.spacing(2)
  },
  urlExample: {
    color: theme.palette.grey[500]
  },
  subdomainBorder: {
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderWidth: 2
  },
  subdomainHighlight: {
    color: theme.palette.secondary.contrastText
  },
  arrow: {
    width: theme.spacing(7),
    marginBottom: theme.spacing(2)
  },
  line: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
    width: theme.spacing(0.8),
    background: theme.palette.primary.main,
    height: theme.spacing(3),
    float: "left"
  },
  point: {
    width: 0,
    height: 0,
    marginLeft: theme.spacing(-1.6),
    borderLeft: `${theme.spacing(1)} solid transparent`,
    borderRight: `${theme.spacing(1)} solid transparent`,
    borderBottom: `${theme.spacing(2)} solid ${theme.palette.primary.main}`,
    float: "inherit"
  }
}));

const SubdomainSelector = (): JSX.Element => {
  const { classes } = useStyles();
  const { changeWorkspace, getWorkspacesList } = useWorkspace();
  const [domainHelp, setDomainHelp] = useState<boolean>(false);
  const { t } = useTranslation();

  const validationSchema = z.object({
    subdomain: z.string().refine(
      (data) => {
        if (findSubdomain(data)) {
          return true;
        }
        return false;
      },
      { message: t("subdomain.error.invalidSubdomain") }
    )
  });

  type SubdomainInput = z.infer<typeof validationSchema>;

  const findSubdomain = (subdomain: string) => {
    return getWorkspacesList().find((d) => d.Subdomain === subdomain);
  };

  const onSubmit = (data: SubdomainInput) => {
    const sd = findSubdomain(data.subdomain);
    if (sd) {
      setLastUsedSubdomain(sd.Subdomain);
      changeWorkspace(sd.idWorkspace);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SubdomainInput>({
    resolver: zodResolver(validationSchema)
  });

  return (
    <Paper className={classes.paper}>
      <GNLogo className={classes.gnLogo} />
      {domainHelp && (
        <>
          <Typography component="h1" variant="h5">
            {t("subdomain.help.title")}
          </Typography>
          <div className={classes.urlBorder}>
            <Typography className={classes.urlExample} variant="subtitle1">
              {t("subdomain.help.urlScheme")}
              <span
                className={`${classes.subdomainHighlight} ${classes.subdomainBorder}`}
              >
                {t("subdomain.help.subdomain")}
              </span>
              {`.${window.location.host}`}
            </Typography>
          </div>
          <div className={classes.arrow}>
            <div className={classes.line}></div>
            <div className={classes.point}></div>
            <Typography className={classes.subdomainHighlight} variant="body1">
              {t("subdomain.help.diagramLabel")}
            </Typography>
          </div>

          <Typography align="center" variant="subtitle1">
            {t("subdomain.help.description", {
              host: `.${window.location.host}`
            })}
          </Typography>
          <Button
            data-testid={selectors.components.SubdomainSelector.helpButton}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => setDomainHelp(false)}
          >
            {t("subdomain.help.button")}
          </Button>
        </>
      )}
      {!domainHelp && (
        <>
          <Typography
            component="h1"
            variant="h5"
            className={classes.titleHeader}
          >
            {t("subdomain.selectionForm.title")}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <RHFInput
              data-testid={
                selectors.components.SubdomainSelector.subdomainInput
              }
              error={errors.subdomain && true}
              helperText={errors.subdomain?.message}
              label=""
              autoFocus
              fullWidth
              name={"subdomain"}
              type="text"
              register={register}
              defaultValue={getLastUsedSubdomain() || ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {`.${window.location.host}`}
                  </InputAdornment>
                )
              }}
            />
            <Button
              data-testid={selectors.components.SubdomainSelector.submitButton}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t("subdomain.selectionForm.button")}
            </Button>
          </form>
          <Link onClick={() => setDomainHelp(true)}>
            {t("subdomain.selectionForm.link")}
          </Link>
        </>
      )}
    </Paper>
  );
};

export default SubdomainSelector;
