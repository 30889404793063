import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material";
import { Create } from "@mui/icons-material";
import {
  AnalyticsAttribute,
  AnalyticsEvent,
  DataLevel,
  SignatureMeaning,
  SignatureOptions,
  StudySignature
} from "@interface/types";
import { useAnalytics } from "@client/hooks/useAnalytics";

const SignaturePickerServiceContext = React.createContext<
  (options: SignatureOptions) => Promise<number>
>(Promise.reject);

const SignaturePickerServiceProvider = ({
  children
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const { t } = useTranslation();
  const [signatureOptions, setSignatureOptions] = useState<SignatureOptions>();
  const [selectedSignature, setSelectedSignature] = useState<StudySignature>();

  const awaitingPromiseRef = useRef<{
    resolve: (value: number | PromiseLike<number>) => void;
    reject: () => void; //
  }>();

  const { sendEvent } = useAnalytics();

  useEffect(() => {
    if (signatureOptions) {
      //For now, only the Approval signature is used.
      setSelectedSignature(
        signatureOptions.signatures.find(
          (s) => s.Meaning === SignatureMeaning.Approval
        )
      );
    }
  }, [signatureOptions]);

  const clearAllState = () => {
    setSignatureOptions(undefined);
    setSelectedSignature(undefined);
  };

  const openPicker = useCallback((options: SignatureOptions) => {
    setSignatureOptions(options);
    return new Promise<number>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const handleCancel = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    clearAllState();
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current && selectedSignature && signatureOptions) {
      sendEvent(AnalyticsEvent.ApplySignature, {
        [AnalyticsAttribute.DataLevel]: DataLevel[signatureOptions.DataLevel]
      });
      awaitingPromiseRef.current.resolve(selectedSignature.SignatureOID);
    }

    clearAllState();
  };

  const getSignatureDisplay = () => {
    if (signatureOptions && selectedSignature) {
      // if (signatureOptions.signatures.length === 1) {
      //   return (
      //     <Grid container>
      //       <Grid container>
      //         <Grid item xs={12}>
      //           <Typography variant="body1">
      //             {signatureOptions.signatures[0].LegalReason}
      //           </Typography>
      //         </Grid>
      //       </Grid>
      //       {getUserDisplay()}
      //     </Grid>
      //   );
      // } else {
      return (
        <Grid container>
          {/* <Grid container>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>
                            {t("dataCollection.signSelect")}
                        </InputLabel>
                        <Select
                            fullWidth
                            labelWidth={118}
                            value={selectedSignature}
                            onChange={(e) => { setSelectedSignature(e.target.value as number) }}>
                            {signatureOptions.signatures.map(s => {
                                return <MenuItem key={s.signatureOID} value={s.signatureOID}>{s.Meaning}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>*/}
          <Grid item xs={12}>
            {signatureOptions.DataLevel === DataLevel.Subject && (
              <Typography variant="h5">
                {t("dataCollection.signReminder")}
              </Typography>
            )}
            <br />
            <br />
            <Typography gutterBottom>
              {selectedSignature.LegalReason}
            </Typography>
          </Grid>
          {getUserDisplay()}
        </Grid>
      );
    }

    return <div></div>;
  };

  const getUserDisplay = () => {
    if (signatureOptions) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle2" display="inline">
              {t("dataCollection.signEmail")}:&nbsp;
            </Typography>
            <Typography variant="subtitle1" display="inline">
              {signatureOptions.user.email}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" display="inline">
              {t("dataCollection.signRole")}:&nbsp;
            </Typography>
            <Typography variant="subtitle1" display="inline">
              {signatureOptions.studyRole.userRole}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" display="inline">
              {t("dataCollection.signDate")}:&nbsp;
            </Typography>
            <Typography variant="caption" display="inline">
              {signatureOptions.dateTime.toLocaleDateString() +
                " " +
                signatureOptions.dateTime.toLocaleTimeString()}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return undefined;
  };

  return (
    <SignaturePickerServiceContext.Provider value={openPicker}>
      {children}
      <Dialog
        data-testid="sign-modal"
        fullWidth
        open={Boolean(signatureOptions)}
        onClose={handleCancel}
      >
        <DialogTitle data-testid="sign-title">
          {t("dataCollection.signTitle", {
            type: signatureOptions && signatureOptions.itemName
          })}
        </DialogTitle>
        <DialogContent dividers>{getSignatureDisplay()}</DialogContent>
        <DialogActions>
          <Button
            data-testid="sign-cancel"
            onClick={handleCancel}
            variant="outlined"
          >
            {t("dataCollection.signCancel")}
          </Button>
          <Button
            data-testid="sign-ok"
            onClick={handleConfirm}
            startIcon={<Create />}
            color="primary"
            variant="outlined"
          >
            {t("dataCollection.signOK")}
          </Button>
        </DialogActions>
      </Dialog>
    </SignaturePickerServiceContext.Provider>
  );
};

const useSignaturePicker = (): ((
  options: SignatureOptions
) => Promise<number>) => {
  const context = React.useContext(SignaturePickerServiceContext);
  if (context === undefined) {
    throw new Error("useSnackbar must be used within an AuthProvider");
  }
  return context;
};

export { SignaturePickerServiceProvider, useSignaturePicker };
