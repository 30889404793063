import React, { useState, useEffect } from "react";
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  Theme,
  ThemeOptions,
  createTheme,
  responsiveFontSizes,
  useTheme
} from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { buildWorkspaceTheme } from "@helpers/themeHelpers";
import { createMakeStyles } from "tss-react";
import { useWorkspace } from "@client/providers/WorkspaceProvider";

import "@client/fonts/fonts.css";

//
//Declare custom theme fields here
declare module "@mui/material" {
  interface Theme {
    custom: {
      appBarHeight: React.CSSProperties["height"];
      sidebarLogoHeight: React.CSSProperties["height"];
      sideBarWidth: React.CSSProperties["width"];
      symptomIconColorHeader: React.CSSProperties["color"];
      symptomIconColorList: React.CSSProperties["color"];
      scalePlus2: React.CSSProperties["color"];
      scalePlus2Dark: React.CSSProperties["color"];
      scalePlus2Icon: React.CSSProperties["color"];
      scalePlus1: React.CSSProperties["color"];
      scalePlus1Dark: React.CSSProperties["color"];
      scalePlus1Icon: React.CSSProperties["color"];
      scale0: React.CSSProperties["color"];
      scale0Dark: React.CSSProperties["color"];
      scale0Icon: React.CSSProperties["color"];
      scaleMinus1: React.CSSProperties["color"];
      scaleMinus1Dark: React.CSSProperties["color"];
      scaleMinus1Icon: React.CSSProperties["color"];
      scaleMinus2: React.CSSProperties["color"];
      scaleMinus2Dark: React.CSSProperties["color"];
      scaleMinus2Icon: React.CSSProperties["color"];
      participantNavWidth: React.CSSProperties["width"];
      clinicNavWidth: React.CSSProperties["width"];
    };
  }

  interface ThemeOptions {
    custom?: {
      appBarHeight: React.CSSProperties["height"];
      sidebarLogoHeight: React.CSSProperties["height"];
      sideBarWidth: React.CSSProperties["width"];
      symptomIconColorHeader: React.CSSProperties["color"];
      symptomIconColorList: React.CSSProperties["color"];
      scalePlus2: React.CSSProperties["color"];
      scalePlus2Dark: React.CSSProperties["color"];
      scalePlus2Icon: React.CSSProperties["color"];
      scalePlus1: React.CSSProperties["color"];
      scalePlus1Dark: React.CSSProperties["color"];
      scalePlus1Icon: React.CSSProperties["color"];
      scale0: React.CSSProperties["color"];
      scale0Dark: React.CSSProperties["color"];
      scale0Icon: React.CSSProperties["color"];
      scaleMinus1: React.CSSProperties["color"];
      scaleMinus1Dark: React.CSSProperties["color"];
      scaleMinus1Icon: React.CSSProperties["color"];
      scaleMinus2: React.CSSProperties["color"];
      scaleMinus2Dark: React.CSSProperties["color"];
      scaleMinus2Icon: React.CSSProperties["color"];
      participantNavWidth: React.CSSProperties["width"];
      clinicNavWidth: React.CSSProperties["width"];
    };
  }
}

export const standardTheme = createTheme({
  palette: {
    primary: {
      main: "#7E8CC5",
      light: "#E7E8F2",
      dark: "#181F40"
    },
    secondary: {
      main: "#FF6D6D"
    },
    error: {
      main: "#FF4136"
    },
    warning: {
      main: "#FF851B"
    },
    success: {
      main: "#2ECC40"
    },
    background: {
      default: "#fafafa" //default theme.palette.grey["50"]
    }
    //tonalOffset:0.9
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1570,
      xl: 1920
    }
  },
  typography: {
    allVariants: {
      fontFamily: "Rules Regular, sans-serif",
      lineHeight: 1.8
    },
    h1: {
      fontFamily: "Romie Regular, serif",
      lineHeight: 1.2
    },
    h2: {
      fontFamily: "Romie Regular, serif",
      lineHeight: 1.2
    },
    h3: {
      fontFamily: "Rules Regular, sans-serif",
      lineHeight: 1.8
    },
    h4: {
      fontFamily: "Rules Regular, sans-serif",
      lineHeight: 1.8
    },
    h5: {
      fontFamily: "Rules Regular, sans-serif",
      lineHeight: 1.8
    },
    h6: {
      fontFamily: "Rules Bold, sans-serif",
      lineHeight: 1.3
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h2: "h1",
          h3: "h2",
          h4: "h3",
          h5: "h4",
          h6: "h5",
          subtitle1: "h6"
        },
        variant: "body2"
      }
    },
    MuiButton: {
      defaultProps: {
        variant: "outlined",
        color: "primary"
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: { lineHeight: 1.5 }
      }
    },
    MuiTextField: {
      defaultProps: {
        // The default props to change
        variant: "outlined",
        margin: "normal",
        style: { minWidth: 150 }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        style: { minWidth: 150 }
      }
    },
    MuiFormControl: { defaultProps: { margin: "normal" } },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": "8px" //default theme.spacing(1)
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
        fontFamily: "Rules Bold",
        lineHeight: 1.5
      }
    }
  },
  custom: {
    appBarHeight: 70, //Set slightly more than ther appbar to account for the drop shadow
    sidebarLogoHeight: "68px",
    sideBarWidth: 300,
    symptomIconColorHeader: "#EED2E7",
    symptomIconColorList: "#ECCBE4",
    scalePlus2: "#E0EEEA",
    scalePlus2Dark: "#399178",
    scalePlus2Icon: "#C4DED7",
    scalePlus1: "#ECEDDD",
    scalePlus1Dark: "#7F9825",
    scalePlus1Icon: "#E1E6CF",
    scale0: "#F8ECD0",
    scale0Dark: "#D8A228",
    scale0Icon: "#F8EACA",
    scaleMinus1: "#F8E2D4",
    scaleMinus1Dark: "#BF6D3E",
    scaleMinus1Icon: "#F8E2D4",
    scaleMinus2: "#F9D8D8",
    scaleMinus2Dark: "#A54242",
    scaleMinus2Icon: "#F9D8D8",
    participantNavWidth: 223,
    clinicNavWidth: 223
  }
} as ThemeOptions);

const muiCache = createCache({ key: "mui", prepend: true });

type Props = {
  children: JSX.Element | JSX.Element[];
};

const ThemeProvider = ({ children }: Props): JSX.Element => {
  const [theme, setTheme] = useState<Theme>(standardTheme);
  const { currentWorkspace } = useWorkspace();
  useEffect(() => {
    if (currentWorkspace && currentWorkspace.Parameters.length > 0) {
      const wsTheme = buildWorkspaceTheme(
        standardTheme,
        currentWorkspace.Parameters
      );
      setTheme(wsTheme);
    }
  }, [currentWorkspace]);

  return (
    // inject emotion to avoid overwriting JSS styles
    <CacheProvider value={muiCache}>
      <MuiThemeProvider theme={responsiveFontSizes(theme, { factor: 3 })}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </CacheProvider>
  );
};

export const { makeStyles } = createMakeStyles<Theme>({
  useTheme
});

export default ThemeProvider;
