import {
  DisplayStudy,
  TrainingModuleName,
  User,
  UserAttribute,
  UserAttributeKey,
  UserCompleteTrainingValue
} from "@interface/types";

export const getUserAttribute = (
  user: User,
  key: UserAttributeKey
): UserAttribute | undefined => {
  return user.userAttributes.find((a) => a.Key === key);
};

export const userCompletedRequiredModules = (
  user: User,
  study: DisplayStudy
): boolean => {
  //Study is not linked to a training, nothing else to do.
  if (!study.HasLinkedTrainingStudy) {
    return true;
  }
  const userAttribute = getUserAttribute(
    user,
    UserAttributeKey.CompletedTraining
  );

  const completedTrainingModules =
    (userAttribute?.Value as UserCompleteTrainingValue)[study.OID] || undefined;

  //If no completed training modules exists required trainings have not been completed
  if (completedTrainingModules === undefined) {
    return false;
  }

  let requiredModuleCount = 0;

  for (const trainingModule of completedTrainingModules) {
    if (requiredModuleCount === 6) {
      break;
    }
    if (
      trainingModule === TrainingModuleName.Introduction ||
      trainingModule === TrainingModuleName.OpenStudy ||
      trainingModule === TrainingModuleName.AddSubject ||
      trainingModule === TrainingModuleName.ViewSubjects ||
      trainingModule === TrainingModuleName.Screening ||
      trainingModule === TrainingModuleName.Followup
    ) {
      requiredModuleCount++;
    }
  }

  //If module count is not 6 then some of the required trainings have not yet been completed.
  if (requiredModuleCount !== 6) {
    return false;
  }
  return true;
};
