import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import routes from "@routes/routes";
import { useWorkspace } from "@providers";

const SubdomainRoute = (): JSX.Element => {
  const { currentWorkspace } = useWorkspace();

  return !currentWorkspace ? <Navigate to={routes.signin} /> : <Outlet />;
};

export default SubdomainRoute;
