import {
  SkipNext,
  ChevronLeft,
  ChevronRight,
  Close
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Theme,
  Typography
} from "@mui/material";
import { ExtendedStep, makeStyles } from "@providers";
import React from "react";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";
import { TooltipRenderProps } from "react-joyride";

const useStyles = makeStyles()((theme: Theme) => ({
  cardRoot: {
    minWidth: "290px",
    maxWidth: "450px",
    position: "relative",
    margin: "auto"
  },
  cardContent: {
    padding: theme.spacing(1.5)
  }
}));

export const InteractiveTrainingTooltip = ({
  index,
  isLastStep,
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps
}: TooltipRenderProps): JSX.Element => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const primitiveContent = typeof step.content === "string";
  const extStep = step as ExtendedStep;

  return (
    <Card raised={true} className={classes.cardRoot} {...tooltipProps}>
      {extStep.showConfetti && (
        <Confetti
          width={350}
          height={350}
          recycle={false}
          numberOfPieces={300}
        />
      )}
      {step.title && (
        <CardHeader title={step.title} className={classes.cardContent} />
      )}

      <CardContent className={classes.cardContent}>
        {primitiveContent ? (
          <Typography variant="body1">{step.content}</Typography>
        ) : (
          step.content
        )}
      </CardContent>

      <CardActions>
        {step.showSkipButton && (!isLastStep || extStep.lastStepShowSkip) && (
          <Button
            color="inherit"
            variant="text"
            endIcon={
              extStep.hideSkipIcon ? null : extStep.skipIcon ?? <SkipNext />
            }
            {...skipProps}
          >
            {extStep.skipButtonText ?? t("nav.skip")}
          </Button>
        )}
        <div style={{ flexGrow: 1 }} />
        {index > 0 && !step.hideBackButton && (
          <Button color="secondary" startIcon={<ChevronLeft />} {...backProps}>
            {t("nav.back")}
          </Button>
        )}
        {!extStep.hideNextButton && (
          <Button
            {...primaryProps}
            color="primary"
            endIcon={
              isLastStep
                ? extStep.lastStepPrimaryButtonIcon ?? <Close />
                : extStep.nextIcon ?? <ChevronRight />
            }
          >
            {isLastStep
              ? extStep.lastStepPrimaryButtonText ?? t("nav.close")
              : extStep.nextButtonText ?? t("nav.next")}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
