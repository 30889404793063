import React, { useEffect, useState, useCallback } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { getSubdomains } from "@api/miscApi";
import ScrollToTop from "@client/components/ScrollToTop";
import { WorkspaceDetails, WorkspaceParameterKey } from "@interface/types";

type Props = {
  children: JSX.Element | JSX.Element[];
};

type WorkspaceContext = {
  currentWorkspace: WorkspaceDetails | undefined;
  changeWorkspace: (idWorkspace: string) => void;
  getWorkspacesList: () => Array<WorkspaceDetails>;
};

const WorkspaceContext = React.createContext<WorkspaceContext>({
  currentWorkspace: undefined,
  changeWorkspace: () => {
    throw new Error("WorkspaceContext has not been initialized");
  },
  getWorkspacesList: () => {
    throw new Error("WorkspaceContext has not been initialized");
  }
});

function addReplaceSubdomain(url: string, toSubdomain: string) {
  const replace = "://" + toSubdomain + ".";
  const subdomainPattern = new RegExp(/(:\/\/\w+\.(?=\w+\.)(?=\w+))/g);
  const localhostPattern = new RegExp(/(:\/\/\w+\.(?=localhost\/*))/g);
  // Check if we got a subdomain in url, or we are on localhost

  //localhost cases
  if (url.indexOf("localhost") !== -1) {
    if (localhostPattern.test(url)) {
      return url.replace(/(:\/\/\w+\.+)/, replace);
    }
    return url.replace(/(:\/\/\w+)/, `${replace}localhost`); //localhost root case
  }
  //valid subdomain case
  if (subdomainPattern.test(url)) {
    return url.replace(/(:\/\/\w+\.+)/, replace);
  }

  //on root, not localhost
  return url.replace(/:\/\//, replace);
}

const WorkspaceProvider = ({ children }: Props): JSX.Element => {
  const [workspaceDetails, setWorkspaceDetails] = useState<WorkspaceDetails[]>(
    []
  );
  const [currentWorkspace, setCurrentWorksapce] = useState<WorkspaceDetails>();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    getSubdomains()
      .then((response) => {
        setWorkspaceDetails(response);
        if (response.length > 0) {
          const wsByVanityDomain = response.find((w) => {
            const vanityParam = w.Parameters.find(
              (p) => p.ParameterName === WorkspaceParameterKey.VanityDomain
            );
            if (vanityParam) {
              return vanityParam.ParameterValue === window.location.hostname;
            }
            return false;
          });

          if (wsByVanityDomain) {
            setCurrentWorksapce(wsByVanityDomain);
          } else {
            const splitHostName = window.location.hostname.split(".");
            let subdomain: string | undefined = undefined;
            //Use >= 3 here to allow for sub-subdomains in the future (ex. a.b.goalnav.ca)
            if (
              splitHostName.length >= 3 ||
              // using includes as localhost may include a Port Number
              splitHostName[1].includes("localhost")
            ) {
              subdomain = splitHostName[0];
            }
            const ws = response.find((d) => d.Subdomain === subdomain);
            setCurrentWorksapce(ws);
          }
        } else {
          setCurrentWorksapce(undefined);
        }
        setReady(true);
      })
      .catch((err) => {
        setReady(true);
        console.log(err);
      });
  }, []);

  const changeWorkspace = useCallback(
    (idWorkspace: string) => {
      if (currentWorkspace && currentWorkspace.idWorkspace === idWorkspace) {
        return; //no-op
      }

      if (workspaceDetails.length > 0) {
        const ws = workspaceDetails.find((w) => w.idWorkspace === idWorkspace);
        if (ws) {
          //When using the WS switchr, dont use vanity domains. This makes changing workspaces difficult as we lose
          //the host of the main site we are on.
          // const wsVanityDomain = ws.Parameters.find(
          //   (p) => p.ParameterName === WorkspaceParameterKey.VanityDomain
          // );

          // if (wsVanityDomain) {
          //   window.location.hostname = wsVanityDomain.ParameterValue;
          // } else {
          const wsSubdomain = ws.Subdomain;

          if (!wsSubdomain) {
            return; //no-op
          }

          //Build our new URL using the same protocol (http locally, https deployed)
          //The new WS's subdomain and the current host with current subdomain removed.
          const newHost = addReplaceSubdomain(
            window.location.href,
            wsSubdomain
          );

          //Redirect to the new WS url
          window.location.replace(newHost);
        }
      }
    },
    [workspaceDetails]
  );

  const getWorkspacesList = (): Array<WorkspaceDetails> => {
    return workspaceDetails;
  };

  return (
    <Router>
      <WorkspaceContext.Provider
        value={{
          currentWorkspace,
          changeWorkspace,
          getWorkspacesList
        }}
      >
        {ready ? children : null}
        <ScrollToTop />
      </WorkspaceContext.Provider>
    </Router>
  );
};

export const useWorkspace = (): WorkspaceContext => {
  const context = React.useContext(WorkspaceContext);
  if (context === undefined) {
    throw new Error("useWorkspace must be used within an WorkspaceProvider");
  }
  return context;
};

export default WorkspaceProvider;
