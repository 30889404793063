export const orderLanguages = (studyLanguages) => {
    return studyLanguages.sort((a, b) => {
        return Number(b.IsDefault) - Number(a.IsDefault);
    });
};
export const getNameValue = (nameTranslations, primaryName, requestedLang) => {
    if (!requestedLang) {
        //fall back to primary
        return primaryName;
    }
    const nameTranslation = nameTranslations.find((nameTranslation) => {
        return nameTranslation.languageTag === requestedLang.languageTag;
    });
    if (!nameTranslation) {
        //The primary lang does not exist in the translation array
        //So if we dont have the request language, return primary
        return primaryName;
    }
    return nameTranslation.TranslatedText;
};
export const getDescriptionValue = (descriptionTranslations, primaryLangTag, requestedLang) => {
    if (!requestedLang) {
        //fall back to primary
        const primaryDescription = descriptionTranslations.find((descriptionTranslation) => {
            return descriptionTranslation.languageTag === primaryLangTag;
        });
        if (!primaryDescription) {
            return ""; //Shouldnt get here
        }
        return primaryDescription.TranslatedText;
    }
    const requestedDescription = descriptionTranslations.find((descriptionTranslation) => {
        return descriptionTranslation.languageTag === requestedLang.languageTag;
    });
    if (!requestedDescription) {
        //fall back to primary
        const primaryDescription = descriptionTranslations.find((descriptionTranslation) => {
            return descriptionTranslation.languageTag === primaryLangTag;
        });
        if (!primaryDescription) {
            return ""; //Shouldnt get here
        }
        return primaryDescription.TranslatedText;
    }
    return requestedDescription.TranslatedText;
};
export const getSystemDescriptionValue = (comment, primaryLangTag, requestedLang, title) => {
    const systemAnnotation = JSON.parse(comment);
    if (systemAnnotation.warnings === undefined ||
        systemAnnotation.warnings[primaryLangTag].length === 0) {
        console.error("Error parsing system annotation");
        return [];
    }
    const translatedWarnings = systemAnnotation.warnings;
    let translatedComments = title ? [title, ""] : [];
    if (requestedLang) {
        translatedComments = translatedComments.concat(translatedWarnings[requestedLang.languageTag]);
    }
    else {
        translatedComments = translatedComments.concat(translatedWarnings[primaryLangTag]);
    }
    translatedComments.pop(); // Removing the empty string element from end of array to prevent <br> at end of comment
    return translatedComments;
};
