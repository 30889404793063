import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { AppType, StudyEventDef } from "@interface/types";
import { Add } from "@mui/icons-material";
import {
  getNameValue,
  getDescriptionValue
} from "@interface/helpers/studyLanguageHelpers";
import HTMLDescription from "@client/components/HTMLDescription";
import { useStudyLang } from "@providers/StudyLanguageProvider";

interface AddEventOptions {
  unscheduledEvents: StudyEventDef[];
  context?: AppType;
}

const AddEventServiceContext = React.createContext<
  (options: AddEventOptions) => Promise<StudyEventDef>
>(Promise.reject);

type Props = {
  children: JSX.Element | JSX.Element[];
};

const AddEventServiceProvider = ({ children }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [addEventOptions, setAddEventOptions] = useState<AddEventOptions>();
  const [selectedEvent, setSelectedEvent] = useState<StudyEventDef | undefined>(
    undefined
  );
  const { currentLanguage } = useStudyLang();
  const { primaryLanguageTag } = useStudyLang();
  useEffect(() => {
    if (addEventOptions) {
      setSelectedEvent(addEventOptions.unscheduledEvents[0]);
    }
  }, [addEventOptions]);

  const awaitingPromiseRef = useRef<{
    resolve: (value: StudyEventDef | PromiseLike<StudyEventDef>) => void;
    reject: () => void;
  }>();

  const clearAllState = () => {
    setSelectedEvent(undefined);
    setAddEventOptions(undefined);
  };

  const openPicker = useCallback((options: AddEventOptions) => {
    setAddEventOptions(options);
    return new Promise<StudyEventDef>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const handleCancel = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    clearAllState();
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current && selectedEvent) {
      awaitingPromiseRef.current.resolve(selectedEvent);
    }

    clearAllState();
  };

  const isConfirmDisabled = (): boolean => {
    return selectedEvent === undefined;
  };

  const getAddEventDisplay = () => {
    if (addEventOptions) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>
                {t("dataCollection.eventSelect", {
                  context: addEventOptions.context
                })}
              </InputLabel>
              <Select
                label={t("dataCollection.eventSelect", {
                  context: addEventOptions.context
                })}
                value={selectedEvent ? selectedEvent.idStudyEvent : ""}
                onChange={(e) => {
                  const selectedId = e.target.value as number;
                  const studyEvent = addEventOptions.unscheduledEvents.find(
                    (studyEventDef: StudyEventDef) => {
                      return studyEventDef.idStudyEvent === selectedId;
                    }
                  );
                  setSelectedEvent(studyEvent);
                }}
              >
                {addEventOptions.unscheduledEvents.map((e) => {
                  return (
                    <MenuItem key={e.idStudyEvent} value={e.idStudyEvent}>
                      {getNameValue(
                        e.NameTranslations,
                        e.Name,
                        currentLanguage
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {selectedEvent ? (
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              {/*<p>{t("dataCollection.eventType")}: {selectedEvent.Type}</p>*/}
              <HTMLDescription
                description={getDescriptionValue(
                  selectedEvent.Descriptions,
                  primaryLanguageTag,
                  currentLanguage
                )}
              />
            </Grid>
          ) : null}
        </Grid>
      );
    }
    return undefined;
  };

  return (
    <>
      <AddEventServiceContext.Provider value={openPicker}>
        {children}
      </AddEventServiceContext.Provider>
      <Dialog
        data-testid="add-event-modal"
        fullWidth
        open={Boolean(addEventOptions)}
        onClose={handleCancel}
      >
        {addEventOptions ? (
          <React.Fragment>
            <DialogTitle data-testid="add-event-title">
              {t("dataCollection.addEvent", {
                context: addEventOptions.context
              })}
            </DialogTitle>
            <DialogContent dividers>{getAddEventDisplay()}</DialogContent>
            <DialogActions>
              <Button
                data-testid="add-event-cancel"
                onClick={handleCancel}
                variant="outlined"
              >
                {t("dataCollection.addEventCancel")}
              </Button>
              <Button
                data-testid="add-event-ok"
                onClick={handleConfirm}
                color="primary"
                variant="outlined"
                startIcon={<Add />}
                disabled={isConfirmDisabled()}
              >
                {t("dataCollection.addEventOK", {
                  context: addEventOptions.context
                })}
              </Button>
            </DialogActions>
          </React.Fragment>
        ) : (
          <div></div>
        )}
      </Dialog>
    </>
  );
};

const useAddEvent = (): ((
  options: AddEventOptions
) => Promise<StudyEventDef>) => React.useContext(AddEventServiceContext);

export { AddEventServiceProvider, useAddEvent };
