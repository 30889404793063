import i18next from "i18next";
import { getEventData } from "./subjectHelpers";
import {
  TimingConstraint,
  TimingDuration,
  StudyEventData
} from "@interface/types";
import { DateObjectFormat } from "@interface/formats";
import moment from "moment";

export const isTimingDurationViolated = (
  now: Date,
  eventStartDate: Date,
  timingDuration: TimingDuration | Omit<TimingDuration, "idTimingDuration">
): string | false => {
  const momentNow = moment(now);
  const momentStartDate = moment(eventStartDate);

  //Add the target duration to the event's start date
  momentStartDate.add(moment.duration(timingDuration.Target));

  //If we have a Pre Window, subtract it from the start date now
  //TODO does this make sense to use? pre-window is earliest you are allowed to save?
  // if (timingDuration.PreWindow) {
  //     let newMomentStart = moment(eventStartDate);//need an extra moment because moment functions don't return a new value, they mutate original values
  //     if (momentNow.isBefore(newMomentStart.subtract(moment.duration(timingDuration.PreWindow)))) {
  //         //TODO return error message too early
  //         return "EARLY";
  //     }
  // }

  //Check if current time is after event start date plus duration
  if (momentNow.isAfter(momentStartDate)) {
    //If we have a post window defined, check if we are within that
    if (timingDuration.PostWindow) {
      //if current time is within the post window then return false (timing duration NOT violated)
      if (
        momentNow.isBefore(
          momentStartDate.add(moment.duration(timingDuration.PostWindow))
        )
      ) {
        return false;
      }
    }

    //Otherwise we are not within the duration, so return error text
    return i18next.t("dataCollection.timingDurationViolationLate");
  }

  //No duration violations, so return false
  return false;
};

export const checkTimingDurationViolation = (
  timingDurations: TimingDuration[],
  studyEventsData: StudyEventData[],
  idStudyEvent: number
): boolean => {
  const timingDuration = timingDurations.find((timingDuration) => {
    return timingDuration.idStudyEvent === idStudyEvent;
  });

  if (!timingDuration) {
    return false;
  }

  const studyEventDatum = getEventData(studyEventsData, idStudyEvent);

  if (!studyEventDatum) {
    return false;
  }

  const now = moment();
  const studyEventStartDate = moment(
    studyEventDatum.Timestamp,
    DateObjectFormat,
    true
  );

  const targetDate = studyEventStartDate
    .clone()
    .add(moment.duration(timingDuration.Target));

  if (timingDuration.PostWindow && timingDuration.PostWindow !== "P0D") {
    targetDate.add(moment.duration(timingDuration.PostWindow));
  }

  if (now.isAfter(targetDate)) {
    return true;
  }

  return false;
};

export const getStudyEventRTCDetails = (
  relativeTimingConstraints: TimingConstraint[],
  studyEventsData: StudyEventData[],
  idStudyEvent: number
): {
  startDate: Date;
  studyEventStartableNow: boolean;
  studyEventStartableLater: boolean;
  boundByTimingConstraint: boolean;
} => {
  const defaultStartDate = new Date();

  const relativeTC = relativeTimingConstraints.find((relativeTC) => {
    return relativeTC.idSuccessorEvent === idStudyEvent;
  });

  if (!relativeTC) {
    // Event does not have any associated relative timing constraints
    return {
      startDate: defaultStartDate,
      studyEventStartableNow: true,
      studyEventStartableLater: true,
      boundByTimingConstraint: false
    };
  }

  const previousStudyEventData = getEventData(
    studyEventsData,
    relativeTC.idPredEvent
  );

  if (!previousStudyEventData) {
    //throw new Error("Predecessor StudyEvent was not started");
    // Event cannot be started before Predecessor study event is completed
    return {
      startDate: defaultStartDate,
      studyEventStartableNow: false,
      studyEventStartableLater: false,
      boundByTimingConstraint: true
    };
  }

  const now = moment();

  const lastStudyEventDate = moment(
    previousStudyEventData.Timestamp,
    DateObjectFormat,
    true
  );

  //Add the target duration to the previous events start date
  const startDate = lastStudyEventDate
    .clone()
    .add(moment.duration(relativeTC.Target));

  const earliestStartDate = startDate.clone();

  //If we have a Pre Window, subtract it from the test date now
  if (relativeTC.PreWindow && relativeTC.PreWindow !== "P0D") {
    earliestStartDate.subtract(moment.duration(relativeTC.PreWindow));
  }

  // We have not reached the start window. Return the future date
  if (now.isBefore(earliestStartDate)) {
    return {
      startDate: earliestStartDate.toDate(),
      studyEventStartableNow: false,
      studyEventStartableLater: true,
      boundByTimingConstraint: true
    };
  }

  // We must be after the earliestStartDate
  const latestStartDate = startDate.clone();
  // We have entered the start window. Check if we have PostWindow
  if (relativeTC.PostWindow && relativeTC.PostWindow !== "P0D") {
    // Add the post window to get the last date to start
    latestStartDate.add(relativeTC.PostWindow);

    if (now.isAfter(latestStartDate)) {
      //throw new Error("StudyEvent can not be started as it is past its window");
      // We have crossed the last start date
      return {
        startDate: defaultStartDate,
        studyEventStartableNow: false,
        studyEventStartableLater: false,
        boundByTimingConstraint: true
      };
    }
  }
  // We dont have a PostWindow or are before PostWindow. Return current date as Start date
  return {
    startDate: defaultStartDate,
    studyEventStartableNow: true,
    studyEventStartableLater: true,
    boundByTimingConstraint: true
  };
};
