import { YesNo } from "../types";
import { getItemsForItemGroup } from "./formHelpers";
import { getDescriptionValue, getNameValue } from "./studyLanguageHelpers";
import { getSortedItemDefs, getOrderedEvents, sortStudyEventDataByTimestamp, getSortedItemGroupDefs } from "./sortHelpers";
import { BASELINE_EVENT_CATEGORY_NAME } from "../constants";
//We expect items for GAS to be set up in a specific way.
//These keys and names are how we reconcile back to real data
// export const GAS_IG_GROUP_KEY = "Domain";
export const GAS_BASELINE_KEY = "Baseline";
export const GAS_SCALE_KEY = "Scale";
export const GAS_ANCHOR_SCALE_KEY = "AnchorScale";
export const GAS_PLUS2_FIELD_NAME = "+2";
export const GAS_PLUS1_FIELD_NAME = "+1";
export const GAS_ZERO_FIELD_NAME = "0";
export const GAS_MINUS2_FIELD_NAME = "-2";
export const GAS_MINUS1_FIELD_NAME = "-1";
export const GAS_RANK_KEY = "Rank";
export const GAS_SCORE_KEY = "Score";
export const GAS_SUBJECT_SCORE_KEY = "SubjectScore";
export const GAS_FOLLOWUP_KEY = "Followup";
export const GAS_REPEAT_NAME_ITEM_KEY = "OtherName";
export const GAS_SYMPTOM_SECTION_KEY = "Symptom";
export var GasStepValue;
(function (GasStepValue) {
    GasStepValue[GasStepValue["SelectItems"] = 0] = "SelectItems";
    GasStepValue[GasStepValue["SelectDescriptorsApplyGas"] = 1] = "SelectDescriptorsApplyGas";
    GasStepValue[GasStepValue["Ranking"] = 2] = "Ranking";
})(GasStepValue || (GasStepValue = {}));
const getGasScales = () => {
    return [
        GAS_PLUS2_FIELD_NAME,
        GAS_PLUS1_FIELD_NAME,
        GAS_ZERO_FIELD_NAME,
        GAS_MINUS1_FIELD_NAME,
        GAS_MINUS2_FIELD_NAME
    ];
};
export const sortFormSectionValuesByRank = ({ formSectionValues, symptomItemGroupDefs, preStandardItemGroupDefs, postStandardItemGroupDefs, baselineItemGroupData, metaDataVersion }) => {
    const getRankValue = ({ rankItemDef, idItemGroup, formSectionValue, baselineItemGroupData }) => {
        const rankValueString = (() => {
            // if we have baselineItemGroupData. Find rank from baseline
            if (baselineItemGroupData) {
                const baselineItemGroupDatum = baselineItemGroupData.find((itemGroupDatum) => {
                    if (formSectionValue.RepeatKey) {
                        return (itemGroupDatum.idItemGroup === idItemGroup &&
                            itemGroupDatum.RepeatKey === formSectionValue.RepeatKey);
                    }
                    return itemGroupDatum.idItemGroup === idItemGroup;
                });
                if (!baselineItemGroupDatum) {
                    return undefined;
                }
                return baselineItemGroupDatum.ItemData.find((itemDatum) => {
                    return itemDatum.idItem === rankItemDef?.idItem;
                })?.Value;
            }
            return formSectionValue.QuestionValues.find((questionValue) => {
                return questionValue.idItem === rankItemDef?.idItem;
            })?.value;
        })();
        if (rankValueString) {
            return parseInt(rankValueString);
        }
        return undefined;
    };
    const outValues = [];
    //Sort the pre first in existing order, if present
    if (preStandardItemGroupDefs.length > 0) {
        formSectionValues.forEach((section) => {
            const isPreIG = preStandardItemGroupDefs.find((ig) => ig.idItemGroup === section.idItemGroup);
            if (isPreIG) {
                outValues.push(section);
            }
        });
    }
    //Now sort symptoms by Rank
    outValues.push(...formSectionValues
        .filter((section) => symptomItemGroupDefs.find((ig) => ig.idItemGroup === section.idItemGroup)) //Filter returns new array, so sort it in place now
        .sort((a, b) => {
        const itemGroupDefA = symptomItemGroupDefs.find((itemGroupDef) => {
            return itemGroupDef.idItemGroup === a.idItemGroup;
        });
        const itemGroupDefB = symptomItemGroupDefs.find((itemGroupDef) => {
            return itemGroupDef.idItemGroup === b.idItemGroup;
        });
        if (!itemGroupDefA || !itemGroupDefB) {
            throw Error("Missing ItemGroupDef for a FormSectionValue");
        }
        const itemDefsA = getSortedItemDefs(metaDataVersion, itemGroupDefA);
        const itemDefsB = getSortedItemDefs(metaDataVersion, itemGroupDefB);
        const rankItemDefA = itemDefsA.find((itemDef) => {
            return itemDef.Comment === GAS_RANK_KEY;
        });
        const rankItemDefB = itemDefsB.find((itemDef) => {
            return itemDef.Comment === GAS_RANK_KEY;
        });
        const rankValueA = getRankValue({
            rankItemDef: rankItemDefA,
            idItemGroup: itemGroupDefA.idItemGroup,
            baselineItemGroupData,
            formSectionValue: a
        });
        const rankValueB = getRankValue({
            rankItemDef: rankItemDefB,
            idItemGroup: itemGroupDefB.idItemGroup,
            baselineItemGroupData,
            formSectionValue: b
        });
        if (rankValueA && rankValueB) {
            return rankValueA - rankValueB;
        }
        return 0;
    }));
    //Sort the post now, if present
    if (postStandardItemGroupDefs.length > 0) {
        formSectionValues.forEach((section) => {
            const isPostIG = postStandardItemGroupDefs.find((ig) => ig.idItemGroup === section.idItemGroup);
            if (isPostIG) {
                outValues.push(section);
            }
        });
    }
    return outValues;
};
export const getAnchorScaleForItemGroup = (metaDataVersion, itemGroup) => {
    const itemDefs = getItemsForItemGroup(metaDataVersion.ItemDefs, itemGroup);
    //Get the anchor score questions
    const anchorQuestion = itemDefs.find((itemDef) => {
        if (!itemDef.Comment) {
            return;
        }
        return itemDef.Comment === GAS_ANCHOR_SCALE_KEY;
    });
    if (anchorQuestion) {
        //Find out which scale is our anchor. Will often be 0 or -1.
        return (getGasScales().find((scaleString) => {
            return anchorQuestion.Name.startsWith(scaleString);
        }) || "");
    }
    return "";
};
export const getRepeatNameValue = (metaDataVersion, itemGroup, itemGroupData) => {
    const itemDefs = getItemsForItemGroup(metaDataVersion.ItemDefs, itemGroup);
    const nameItemDef = itemDefs.find((itemDef) => {
        if (!itemDef.Comment) {
            return;
        }
        return itemDef.Comment === GAS_REPEAT_NAME_ITEM_KEY;
    });
    if (!nameItemDef) {
        return "";
    }
    //Find the data for this item
    const nameData = itemGroupData.ItemData.find((itemDatum) => {
        return itemDatum.idItem === nameItemDef.idItem;
    });
    if (!nameData) {
        return "";
    }
    return nameData.Value;
};
export const getRankItemForItemGroup = (itemDefs, itemGroupDefs, idItemGroup) => {
    const itemGroupDef = (() => {
        if (Array.isArray(itemGroupDefs)) {
            return itemGroupDefs.find((itemGroupDef) => {
                return itemGroupDef.idItemGroup === idItemGroup;
            });
        }
        // It is not an array
        return itemGroupDefs;
    })();
    if (!itemGroupDef) {
        return undefined;
    }
    const rankItem = itemDefs.find((itemDef) => {
        const itemRef = itemGroupDef.ItemRefs.find((itemRef) => {
            return itemRef.idItem === itemDef.idItem;
        });
        if (itemRef) {
            return itemDef.Comment === GAS_RANK_KEY;
        }
        return undefined;
    });
    return rankItem;
};
export const isBaselineStudyEvent = (idStudyEvent, idForm, studyEventDefs) => {
    //As baseline event is one with its Category set to Baseline and contains a ref to the given form.
    const eventDef = studyEventDefs.find((e) => e.idStudyEvent === idStudyEvent);
    if (eventDef) {
        if (eventDef.Category !== BASELINE_EVENT_CATEGORY_NAME) {
            return false; //Category not set, not baseline
        }
        const formRef = eventDef.FormRefs.find((r) => r.idForm === idForm);
        if (formRef) {
            return true; //Category set AND we have this GAS form
        }
    }
    return false;
};
export const getBaselineFormData = (idForm, baselineEventId, repeatKey, studyEventsData) => {
    if (!baselineEventId) {
        return undefined;
    }
    const baselineStudyEventDatum = studyEventsData.find((studyEventDatum) => {
        if (repeatKey) {
            return (studyEventDatum.RepeatKey === repeatKey &&
                studyEventDatum.idStudyEvent === baselineEventId);
        }
        return studyEventDatum.idStudyEvent === baselineEventId;
    });
    if (!baselineStudyEventDatum) {
        return undefined;
    }
    const baselineFormDatum = baselineStudyEventDatum.FormData.find((formDatum) => {
        return formDatum.idForm === idForm;
    });
    return baselineFormDatum;
};
//Checks if we have any follow up form data for a given form.
export const hasFollowupFormData = (studyEventDefs, studyEventsData, idForm) => {
    if (!idForm) {
        return false;
    }
    for (const eventData of studyEventsData) {
        const eventDef = studyEventDefs.find((e) => e.idStudyEvent === eventData.idStudyEvent);
        //Ignore baseline events
        if (!eventDef || eventDef.Category === BASELINE_EVENT_CATEGORY_NAME) {
            continue;
        }
        const formData = eventData.FormData.find((d) => d.idForm === idForm);
        if (formData) {
            return true;
        }
    }
    return false;
};
//Merges all the item group data together in event order for all baseline events
export const getMergedBaselineItemGroupData = (idForm, studyEventDefs, studyEventRefs, studyEventsData) => {
    if (!idForm) {
        return [];
    }
    const sortedEvents = getOrderedEvents(studyEventDefs, studyEventRefs, studyEventsData);
    const itemGroupData = [];
    for (const event of sortedEvents) {
        const def = studyEventDefs.find((e) => e.idStudyEvent === event.idStudyEvent);
        if (!def) {
            continue; //Shouldnt happen
        }
        //We only care about baseline events
        if (def.Category !== BASELINE_EVENT_CATEGORY_NAME) {
            continue;
        }
        const eventData = studyEventsData.find((d) => {
            if (event.repeatKey) {
                return (d.idStudyEvent === event.idStudyEvent &&
                    d.RepeatKey === event.repeatKey);
            }
            return d.idStudyEvent === event.idStudyEvent;
        });
        if (!eventData) {
            continue;
        }
        const formData = eventData.FormData.find((d) => d.idForm === idForm);
        if (!formData) {
            continue;
        }
        for (const igd of formData.ItemGroupData) {
            const found = itemGroupData.find((x) => x.idItemGroup === igd.idItemGroup && x.RepeatKey === igd.RepeatKey);
            if (!found) {
                itemGroupData.push(igd);
            } //TODO GN536: This will not display a changing anchor Q value. Only the first baseline this group appears in will show
        }
    }
    return itemGroupData;
};
export const sortGASItemGroupsData = (itemGroupData, itemGroupDefs, itemDefs) => {
    return itemGroupData.sort((a, b) => {
        const aItemGroupDef = itemGroupDefs.find((itemGroupDef) => itemGroupDef.idItemGroup === a.idItemGroup);
        const bItemGroupDef = itemGroupDefs.find((itemGroupDef) => itemGroupDef.idItemGroup === b.idItemGroup);
        if (!aItemGroupDef || !bItemGroupDef) {
            return 0;
        }
        const aRankItem = getRankItemForItemGroup(itemDefs, aItemGroupDef, aItemGroupDef.idItemGroup);
        const bRankItem = getRankItemForItemGroup(itemDefs, bItemGroupDef, bItemGroupDef.idItemGroup);
        if (!aRankItem || !bRankItem) {
            return 0;
        }
        const aRankData = a.ItemData.find((itemDatum) => {
            return itemDatum.idItem === aRankItem.idItem;
        });
        const bRankData = b.ItemData.find((itemDatum) => {
            return itemDatum.idItem === bRankItem.idItem;
        });
        if (aRankData && bRankData) {
            return parseInt(aRankData.Value) - parseInt(bRankData.Value);
        }
        return 0;
    });
};
export const filterItemGroup = (searchText, ig, primaryLanguageTag, t, currentLanguage) => {
    if (!searchText || searchText === "") {
        return true;
    }
    const itemGroupName = getNameValue(ig.NameTranslations, ig.Name, currentLanguage).toUpperCase();
    const itemDescription = getDescriptionValue(ig.Descriptions, primaryLanguageTag, currentLanguage).toUpperCase();
    const itemKeywords = ig.Comment?.toUpperCase() ?? "";
    console.log(t("keywords"));
    //creating a string[] to filter and replace each stopword found
    const stopwords = t("stopwords").split(", ");
    const splitText = searchText
        .trim()
        .split(/\s+/) //Split on any number of whitespace characters so they dont get included in the words list.
        .filter((key) => !stopwords.includes(key) && key.length >= 3);
    const include = splitText
        .map((searchWords) => searchWords.toUpperCase())
        .some(function (searchWord) {
        return (itemGroupName.indexOf(searchWord) !== -1 ||
            itemDescription.indexOf(searchWord) !== -1 ||
            itemKeywords.indexOf(searchWord) !== -1);
    });
    return include;
};
//Gets the last completed baseline event for the given form
//Mostly useful for the myGoalNav side as when adding an unscheduled event, the event data is not created right away.
export const getLastCompletedBaselineEventId = (studyEventDefs, studyEventsData, idForm) => {
    if (!idForm) {
        return undefined;
    }
    //Reverse sort so newest is on top
    const sortedEventData = sortStudyEventDataByTimestamp(studyEventsData, true);
    for (const data of sortedEventData) {
        const eventDef = studyEventDefs.find((e) => e.idStudyEvent === data.idStudyEvent);
        if (eventDef && eventDef.Category === BASELINE_EVENT_CATEGORY_NAME) {
            if (data.FormData.find((f) => f.idForm === idForm)) {
                return { idStudyEvent: data.idStudyEvent, repeatKey: data.RepeatKey };
            }
        }
    }
    return undefined;
};
//Get the baseline event id for the given current event.
//The passed in event may be a followup OR another baseline
export const getBaselineEventId = (studyEventDefs, studyEventRefs, studyEventsData, currentEventId, currentEventRepeatKey, idForm) => {
    if (!currentEventId) {
        //Need to know what event we are getting our baseline for
        return undefined;
    }
    const sortedEvents = getOrderedEvents(studyEventDefs, studyEventRefs, studyEventsData);
    let currentBaselineId = -1;
    let currentBaselineRepeatKey = undefined;
    for (const event of sortedEvents) {
        const def = studyEventDefs.find((e) => e.idStudyEvent === event.idStudyEvent);
        //Neither of these should be undefined. Check to keep TS happy
        if (!def) {
            continue;
        }
        //Ensure this event has this form
        const formRef = def.FormRefs.find((r) => r.idForm === idForm);
        if (!formRef) {
            continue;
        }
        //If this is us, break the loop. The baseline ID will be set (or -1 if we are the first baseline)
        if (event.idStudyEvent === currentEventId) {
            if (currentEventRepeatKey) {
                if (event.repeatKey && event.repeatKey === currentEventRepeatKey) {
                    break;
                }
            }
            else {
                break;
            }
        }
        //Check if this is a baseline event. If so, set our ID and keep going
        if (def.Category === BASELINE_EVENT_CATEGORY_NAME) {
            currentBaselineId = event.idStudyEvent;
            currentBaselineRepeatKey = event.repeatKey;
        }
    }
    return currentBaselineId === -1
        ? undefined
        : {
            idStudyEvent: currentBaselineId,
            repeatKey: currentBaselineRepeatKey
        };
};
// Get the most recent follow up for a gas form and its baseline.
export const getMostRecentFollowupFormDataWithBaseline = (studyEventsData, metaDataVersion, gasFormId) => {
    //Sort event data by date, newest first
    const sortedEventsData = sortStudyEventDataByTimestamp(studyEventsData, true);
    //Find our latest followup data and baseline for given gas form
    let followupFormData;
    let baselineFormData;
    for (const eventData of sortedEventsData) {
        const eventDef = metaDataVersion.StudyEventDefs.find((e) => e.idStudyEvent === eventData.idStudyEvent);
        const formData = eventData.FormData.find((f) => f.idForm === gasFormId);
        if (eventDef && formData) {
            //If we are still looking for a followup, this cant be a baseline, so check now
            if (!followupFormData &&
                eventDef.Category !== BASELINE_EVENT_CATEGORY_NAME) {
                followupFormData = formData;
            }
            //If we have our followup, need our baseline and this is a baseline, it must be the one we want
            if (followupFormData &&
                !baselineFormData &&
                eventDef.Category === BASELINE_EVENT_CATEGORY_NAME) {
                baselineFormData = formData;
            }
        }
        //We found both alread, break the loop
        if (followupFormData && baselineFormData) {
            break;
        }
    }
    return { baselineFormData, followupFormData };
};
export const getDefaultScaleOptions = (anchorItemDef) => {
    return ["+2", "+1", "0", "-1", "-2"].map((codedValue) => {
        let ratingText = "No Change";
        switch (true) {
            case anchorItemDef.Name === codedValue: {
                ratingText = "No Change";
                break;
            }
            case codedValue === "+2": {
                ratingText = "Much Better";
                break;
            }
            case codedValue === "+1": {
                ratingText = "Somewhat Better";
                break;
            }
            case codedValue === "0": {
                ratingText = "Expected Outcome";
                break;
            }
            case codedValue === "-1": {
                ratingText = "Somewhat Worse";
                break;
            }
            case codedValue === "-2": {
                ratingText = "Much Worse";
                break;
            }
        }
        return {
            idCodeListItem: 0,
            idCodeList: 0,
            CodedValue: codedValue,
            Decodes: [
                {
                    TranslatedText: ratingText,
                    languageTag: "en-ca",
                    idCodeListItem: 0
                }
            ]
        };
    });
};
export const getFollowupEventDataForBaseline = (studyEventsData, metaDataVersion, baselineEventId, gasFormId, baselineEventRepeatKey) => {
    //Sort event data by date, oldest first
    const sortedEventsData = sortStudyEventDataByTimestamp(studyEventsData);
    const followupEvents = [];
    //Loop the events. Start collecting event data once we pass our baseline
    let startCollecting = false;
    for (const eventData of sortedEventsData) {
        if (eventData.idStudyEvent === baselineEventId) {
            if (baselineEventRepeatKey &&
                baselineEventRepeatKey !== "" &&
                eventData.RepeatKey !== baselineEventRepeatKey) {
                //Not our baseline.
                //We may have have back to back baselines, so if we were collecting, we are done
                if (startCollecting) {
                    break;
                }
                continue;
            }
            //This is our baseline, start collecting
            startCollecting = true;
            continue;
        }
        if (startCollecting) {
            //Make sure we are not on the next baseline
            const eventDef = metaDataVersion.StudyEventDefs.find((d) => d.idStudyEvent === eventData.idStudyEvent);
            const formData = eventData.FormData.find((f) => f.idForm === gasFormId);
            if (formData) {
                if (eventDef?.Category === BASELINE_EVENT_CATEGORY_NAME) {
                    break; //We hit another baseline with this gas form, so we are done with followups for the passed baseline
                }
                //We have data for this gasForm and we are not a baseline, we must be a followup
                followupEvents.push(eventData);
            }
        }
    }
    return followupEvents;
};
export const getGasScoreDataByDateForItemGroup = (studyEventsData, metaDataVersion, itemGroupDef, anchorScore, itemGroupRepeatKey) => {
    let baselinePushed = false;
    const idItemGroup = itemGroupDef.idItemGroup;
    return sortStudyEventDataByTimestamp(studyEventsData)
        .reduce((data, g) => {
        const m = g.FormData.reduce((data, fd) => {
            const scoreItem = getItemsForItemGroup(metaDataVersion.ItemDefs, itemGroupDef).find((itemDef) => {
                return itemDef.Comment === GAS_SCORE_KEY;
            });
            if (!scoreItem) {
                throw new Error("ItemGroup does not have a rating item");
            }
            const itemGroupDatum = fd.ItemGroupData.find((itemGroupDatum) => {
                if (itemGroupRepeatKey) {
                    return (itemGroupDatum.idItemGroup === idItemGroup &&
                        itemGroupDatum.RepeatKey === itemGroupRepeatKey);
                }
                return itemGroupDatum.idItemGroup === idItemGroup;
            });
            if (!itemGroupDatum) {
                return data;
            }
            const scoreValue = itemGroupDatum.ItemData.find((itemDatum) => {
                return itemDatum.idItem === scoreItem.idItem;
            })?.Value;
            data.push(scoreValue);
            return data;
        }, []);
        if (m.length > 0) {
            if (m[0]) {
                const h = {
                    DateTimeStamp: g.Timestamp,
                    Value: parseFloat(m[0])
                };
                data.push(h);
            }
            else {
                //This must be the baseline
                if (!baselinePushed) {
                    const h = {
                        DateTimeStamp: g.Timestamp,
                        Value: anchorScore,
                        IsBaseline: true
                    };
                    data.push(h);
                    baselinePushed = true;
                }
            }
        }
        return data;
    }, [])
        .sort((a, b) => {
        return Date.parse(a.DateTimeStamp) - Date.parse(b.DateTimeStamp);
    });
};
export const getGasFormConfig = (formDef, mdv) => {
    const itemGroupDefs = getSortedItemGroupDefs(mdv, formDef);
    //The presense of a repating IG means we have user entered goals for this GAS form
    const userGoalItemGroup = itemGroupDefs.find((itemGroupDef) => {
        return itemGroupDef.Repeating === YesNo.Yes;
    });
    //If every IG has a rank option, this GAS form support goal ranking
    const hasRanking = itemGroupDefs.every((itemGroupDef) => {
        return (itemGroupDef.idItemGroup &&
            Boolean(getRankItemForItemGroup(mdv.ItemDefs, itemGroupDef, itemGroupDef.idItemGroup)));
    });
    //If every IG's rank item is marked as Mandatory, ranking is mandatory for this GAS form
    const rankingMandatory = hasRanking &&
        itemGroupDefs.every((itemGroupDef) => {
            const rankItemDef = getRankItemForItemGroup(mdv.ItemDefs, itemGroupDef, itemGroupDef.idItemGroup);
            if (!rankItemDef) {
                return false;
            }
            if (!itemGroupDef.ItemRefs) {
                throw Error(`Missing ItemRefs for ItemGroupDef ${itemGroupDef.Name}`);
            }
            const rankItemRef = itemGroupDef.ItemRefs.find((itemRef) => {
                return itemRef.idItem === rankItemDef.idItem;
            });
            return rankItemRef && rankItemRef.Mandatory === YesNo.Yes;
        });
    return {
        hasMenu: itemGroupDefs.length > 1, //A GAS form with more than 1 IG has a menu
        hasUserEnteredGoals: userGoalItemGroup !== undefined,
        hasRanking,
        rankingMandatory,
        userGoalItemGroup,
        menuItemGroups: itemGroupDefs.filter((ig) => ig.Repeating === YesNo.No)
    };
};
