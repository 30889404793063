import glossary_en from "./translations/en/glossary.json";
import common_en from "./translations/en/common.json";
import goalnav_en from "./translations/en/goalnav.json";
import validation_en from "./translations/en/validation.json";
import participant_en from "./translations/en/participant.json";
import forms_en from "./translations/en/forms.json";
import training_en from "./translations/en/training.json";
import views_en from "./translations/en/views.json";
import overrides_en from "./translations/en/overrides.json";
import manual_en from "./translations/en/manual.json";
// import common_fr from "./translations/fr/common.json";
// import validation_fr from "./translations/fr/validation.json";
const resources = {
    en: {
        glossary: glossary_en,
        common: common_en,
        goalnav: goalnav_en,
        validation: validation_en,
        participant: participant_en,
        forms: forms_en,
        manual: manual_en,
        training: training_en,
        views: views_en,
        overrides: overrides_en
    }
    // fr:{
    //   common:common_fr
    // }
};
const defaultLanguage = "en";
const defaultNS = "common";
export { resources, defaultLanguage, defaultNS };
