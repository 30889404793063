import React from "react";
import RHFInput from "@client/components/RHFInput";
import { makeStyles, useAuth } from "@client/providers";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Grid, Paper, Theme, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import selectors from "@interface/constants/selectors.json";
import {
  AuthResponsSSOAccountPayload,
  AuthResponseLocalAccountPayload,
  AuthStatus
} from "@interface/types";
import { ReactComponent as GNLogo } from "@client/images/logo.svg";
import { getAuthCardStyle } from "@client/helpers/themeHelpers";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: getAuthCardStyle(theme),
  gnLogo: {
    margin: theme.spacing(1),
    width: 45,
    height: 45
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    fontSize: "1.2em"
  }
}));

interface ICheckEmailPromptProps {
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  inviteId: string | undefined;
}

const CheckEmailPrompt = ({ setEmail, inviteId }: ICheckEmailPromptProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { checkAccountEmail } = useAuth();
  const isDev = process.env.NODE_ENV === "development";

  const emailSchema = z.object({
    email: z.string().email({ message: t("validation.email") })
  });

  const submitEmailCheck = (data: z.infer<typeof emailSchema>): void => {
    checkAccountEmail(data.email, inviteId)
      .then((res) => {
        if (res.status === AuthStatus.LocalAccount) {
          setEmail((res.body as AuthResponseLocalAccountPayload).email);
          return;
        }

        if (res.status === AuthStatus.SSOAccount) {
          //Redirect the user to their SSO signin page
          const body = res.body as AuthResponsSSOAccountPayload;
          console.info(`Redirecting SSO user to provider ${body.provider}`);
          window.location.replace(body.redirectUrl);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<z.infer<typeof emailSchema>>({
    resolver: zodResolver(emailSchema)
  });

  return (
    <Paper className={classes.paper}>
      <GNLogo className={classes.gnLogo} />
      <Typography component="h1" variant="h5">
        {t("signIn.header")}
      </Typography>
      <form className={classes.form}>
        <Grid container>
          <Grid item xs={12}>
            <RHFInput
              data-testid={selectors.views.CheckEmailPrompt.emailInput}
              fullWidth
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              label={t("signIn.email")}
              autoComplete="email"
              autoFocus
              required
              name={"email"}
              type="email"
              register={register}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "end" }}>
            <Button
              data-testid={selectors.views.CheckEmailPrompt.nextButton}
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!isValid}
              onClick={handleSubmit(submitEmailCheck)}
              type="submit"
            >
              {t("actions.next")}
            </Button>
          </Grid>
          {isDev && (
            <Grid item xs={12} style={{ textAlign: "end", marginTop: "15px" }}>
              <Button
                data-testid={selectors.views.CheckEmailPrompt.skipSSO}
                variant="contained"
                color="secondary"
                disabled={!isValid}
                onClick={handleSubmit((data) => setEmail(data.email))}
              >
                Force Local Account Login (Dev Only, Skips SSO)
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Paper>
  );
};

export default CheckEmailPrompt;
