import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import routes, { SSO_STATE, SSO_STATE_VERIFY_PARAM } from "@routes/routes";
import { getLoggedInUser } from "@api/userApi";
import {
  postLogin,
  getSessionLogout,
  createAccount,
  sendEmailVerification,
  sendPasswordResetEmail,
  verifyMFA,
  checkEmail,
  postSSOLogin
} from "@api/authApi";
import {
  AuthResponsSSOAccountPayload,
  AuthResponse,
  AuthStatus,
  User,
  VerifyAction
} from "@interface/types";
import { useSnackbar } from "@providers/SnackBarProvider";
import { useTranslation } from "react-i18next";
import {
  AuthError,
  CognitoError,
  ConnectionError,
  InviteError
} from "@interface/errors";
import { useWorkspace } from "./WorkspaceProvider";
import { Dialog } from "@mui/material";
import { SignIn } from "@client/views/SignIn";
import { openCenteredPopup } from "@client/helpers/windowHelpers";

interface ContextProps {
  user?: User;
  login: (
    email: string,
    password: string,
    idInvite?: string,
    verifyAction?: VerifyAction
  ) => Promise<AuthResponse>;
  signUp: (
    email: string,
    password: string,
    invite?: string
  ) => Promise<AuthResponse>;
  logout: () => void;
  verify: (action: VerifyAction) => Promise<AuthResponse>;
  refreshUser: () => void;
  passwordReset: (email: string) => Promise<void>;
  resendEmailVerification: (email: string) => Promise<void>;
  verifyMFACode: (
    mfaSession: string,
    userId: string,
    mfaCode: string,
    idInvite?: string,
    verifyAction?: VerifyAction
  ) => Promise<AuthResponse>;
  checkAccountEmail: (
    email: string,
    idInvite?: string
  ) => Promise<AuthResponse>;
  ssoLogin: (
    code: string,
    idInvite?: string,
    verifyAction?: VerifyAction
  ) => Promise<AuthResponse>;
}
const AuthContext = React.createContext<ContextProps>({
  login: () => {
    throw new Error("AuthContext has not been initialized");
  },
  signUp: () => {
    throw new Error("AuthContext has not been initialized");
  },
  logout: () => {
    throw new Error("AuthContext has not been initialized");
  },
  verify: () => {
    throw new Error("AuthContext has not been initialized");
  },
  refreshUser: () => {
    throw new Error("AuthContext has not been initialized");
  },
  passwordReset: () => {
    throw new Error("AuthContext has not been initialized");
  },
  resendEmailVerification: () => {
    throw new Error("AuthContext has not been initialized");
  },
  verifyMFACode: () => {
    throw new Error("AuthContext has not been initialized");
  },
  checkAccountEmail: () => {
    throw new Error("AuthContext has not been initialized");
  },
  ssoLogin: () => {
    throw new Error("AuthContext has not been initialized");
  }
});

type Props = { children: JSX.Element | JSX.Element[] };

const AuthProvider = ({ children }: Props): JSX.Element => {
  const [user, setUser] = useState<User>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { currentWorkspace } = useWorkspace();

  const [confirmEmailValue, setConfirmEmailValue] = useState<string>();
  const [verifyAction, setVerifyAction] = useState<VerifyAction>();
  const validationPromiseRef = useRef<{
    resolve: (authRes: AuthResponse) => void;
    reject: () => void; //
  }>();

  const cognitoErrorHandler = (errorMessage: string) => {
    //TODO map new cognito errors
    switch (errorMessage) {
      case "Incorrect username or password.":
        enqueueSnackbar(t("signIn.emailPasswordError"), { variant: "error" });
        throw new Error(errorMessage);
      case "signup-fail":
        enqueueSnackbar(t("forms:signUp.emailError"), { variant: "error" });
        throw new Error(errorMessage);
      case "signup-required":
        return navigate(routes.unauthorized, {
          state: { status: AuthStatus.SignupRequired, message: errorMessage }
        });
      case "idp-local-signup":
        enqueueSnackbar(t("signIn.sso.localSignup"), { variant: "error" });
        throw new Error(errorMessage);

      default:
        enqueueSnackbar(t("errors.generalError"), {
          variant: "error"
        });
        throw new Error(errorMessage);
    }
  };

  const errorHandler = (
    error: ConnectionError | CognitoError | InviteError | AuthError | Error
  ) => {
    switch (true) {
      case error instanceof ConnectionError:
        enqueueSnackbar(t("forms:signUp.networkError"), {
          variant: "error"
        });
        throw new Error(error.name);
      case error instanceof CognitoError:
        cognitoErrorHandler(error.message);
        break;
      case error instanceof InviteError:
        return navigate(routes.unauthorized, {
          state: { status: AuthStatus.InviteError, message: error.message }
        });
      case error instanceof AuthError:
        return navigate(routes.unauthorized, {
          state: { status: AuthStatus.UserNotFound, message: error.message }
        });
      default:
        enqueueSnackbar(t("errors.generalError"), {
          variant: "error"
        });
        throw new Error(error.name);
    }
  };

  const login = async (
    email: string,
    password: string,
    idInvite?: string,
    verifyAction?: VerifyAction
  ): Promise<AuthResponse> => {
    try {
      const authResponse = await postLogin({
        email,
        password,
        verifyAction
      });

      //This user has MFA enabled
      if (authResponse.status === AuthStatus.MFARequired) {
        return authResponse;
      }

      // Check if user has verified their email before allowing Sign in
      if (authResponse.status === AuthStatus.UnverifiedEmail) {
        await sendEmailVerification({ email });
        return authResponse;
      }

      //Dont set the user again if we are only verifying the login
      if (!verifyAction) {
        const user = await getLoggedInUser(idInvite);
        setUser(user);
      }

      return authResponse;
    } catch (error) {
      if (!verifyAction) {
        await getSessionLogout();
        setUser(undefined);
      }

      if (error instanceof Error) {
        errorHandler(error);
      }
      return { status: AuthStatus.GeneralError };
    }
  };

  const ssoLogin = async (
    code: string,
    idInvite?: string,
    verifyAction?: VerifyAction
  ): Promise<AuthResponse> => {
    try {
      const authResponse = await postSSOLogin({
        code,
        verifyAction
      });

      //Dont set the user again if we are only verifying the login
      if (!verifyAction) {
        const user = await getLoggedInUser(idInvite);
        setUser(user);
      }

      return authResponse;
    } catch (error) {
      if (!verifyAction) {
        await getSessionLogout();
        setUser(undefined);
      }

      if (error instanceof Error) {
        errorHandler(error);
      }
      return { status: AuthStatus.GeneralError };
    }
  };

  const signUp = async (
    email: string,
    password: string,
    idInvite?: string
  ): Promise<AuthResponse> => {
    return createAccount({ email, password, idInvite })
      .then((res) => {
        /* When user creates an account, it is always going to be unverified
        and we want users to verify their email before continuing */
        return res;
      })
      .catch((error) => {
        errorHandler(error);
        return { status: AuthStatus.GeneralError };
      });
  };

  const resendEmailVerification = async (email: string): Promise<void> => {
    await sendEmailVerification({ email });
    enqueueSnackbar(t("notifications.success.emailVerificationSent"), {
      variant: "success"
    });
  };

  const logout = () => {
    getSessionLogout().then(() => {
      setUser(undefined);
      navigate(routes.root);
    });
  };

  //useAuth doesn't refresh user on re-render, so this function gets an up to date instance of the user when we need for example the user's workspaces or workspaceRoles arrays to be refreshed
  const refreshUser = async () => {
    return getLoggedInUser()
      .then((u) => {
        setUser(u);
      })
      .catch((error) => {
        return errorHandler(error);
      });
  };

  const localVerify = async (
    user: User,
    action: VerifyAction
  ): Promise<AuthResponse> => {
    //The verify dialog is now part of this provider.
    //Set the users email and promise ref to start the process.
    //The SignIn flow will detect its in verify mode and update the promise as needed.
    setConfirmEmailValue(user.email);
    setVerifyAction(action);
    const authRes = await new Promise<AuthResponse>((resolve, reject) => {
      validationPromiseRef.current = { resolve, reject };
    });

    if (authRes.status === AuthStatus.UserCancelled) {
      enqueueSnackbar(t("dataCollection.userCancelledValidation"), {
        variant: "info"
      });
      return Promise.reject(new Error("User Cancelled"));
    }

    return authRes;
  };

  const ssoVerify = async (
    user: User,
    action: VerifyAction
  ): Promise<AuthResponse> => {
    //If user is SSO user, we need to do something different.
    //Open popup with the SSO auth url, handle redirect in popup and have popup message back to us
    //Get the login URL for this user
    const res = await checkAccountEmail(user.email);

    //If this is not an SSO response, something weird has happened
    if (res.status !== AuthStatus.SSOAccount) {
      return Promise.reject(new Error("SSO user: Provider not found"));
    }
    const { redirectUrl } = res.body as AuthResponsSSOAccountPayload;
    const parsedRedirectUrl = new URL(redirectUrl);
    //Append the verify flag to the state
    let redirectState = parsedRedirectUrl.searchParams.get(SSO_STATE) as string;
    if (redirectState) {
      redirectState += `&${SSO_STATE_VERIFY_PARAM}=${action}`;
    }

    parsedRedirectUrl.searchParams.set(SSO_STATE, redirectState);

    const result = await new Promise<AuthResponse>((resolve, reject) => {
      //Listen for messages from the popup window
      //TODO What do we do if the user closes the SSO popup window?
      window.addEventListener("message", function handle(event) {
        if (event.origin !== this.window.origin) {
          return;
        }

        const authRes = event.data as AuthResponse;
        if (!authRes.status) {
          return;
        }

        if (authRes.status === AuthStatus.Success) {
          resolve(authRes);
        } else {
          reject(new Error("Verify Error"));
        }

        //We dont need to listen any more so remove this event listener
        this.removeEventListener("message", handle);
      });

      //Open the popup
      const handle = openCenteredPopup(
        parsedRedirectUrl,
        600,
        600,
        "ssoWindow"
      );

      if (!handle) {
        reject(new Error("Error opening popup"));
      }
    });

    return result;
  };

  const verify = async (action: VerifyAction): Promise<AuthResponse> => {
    try {
      //We can only verify with an already logged in user
      if (!user) {
        enqueueSnackbar(t("dataCollection.signValidationError"), {
          variant: "error"
        });
        return Promise.reject(new Error("No User"));
      }

      const result = user.externalAccount
        ? await ssoVerify(user, action)
        : await localVerify(user, action);
      return result;
    } catch (error) {
      console.error(`Error from verify: ${error}`);
      if (error instanceof Error) {
        errorHandler(error);
      }
      return Promise.reject(new Error());
    } finally {
      setConfirmEmailValue(undefined);
      setVerifyAction(undefined);
      validationPromiseRef.current = undefined;
    }
  };

  const passwordReset = async (email: string) => {
    try {
      await sendPasswordResetEmail({ email });
      enqueueSnackbar(t("forgotPw.success"), { variant: "success" });
      return;
    } catch (error) {
      if (error instanceof CognitoError) {
        return cognitoErrorHandler(error.message);
      }
      return;
    }
  };

  const verifyMFACode = async (
    mfaSession: string,
    userId: string,
    mfaCode: string,
    idInvite?: string,
    verifyAction?: VerifyAction
  ): Promise<AuthResponse> => {
    try {
      const authResponse = await verifyMFA({
        mfaSession,
        userId,
        mfaCode,
        verifyAction
      });

      if (authResponse.status !== AuthStatus.Success) {
        throw Error(authResponse.message);
      }

      if (!verifyAction) {
        const user = await getLoggedInUser(idInvite);
        setUser(user);
      }

      return { status: AuthStatus.Success };
    } catch (error) {
      if (!verifyAction) {
        await getSessionLogout();
        setUser(undefined);
      }

      if (error instanceof Error) {
        errorHandler(error);
      }
      return { status: AuthStatus.GeneralError };
    }
  };

  const checkAccountEmail = async (
    email: string,
    idInvite?: string
  ): Promise<AuthResponse> => {
    try {
      return await checkEmail({ email, idInvite });
    } catch (error) {
      if (error instanceof Error) {
        errorHandler(error);
      }
      return { status: AuthStatus.GeneralError };
    }
  };

  useEffect(() => {
    if (currentWorkspace) {
      // On app load. Check if we have an existing session and can get user.
      getLoggedInUser()
        .then((response: User) => {
          setUser(response);
        })
        .catch((error: Error) => {
          console.error(error);
          return;
        });
    }
  }, [currentWorkspace]);

  const handleVerifyCancel = () => {
    if (validationPromiseRef.current) {
      validationPromiseRef.current.resolve({
        status: AuthStatus.UserCancelled
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        ssoLogin,
        signUp,
        logout,
        refreshUser,
        verify,
        passwordReset,
        resendEmailVerification,
        verifyMFACode,
        checkAccountEmail
      }}
    >
      {children}
      <Dialog
        data-testid="verify-modal"
        open={Boolean(confirmEmailValue) && Boolean(verifyAction)}
        onClose={handleVerifyCancel}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            containerName: "auth",
            containerType: "inline-size"
          }
        }}
      >
        {confirmEmailValue && verifyAction && (
          <SignIn
            email={confirmEmailValue}
            verifyAction={verifyAction}
            verifyPromiseRef={validationPromiseRef}
          />
        )}
      </Dialog>
    </AuthContext.Provider>
  );
};

const useAuth = (): ContextProps => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("userAuth must be used within an AuthProvider");
  }
  return context;
};

export { useAuth, AuthProvider };
