import { encrypt, decrypt } from "crypto-js/aes";
import utf8 from "crypto-js/enc-utf8";

const LANG_KEY = "lang";
const LAST_USED_SITES = "lastUsedSites";
const SUBDOMAIN_KEY = "subd";
// const REDIRECT_KEY = "redirect";

export const setLastUsedLanguage = (lang: string): void => {
  return localStorage.setItem(LANG_KEY, lang);
};

export const getLastUsedLanguage = (): string | null => {
  return localStorage.getItem(LANG_KEY);
};

export const setLastUsedSites = (studyOID: string, siteIds: number[]): void => {
  return localStorage.setItem(
    `${LAST_USED_SITES}-${studyOID}`,
    JSON.stringify(siteIds)
  );
};

export const getLastUsedSites = (studyOID: string): number[] => {
  const value = localStorage.getItem(`${LAST_USED_SITES}-${studyOID}`);

  if (!value) {
    return [];
  }

  return JSON.parse(value);
};

export const setLastUsedStudyLanguage = (
  studyId: string,
  langTag: string
): void => {
  return localStorage.setItem(studyId, langTag);
};

export const getLastUsedStudyLanguage = (studyId: string): string | null => {
  return localStorage.getItem(studyId);
};

export const setEncryptedValue = (
  storageKey: string,
  encryptionKey: string | undefined,
  payload: unknown
) => {
  if (!encryptionKey) {
    console.error("No encryption key provided");
    return;
  }
  try {
    window.localStorage.setItem(
      storageKey,
      encrypt(JSON.stringify(payload), encryptionKey).toString()
    );
  } catch (error) {
    //Possible the user has disabled local storage or has filled it
    console.error(error);
  }
};

export const getDecryptedValue = <T>(
  storageKey: string,
  encryptionKey: string | undefined
): T | undefined => {
  if (!encryptionKey) {
    return undefined;
  }

  const item = window.localStorage.getItem(storageKey);
  if (!item) {
    return undefined;
  }

  const decryptedBytes = decrypt(item, encryptionKey);
  const decrypted = decryptedBytes.toString(utf8);
  return JSON.parse(decrypted);
};

export const clearAllKeysInList = (keys: string[]) => {
  keys.forEach((k) => window.localStorage.removeItem(k));
};

export const buildStorageKey = (
  keyParts: Array<string | number | undefined>,
  separator?: string
) => {
  return keyParts.filter(Boolean).join(separator ?? ".");
};

export const setLastUsedSubdomain = (subdomain: string | undefined): void => {
  if (subdomain) {
    return localStorage.setItem(SUBDOMAIN_KEY, subdomain);
  }
};

export const getLastUsedSubdomain = (): string | null => {
  return localStorage.getItem(SUBDOMAIN_KEY);
};
