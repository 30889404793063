import React, { useState } from "react";

type StudyQueryInterface = {
  count: number;
  setCount: (count: number) => void;
};

const StudyQueryContext = React.createContext<StudyQueryInterface>({
  count: 0,
  setCount: () => {
    throw new Error(` QueryContext has not been initialized`);
  }
});
const StudyQueryProvider = ({
  children
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [count, setCount] = useState<number>(0);
  return (
    <StudyQueryContext.Provider value={{ count, setCount }}>
      {children}
    </StudyQueryContext.Provider>
  );
};

const useStudyQuery = (): StudyQueryInterface => {
  const context = React.useContext(StudyQueryContext);

  if (context === undefined) {
    throw new Error("useStudyQuery must be used within an StudyQueryProvider");
  }

  return context;
};

export { StudyQueryContext, StudyQueryProvider, useStudyQuery };
