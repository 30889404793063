import React, { useEffect, useMemo, useReducer } from "react";
import { useAuth } from "./AuthProvider";
import { getStudyById } from "@client/api/studyApi";
import routes from "@routes/routes";
import { isParticipantUser } from "@helpers/participantHelpers";
import { Study } from "@interface/types";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useWorkspace } from "./WorkspaceProvider";

type Props = {
  children?: JSX.Element | JSX.Element[];
};

type ContextProps = {
  study: Study | undefined;
  dispatchStudy: (action: Action) => void;
};

type Action =
  | {
      type: "setStudy";
      payload: Study;
    }
  | { type: "update"; payload: Study }
  | { type: "reset" };

const StudyContext = React.createContext<ContextProps>({
  study: undefined,
  dispatchStudy: (action: Action) => {
    throw new Error(
      `${action.type} cannot be completed as Study provider has not initialized`
    );
  }
});

const studyReducer = (prevState: Study | undefined, action: Action) => {
  switch (action.type) {
    case "setStudy":
      return { ...prevState, ...action.payload };
    case "update":
      return { ...prevState, ...action.payload };
    case "reset":
      return undefined;
    default:
      throw new Error("Invalid dispatch");
  }
};

export const StudyProvider = ({ children }: Props): JSX.Element => {
  // Params[
  //   `${routes.studies}${routes.editStudyNew}`,
  //   routes.studyReports
  // ]);
  const match = matchRoutes(
    [
      { path: `${routes.newStudy}` },
      { path: `${routes.editStudy}/*` },
      { path: `${routes.clinics}${routes.study}/*` },
      { path: `${routes.studies}${routes.study}/*` }
    ],
    useLocation()
  );

  //See if we are on the generic studies/contact route
  //We dont want to try and load a study if so.
  const studylessContactRouteMacth = matchRoutes(
    [{ path: `${routes.studies}${routes.contact}` }],
    useLocation()
  );

  const { user } = useAuth();
  const { currentWorkspace } = useWorkspace();

  const navigate = useNavigate();

  const [study, dispatchStudy] = useReducer(studyReducer, undefined);

  const { studyOID } = useMemo(() => {
    if (match && !studylessContactRouteMacth) {
      const params = match[0].params;
      const { studyId: studyOID } = params;
      return { studyOID };
    }
    return { studyOID: undefined };
  }, [match]);

  useEffect(() => {
    if (studyOID && currentWorkspace && user) {
      //setLoading(true);
      if (isParticipantUser(user)) {
        return;
      }

      getStudyById(studyOID)
        .then((study) => {
          dispatchStudy({ type: "setStudy", payload: study });
          //setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          // Most likely user is not authorized or not in correct state
          navigate(routes.root);
          //setLoading(false);
        });
    } else {
      dispatchStudy({ type: "reset" });
    }
  }, [studyOID, currentWorkspace, user]);

  return (
    <StudyContext.Provider value={{ study, dispatchStudy }}>
      {children}
    </StudyContext.Provider>
  );
};

export const useStudy = (): ContextProps => {
  const context = React.useContext(StudyContext);
  if (context === undefined) {
    throw new Error("useStudy must be used within an StudyProvider");
  }
  return context;
};
