export const ONBOARDING_EVENT_NAME_PREFIX = "Onboarding";
export const CHECKIN_EVENT_NAME_PREFIX = "Checkin";
export const BASELINE_EVENT_CATEGORY_NAME = "Baseline";
export const PROFILE_FORM_NAME_PREFIX = "Profile";
export const RESTRICTED_SUBDOMAIN_NAMES = ["training", "dev"];
export const RESTRICTED_DOMAIN_NAMES = ["goalnav.ca", "mygoalnav.ca"];
export const SESSION_COOKIE_NAME = "session-token-v2";
export const DOMAIN_ROOTS = []; //could RESTRICTED_SUBDOMAIN_NAMES and this array be the same?
export const routes = "./routes.json";
export const INVITE_HEADER = "x-goalnav-invite-id";
export const ACTION_TOKEN_NAME = "token";
export const ACTION_MODE_NAME = "mode";
export const SUBJECT_LEVEL_QUERY = "squery";
export const EVENT_LEVEL_QUERY = "equery";
export const FORM_LEVEL_QUERY = "fquery";
export const ITEM_GROUP_LEVEL_QUERY = "iquery";
