import { INVITE_HEADER } from "@interface/constants";
import {
  AddSubscriptionRequest,
  UpdateUserAttributeRequest,
  User
} from "@interface/types";
import FetchClient from "./FetchClient";

const USER_BASE = "/api/user";

export const getLoggedInUser = async (idInvite?: string): Promise<User> => {
  //this calls router.get "/" (which is /api/user) and only returns a response or error
  //api/user is sent to the user route by server.ts, which defines api/user as going to UserRoutes from this project's server/routes/user
  const fetchClient = new FetchClient();

  if (idInvite) {
    fetchClient.setHeader(INVITE_HEADER, idInvite);
  }

  //fetch is part of JS web api, returns res that is different from express router res
  //this res is different from the router express one, it is just part of basic web api, res.status and .ok are read only.

  return await fetchClient.get<User>({ path: USER_BASE });
};

export const setUserMFAPhoneNumber = async (
  phoneNumber: string
): Promise<void> => {
  const path = `${USER_BASE}/setMFAPhoneNumber`;
  return new FetchClient().post({ path, body: { phoneNumber } });
};

export const verifyUserMFAPhoneNumber = async (code: string): Promise<void> => {
  const path = `${USER_BASE}/verifyMFAPhoneNumber`;
  return new FetchClient().post({ path, body: { code } });
};

export const disableMFA = async (): Promise<void> => {
  const path = `${USER_BASE}/disableMFA`;
  return new FetchClient().delete({ path });
};

export const addUpdateUserAttribute = async (
  req: UpdateUserAttributeRequest
): Promise<void> => {
  const path = `${USER_BASE}/updateUserAttribute`;
  const fetchClient = new FetchClient();
  return await fetchClient.post({ path, body: req });
};

export const addSubscription = async (
  req: AddSubscriptionRequest
): Promise<void> => {
  const path = `${USER_BASE}/addSubscription`;
  const fetchClient = new FetchClient();
  return await fetchClient.post({ path, body: req });
};

export const removeSubscription = async (topicIds: number[]): Promise<void> => {
  const path = `${USER_BASE}/removeSubscriptions`;
  const fetchClient = new FetchClient();
  return await fetchClient.delete({ path, body: topicIds });
};
