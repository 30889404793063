import React, { useMemo, useState } from "react";
import { InteractiveTrainingTooltip } from "@client/components/InteractiveTrainingTooltip";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactJoyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step
} from "react-joyride";
import {
  StudyTrainingConfig,
  TrainingModuleName,
  UpdateUserAttributeRequest,
  UserAttributeKey,
  UserCompleteTrainingValue
} from "@interface/types";
import { useAuth } from "./AuthProvider";
import { addUpdateUserAttribute } from "@client/api/userApi";
import { getUserAttribute } from "@client/helpers/userAttributeHelpers";

export interface ExtendedStep extends Step {
  hideNextButton?: boolean;
  dependsOnPreviousTraining?: TrainingModuleName;
  showConfetti?: boolean;
  lastStepPrimaryButtonText?: string;
  lastStepPrimaryButtonIcon?: JSX.Element;
  lastStepShowSkip?: boolean;
  skipButtonText?: string;
  hideSkipIcon?: boolean;
  skipIcon?: JSX.Element;
  nextButtonText?: string;
  nextIcon?: JSX.Element;
}

export interface TrainingModule {
  moduleName: TrainingModuleName;
  steps: ExtendedStep[];
}

type TrainingState = {
  run: boolean;
  currentModuleSteps: ExtendedStep[];
  currentStep: number;
  modules: TrainingModule[];
  currentModuleIndex: number;
  lastFinishedModuleName?: TrainingModuleName;
  trainingContext?: StudyTrainingConfig;
};

type ContextProps = {
  loadTrainingModules: (
    modules: TrainingModule[],
    trainingContext: StudyTrainingConfig
  ) => void;
  startTraining: () => void;
  advanceTraining: (moduleName?: TrainingModuleName) => void;
  trainingRunning: boolean;
};

const InteractiveTrainingContext = React.createContext<ContextProps>({
  loadTrainingModules: () => {
    return false;
  },
  startTraining: () => {
    return false;
  },
  advanceTraining: () => {
    return false;
  },
  trainingRunning: false
});

export const InteractiveTrainingProvider = ({
  children
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [state, setState] = useState<TrainingState>({
    run: false,
    currentModuleSteps: [],
    currentStep: 0,
    modules: [],
    currentModuleIndex: 0,
    lastFinishedModuleName: undefined,
    trainingContext: undefined
  });
  const { t } = useTranslation();
  const { user, refreshUser } = useAuth();
  const theme = useTheme();
  const locale = useMemo(() => {
    return {
      back: t("nav.back"),
      close: t("nav.close"),
      last: t("nav.close"),
      next: t("nav.next"),
      skip: t("nav.skip")
    };
  }, []);
  const appbarHeight = theme.custom.appBarHeight?.valueOf();

  const getUserCompletedTraining = (studyOID: string): TrainingModuleName[] => {
    if (!user) {
      throw new Error("User not set.");
    }

    const attr = getUserAttribute(user, UserAttributeKey.CompletedTraining);

    if (!attr) {
      return [];
    }

    return (attr.Value as UserCompleteTrainingValue)[studyOID] || [];
  };

  const isTrainingModuleComplete = (
    moduleName: TrainingModuleName,
    studyOID: string
  ): boolean => {
    const userCompletedTraining = getUserCompletedTraining(studyOID);
    return userCompletedTraining.find((m) => m === moduleName) !== undefined;
  };

  const markAllTrainingComplete = (studyOID?: string) => {
    if (!user) {
      throw new Error("User not set.");
    }

    if (!studyOID) {
      console.error("No training study context provided.");
      return;
    }

    //Set all modules as completed
    const value: UserCompleteTrainingValue = {};
    value[studyOID] = Object.values(TrainingModuleName);
    updateCompletedTraining({
      idUser: user.userid,
      key: UserAttributeKey.CompletedTraining,
      value
    });
  };

  const markTrainingModuleComplete = (
    moduleName: TrainingModuleName,
    studyOID?: string
  ) => {
    if (!user) {
      throw new Error("User not set.");
    }

    if (!studyOID) {
      console.error("No training study context provided.");
      return;
    }

    const attr = getUserAttribute(user, UserAttributeKey.CompletedTraining);

    if (!attr) {
      //This is the first training we are marking for this user
      const value: UserCompleteTrainingValue = {};
      value[studyOID] = [moduleName];
      updateCompletedTraining({
        idUser: user.userid,
        key: UserAttributeKey.CompletedTraining,
        value
      });
    } else {
      //Update the training values
      const value = attr.Value as UserCompleteTrainingValue;
      const studyValues = value[studyOID];

      //Check if this module is not already marked as complete
      if (studyValues.find((v) => v === moduleName) === undefined) {
        //Add this module to this studies completed modules list
        value[studyOID] = [...value[studyOID], moduleName];
        updateCompletedTraining({
          idUser: user.userid,
          key: UserAttributeKey.CompletedTraining,
          value
        });
      }
    }
  };

  const updateCompletedTraining = (req: UpdateUserAttributeRequest) => {
    addUpdateUserAttribute(req)
      .then(() => {
        refreshUser();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loadTrainingModules = (
    modules: TrainingModule[],
    trainingContext: StudyTrainingConfig
  ) => {
    setState((prev) => {
      return {
        ...prev,
        trainingContext,
        run: false,
        currentModuleSteps: [],
        currentStep: 0,
        modules: modules.filter(
          (m) =>
            !isTrainingModuleComplete(m.moduleName, trainingContext.parentStudy)
        ), //Filter out any completed modules
        currentModuleIndex: 0
      };
    });
  };

  const startTraining = () => {
    setState((prev) => {
      if (prev.modules.length === 0) {
        console.debug("No modules");
        return prev;
      }

      const module = prev.modules[prev.currentModuleIndex];

      if (!module) {
        return prev;
      }

      //Filter out any steps that depend on a previous module that isnt our previous one.
      const steps = module.steps.filter((s) => {
        if (
          s.dependsOnPreviousTraining &&
          prev.lastFinishedModuleName !== s.dependsOnPreviousTraining
        ) {
          return false;
        }
        return true;
      });

      return {
        ...prev,
        run: true,
        currentModuleName: module.moduleName,
        currentModuleSteps: steps,
        currentStep: 0
      };
    });
  };

  const advanceTraining = (moduleName?: TrainingModuleName) => {
    if (state.run) {
      //If we passed in a module name, only advance if we are currently in that module
      if (
        moduleName &&
        moduleName !== state.modules[state.currentModuleIndex].moduleName
      ) {
        return;
      }

      setState((prev) => {
        return { ...prev, currentStep: prev.currentStep + 1, run: false };
      });

      //When manually advancing training, introduce a small delay to the tooltip renders in the right place
      setTimeout(() => {
        setState((prev) => {
          return { ...prev, run: true };
        });
      }, 300);
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, action, index } = data;

    if (state.modules[state.currentModuleIndex] === undefined) {
      return;
    }

    const currentModuleName =
      state.modules[state.currentModuleIndex]?.moduleName;

    if (status === STATUS.SKIPPED) {
      console.debug(`Training ${currentModuleName} skipped.`);

      if (currentModuleName === TrainingModuleName.Introduction) {
        //Mark all modules as complete when skipping the Introduction module
        markAllTrainingComplete(state.trainingContext?.parentStudy);
      } else {
        markTrainingModuleComplete(
          currentModuleName,
          state.trainingContext?.parentStudy
        );
      }

      setState((prev) => {
        return { ...prev, run: false, currentStep: 0 };
      });
      return;
    }

    if (status === STATUS.FINISHED) {
      console.debug(`Training ${currentModuleName} complete.`);
      markTrainingModuleComplete(
        currentModuleName,
        state.trainingContext?.parentStudy
      );

      //Update our state to the next module, which may not exist, and try and load it
      setState((prev) => {
        return {
          ...prev,
          run: false,
          currentStep: 0,
          currentModuleSteps: [],
          lastFinishedModuleName: currentModuleName,
          currentModuleIndex: prev.currentModuleIndex + 1
        };
      });
      startTraining();
      return;
    }

    if (([EVENTS.STEP_AFTER] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      //Go forward or back
      setState((prev) => {
        return {
          ...prev,
          currentStep: nextStepIndex
        };
      });
    }

    //We may be loading the next view/component. Stop the joyride and then resume after a small delay
    if (([EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      setState((prev) => {
        return { ...prev, run: false };
      });
      setTimeout(() => {
        setState((prev) => {
          return { ...prev, run: true };
        });
      }, 500);
    }
  };

  return (
    <InteractiveTrainingContext.Provider
      value={{
        loadTrainingModules,
        startTraining,
        advanceTraining,
        trainingRunning: state.run
      }}
    >
      {children}
      <ReactJoyride
        key={state.currentModuleIndex}
        tooltipComponent={InteractiveTrainingTooltip}
        steps={state.currentModuleSteps}
        stepIndex={state.currentStep}
        run={state.run}
        continuous
        callback={handleJoyrideCallback}
        spotlightPadding={5}
        disableCloseOnEsc
        disableOverlayClose
        hideCloseButton
        showSkipButton={!state.trainingContext?.required}
        styles={{
          options: {
            backgroundColor: theme.palette.background.paper,
            primaryColor: theme.palette.primary.main,
            zIndex: theme.zIndex.tooltip
          }
        }}
        scrollOffset={appbarHeight ? parseInt(appbarHeight.toString()) : 0} //Account for top bar
        locale={locale}
      />
    </InteractiveTrainingContext.Provider>
  );
};

export const useInteractiveTraining = (): ContextProps => {
  const context = React.useContext(InteractiveTrainingContext);
  if (context === undefined) {
    throw new Error(
      "useInteractiveTraining must be used within an InteractiveTrainingProvider"
    );
  }
  return context;
};
