import React from "react";
import { CircularProgress, Backdrop, Theme } from "@mui/material";
import { makeStyles } from "@providers";

const useStyles = makeStyles()((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  }
}));

type Props = {
  show?: boolean;
  isLoading?: boolean;
};

const LoadingSpinner = ({
  show = false,
  isLoading = false
}: Props): JSX.Element | null => {
  const { classes } = useStyles();

  return show || isLoading ? (
    <Backdrop open={show || isLoading} className={classes.backdrop}>
      <CircularProgress color="primary" variant="indeterminate" size={60} />
    </Backdrop>
  ) : null;
};

export default LoadingSpinner;
