import { GAS_SCORE_KEY, getMostRecentFollowupFormDataWithBaseline, getRankItemForItemGroup } from "./gasHelpers";
import { sortStudyEventDataByTimestamp } from "./sortHelpers";
import { getItemsForItemGroup } from "./formHelpers";
import { BASELINE_EVENT_CATEGORY_NAME } from "../constants";
import { getNameValue } from "./studyLanguageHelpers";
export const getTScoresForLatestFollowup = (subjectData, metaDataVersion, gasFormId, groupByDomain = false) => {
    //Find our latest followup data and baseline for given gas form
    const { baselineFormData, followupFormData } = getMostRecentFollowupFormDataWithBaseline(subjectData.StudyEventsData, metaDataVersion, gasFormId);
    //Sanity check
    if (!followupFormData || !baselineFormData) {
        return [];
    }
    //Group the IG data by domain, if requested
    let groups = {};
    if (groupByDomain) {
        groups = followupFormData.ItemGroupData.reduce((groups, igData) => {
            const igDef = metaDataVersion.ItemGroupDefs.find((g) => g.idItemGroup === igData.idItemGroup);
            const domain = igDef?.Domain || "";
            groups[domain] = groups[domain] || [];
            groups[domain].push(igData);
            return groups;
        }, {});
    }
    else {
        groups[""] = followupFormData.ItemGroupData;
    }
    //For each group, calculate the T Score
    const tScores = [];
    for (const key in groups) {
        const igData = groups[key];
        const tScore = calculateTScore(igData, getItemGroupScores(metaDataVersion, followupFormData), getItemGroupWeights(metaDataVersion, baselineFormData));
        tScores.push({ Count: igData.length, Value: tScore, Name: key });
    }
    return tScores;
};
export const getSubjectTScores = (subjectData, metaDataVersion, gasFormId, currentLanguage) => {
    //Sort event data by date
    const sortedEventsData = sortStudyEventDataByTimestamp(subjectData.StudyEventsData);
    //Loop the data in order collecting up t scores
    let currentBaseline = undefined;
    const baselineDetails = [];
    const followupTScores = [];
    for (const eventData of sortedEventsData) {
        const eventDef = metaDataVersion.StudyEventDefs.find((e) => e.idStudyEvent === eventData.idStudyEvent);
        if (!eventDef) {
            continue; //Shouldnt happen
        }
        const formData = eventData.FormData.find((f) => f.idForm === gasFormId);
        if (!formData) {
            continue; //this event doesnt have our GAS form. Skip.
        }
        const isBaseline = eventDef.Category === BASELINE_EVENT_CATEGORY_NAME;
        //Check if this is the first baseline. If so, set it and move on
        if (!currentBaseline && isBaseline) {
            currentBaseline = formData;
            continue;
        }
        //We have a current baseline and we are not a baseline ourselves, we are a followup
        if (currentBaseline && !isBaseline) {
            const scores = getItemGroupScores(metaDataVersion, formData);
            const followupTScore = calculateTScore(formData.ItemGroupData, scores, getItemGroupWeights(metaDataVersion, currentBaseline));
            const scoreSum = Object.values(scores).reduce((a, b) => a + b, 0);
            const scoreAvg = scoreSum / formData.ItemGroupData.length || 0;
            followupTScores.push({
                Timestamp: eventData.Timestamp,
                Count: formData.ItemGroupData.length,
                Value: followupTScore,
                AvgAttainment: parseFloat(scoreAvg.toFixed(2)),
                Name: getNameValue(eventDef.NameTranslations, eventDef.Name, currentLanguage)
            });
        }
        //Update our current baseline
        if (isBaseline) {
            currentBaseline = formData;
            //Only add this as a baseline event if we have already added followup data
            if (followupTScores.length > 0) {
                baselineDetails.push({
                    Timestamp: eventData.Timestamp,
                    Value: getNameValue(eventDef.NameTranslations, eventDef.Name, currentLanguage)
                });
            }
        }
    }
    return {
        BaselineDetails: baselineDetails,
        FollowupTScores: followupTScores
    };
};
export const getAverageTScore = (tScores) => {
    const sumTScores = tScores.reduce((a, b) => a + b.Value, 0);
    return sumTScores / tScores.length || 0;
};
export const getTScoreStdDeviation = (tscores, avgTScore) => {
    const sumOfSquaredDistanceFromMean = tscores.reduce((sum, data) => sum + Math.pow(data.Value - avgTScore, 2), 0);
    return Math.sqrt(sumOfSquaredDistanceFromMean / tscores.length);
};
const calculateTScore = (itemGroupData, scoresByItemGroup, weightByItemGroup) => {
    let sumOfWeights = 0;
    let squaredSumOfWeights = 0;
    let sumOfWeightsTimeScore = 0;
    for (const igData of itemGroupData) {
        const key = igData.RepeatKey && igData.RepeatKey !== ""
            ? igData.RepeatKey
            : igData.idItemGroup;
        const weight = weightByItemGroup[key];
        const score = scoresByItemGroup[key];
        sumOfWeights += weight;
        squaredSumOfWeights += Math.pow(weight, 2);
        sumOfWeightsTimeScore += weight * score;
    }
    const numerator = 10 * sumOfWeightsTimeScore;
    const denominator = Math.sqrt(0.7 * squaredSumOfWeights + 0.3 * Math.pow(sumOfWeights, 2));
    return parseFloat((50 + numerator / denominator).toFixed(1));
};
const getItemGroupScores = (metaDataVersion, followupFormData) => {
    const scores = {};
    for (const igData of followupFormData.ItemGroupData) {
        const igDef = metaDataVersion.ItemGroupDefs.find((g) => g.idItemGroup === igData.idItemGroup);
        if (!igDef) {
            continue;
        }
        const scoreItem = getItemsForItemGroup(metaDataVersion.ItemDefs, igDef).find((itemDef) => {
            return itemDef.Comment === GAS_SCORE_KEY;
        });
        if (!scoreItem) {
            continue;
        }
        const scoreValue = igData.ItemData.find((itemDatum) => {
            return itemDatum.idItem === scoreItem.idItem;
        })?.Value;
        if (!scoreValue) {
            continue;
        }
        const value = parseFloat(scoreValue);
        igData.RepeatKey && igData.RepeatKey !== ""
            ? (scores[igData.RepeatKey] = value)
            : (scores[igData.idItemGroup] = value);
    }
    return scores;
};
const getItemGroupWeights = (metadataversion, baselineFormData) => {
    const weights = {};
    const igCount = baselineFormData.ItemGroupData.length;
    for (const igData of baselineFormData.ItemGroupData) {
        const rankItem = getRankItemForItemGroup(metadataversion.ItemDefs, metadataversion.ItemGroupDefs, igData.idItemGroup);
        if (!rankItem) {
            //We dont have a rank item for an IG, return an equal weighting now
            return getEqualWeightingForItemGroups(baselineFormData.ItemGroupData);
        }
        const rankValue = igData.ItemData.find((i) => i.idItem === rankItem.idItem);
        if (!rankValue) {
            //We dont have a rank value for an IG, return an equal weighting now
            return getEqualWeightingForItemGroups(baselineFormData.ItemGroupData);
        }
        const rankNumericValue = parseInt(rankValue.Value);
        //Weight is the inverse rank value.
        //A value ranked at 1 should have a weight equal to the count of item groups
        //Take 1 off the rank as we rank starting with 1 not 0
        const weight = igCount - (rankNumericValue - 1);
        //Set the weight value based on the rank
        igData.RepeatKey && igData.RepeatKey !== ""
            ? (weights[igData.RepeatKey] = weight)
            : (weights[igData.idItemGroup] = weight);
    }
    return weights;
};
const getEqualWeightingForItemGroups = (itemGroupData) => {
    return itemGroupData.reduce((ranks, igData) => {
        igData.RepeatKey && igData.RepeatKey !== ""
            ? (ranks[igData.RepeatKey] = 1)
            : (ranks[igData.idItemGroup] = 1);
        return ranks;
    }, {});
};
