import React, { useState } from "react";
import {
  Avatar,
  Button,
  Link,
  Grid,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
  Theme,
  OutlinedInput
} from "@mui/material";
import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles, useAuth, useWorkspace } from "@providers";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RHFInput from "@client/components/RHFInput";
import SubdomainSelector from "@client/components/SubdomainSelector";
import { useAnalytics } from "@client/hooks/useAnalytics";
import {
  AnalyticsEvent,
  AnalyticsMetric,
  AuthResponse,
  AuthResponseMFAPayload,
  AuthResponseUnverifiedEmailPayload,
  AuthStatus,
  VerifyAction
} from "@interface/types";
import selectors from "@interface/constants/selectors.json";
import UnverifiedEmailView from "./UnverifiedEmailView";
import MultiFactorPrompt from "./MultiFactorPrompt";
import CheckEmailPrompt from "./CheckEmailPrompt";
import { getAuthCardStyle } from "@client/helpers/themeHelpers";
import routes from "@client/routes/routes";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: getAuthCardStyle(theme),
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    fontSize: "1.2em",
    margin: theme.spacing(3, 0, 2)
  }
}));
interface ISignInProps {
  email?: string;
  verifyAction?: VerifyAction;
  verifyPromiseRef?: React.MutableRefObject<
    | {
        resolve: (authRes: AuthResponse) => void;
        reject: () => void;
      }
    | undefined
  >;
}

const SignIn = ({
  email: passedEmail,
  verifyAction,
  verifyPromiseRef
}: ISignInProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { login } = useAuth();
  const { inviteId } = useParams<{ inviteId: string }>();
  const { currentWorkspace } = useWorkspace();
  const { sendEvent } = useAnalytics();
  const [loginAttempts, setLoginAttempts] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [authResponse, setAuthResponse] = useState<AuthResponse>();
  const location = useLocation();
  const state = location.state as { email: string } | null;

  const [email, setEmail] = useState<string | undefined>(
    passedEmail || state?.email
  );

  const loginSchema = z.object({
    password: z.string()
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema)
  });

  const submitLocalLogin = (data: z.infer<typeof loginSchema>) => {
    if (email) {
      login(email, data.password, inviteId, verifyAction)
        .then((res: AuthResponse) => {
          if (!verifyAction) {
            sendEvent(
              AnalyticsEvent.LoginEvent,
              {},
              { [AnalyticsMetric.LoginAttempts]: loginAttempts }
            );
          }

          //This may be a success, or we may need to show different UI now
          setAuthResponse(res);

          if (res.status === AuthStatus.Success && verifyPromiseRef?.current) {
            verifyPromiseRef.current.resolve(res);
          }
        })
        .catch((error: Error) => {
          console.log(error);
          if (!verifyAction) {
            sendEvent(
              AnalyticsEvent.AuthFail,
              {},
              { [AnalyticsMetric.LoginAttempts]: loginAttempts }
            );
          }
          if (verifyPromiseRef?.current) {
            verifyPromiseRef.current.reject();
          }
        });
      setLoginAttempts(loginAttempts + 1);
    }
  };

  //Show the subdomain selector when the user is not currently in a workspace sucdomain
  if (!currentWorkspace) {
    return <SubdomainSelector />;
  }

  //Show the email get screen
  if (!email) {
    return <CheckEmailPrompt setEmail={setEmail} inviteId={inviteId} />;
  }

  //Respond to MFA challenge
  if (authResponse && authResponse.status === AuthStatus.MFARequired) {
    return (
      <Paper className={classes.paper}>
        <MultiFactorPrompt
          responsePayload={authResponse.body as AuthResponseMFAPayload}
          verifyAction={verifyAction}
          verifyPromiseRef={verifyPromiseRef}
          inviteId={inviteId}
        />
      </Paper>
    );
  }

  //The user does not yet have a verified email
  if (authResponse && authResponse.status === AuthStatus.UnverifiedEmail) {
    return (
      <UnverifiedEmailView
        responsePayload={
          authResponse.body as AuthResponseUnverifiedEmailPayload
        }
      />
    );
  }

  //Standard local account login page
  return (
    <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("signIn.header")}
      </Typography>
      <form onSubmit={handleSubmit(submitLocalLogin)} className={classes.form}>
        <OutlinedInput fullWidth disabled value={email} />
        <RHFInput
          data-testid={selectors.views.SignIn.passwordInput}
          fullWidth
          error={errors.password && true}
          helperText={errors.password?.message}
          label={t("signIn.password")}
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          required
          name={"password"}
          register={register}
        />
        <Button
          data-testid={selectors.views.SignIn.loginButton}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {t("signIn.header")}
        </Button>
        {!Boolean(verifyAction) && (
          <Grid container>
            <Grid item xs>
              <Link
                component={RouterLink}
                to="/forgotpw"
                variant="body2"
                state={{ email }}
              >
                {t("signIn.forgotpw")}
              </Link>
            </Grid>
            <Grid item>
              <Link
                component={RouterLink}
                data-testid="signup-link"
                to={
                  inviteId ? `${routes.signup}/${inviteId}` : `${routes.signup}`
                }
                state={{ email }}
                variant="body2"
              >
                {t("signIn.signup")}
              </Link>
            </Grid>
          </Grid>
        )}
      </form>
    </Paper>
  );
};

export default SignIn;
