import { WorkspaceDetails } from "@interface/types";
import FetchClient from "./FetchClient";

const MISC_BASE = "/api/misc";

export const getSubdomains = async (): Promise<Array<WorkspaceDetails>> => {
  return new FetchClient().get<Array<WorkspaceDetails>>({
    path: `${MISC_BASE}/subdomains`
  });
};
