import React, { useEffect, useState } from "react";
import {
  Annotation,
  AnnotationStatus,
  DataLevel,
  QueryHistoryRequest
} from "@interface/types";
import { getQueryHistory } from "@client/api/annotationApi";
import { useSnackbar, useStudyLang } from "@client/providers";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Typography
} from "@mui/material";
import { displayComments } from "@client/helpers/queryHelpers";
import { ArrowDropUp } from "@mui/icons-material";
import { dateTimeFormatOptions } from "@interface/formats";
import { capitalizeStringFirstLetter } from "@client/helpers/stringHelpers";

interface IQueryHistoryProps {
  OID: string;
  idData: number;
  dataLevel: DataLevel;
}

const QueryHistory = ({ OID, idData, dataLevel }: IQueryHistoryProps) => {
  const [pastQueries, setPastQueries] = useState<Annotation[]>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { currentLanguage, primaryLanguageTag } = useStudyLang();

  useEffect(() => {
    setLoading(true);
    const req: QueryHistoryRequest = {
      OID,
      idData,
      dataLevel
    };
    getQueryHistory(req)
      .then((data) => {
        setPastQueries(data);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("error.generalError"), { variant: "error" });
      })
      .finally(() => setLoading(false));
  }, []);

  const buildQueryAccordions = () => {
    const parents = pastQueries
      .filter((q) => !q.parentId)
      .sort((a, b) => {
        return (
          new Date(b.Timestamp).getTime() - new Date(a.Timestamp).getTime()
        );
      });

    return parents.map((pQ) => {
      const children = pastQueries
        .filter((q) => q.parentId === pQ.id)
        .sort((a, b) => {
          return (
            new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime()
          );
        });

      return (
        <Accordion key={pQ.id}>
          <AccordionSummary
            style={{ marginTop: "10px" }}
            expandIcon={<ArrowDropUp />}
          >
            <Typography variant="body1">
              {capitalizeStringFirstLetter(pQ.DisplayUser)}
              <br />
              <Typography variant="caption" color={"grey"}>
                {new Date(pQ.Timestamp).toLocaleDateString(
                  undefined,
                  dateTimeFormatOptions
                )}
                {pQ.Status === AnnotationStatus.Unresolvable && (
                  <Typography color="red">
                    {t("dataCollection.closedUnresolvable")}
                  </Typography>
                )}
              </Typography>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {[pQ, ...children].map((q) => (
                <ListItem key={q.id} alignItems="flex-start">
                  {displayComments(
                    q,
                    primaryLanguageTag,
                    currentLanguage,
                    true
                  )}
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const getQueryHistoryDisplay = () => {
    if (loading) {
      return null;
    }

    if (!pastQueries || pastQueries.length === 0) {
      return <Typography>{t("dataCollection.noPreviousQueries")}</Typography>;
    }

    return buildQueryAccordions();
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {getQueryHistoryDisplay()}
      </Grid>
      {loading && (
        <CircularProgress
          color="primary"
          variant="indeterminate"
          size={60}
          sx={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px" }}
        />
      )}
    </Grid>
  );
};

export default QueryHistory;
