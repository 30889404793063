import React from "react";
import { createRoot } from "react-dom/client";
import AppProviders from "@providers/AppProviders";

import "./i18n";

// Import polyfills
import "core-js/proposals/object-from-entries";

import App from "@client/views/App";
import ErrorBoundary from "@client/components/ErrorBoundary";

import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  "9e34b8c3a48865a06bab9dd709103082Tz04MzMwNyxFPTE3MzgzMjUxOTIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

console.log("%cWarning", "color:red; background-color:yellow; font-size:20px");
console.log(
  "%cUsing this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS.",
  "color:yellow; font-size:16px"
);
console.log(
  "%cPasting anything in here could give attackers access to your account.",
  "color:red; font-size:16px"
);

const container = document.getElementById("root");

if (!container) {
  throw Error("Unable to find root element.");
}

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <AppProviders>
        <App />
      </AppProviders>
    </ErrorBoundary>
  </React.StrictMode>
);
