import React, { useCallback, useEffect, useState } from "react";
import { defineAbility, subject } from "@casl/ability";
import {
  defineAbilityForWorkspace,
  WorkspaceAbility,
  WorkspaceActions
} from "@interface/auth";
import { useAuth } from "./AuthProvider";
import { useWorkspace } from "./WorkspaceProvider";

type ContextProps = {
  workspaceCan: (ability: WorkspaceActions) => boolean;
  workspaceCannot: (ability: WorkspaceActions) => boolean;
};

const WorkspaceAbilityContext = React.createContext<ContextProps>({
  workspaceCan: () => {
    return false;
  },
  workspaceCannot: () => {
    return true;
  }
});

export const WorkspaceAbilityProvider = ({
  children
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { currentWorkspace } = useWorkspace();
  const { user } = useAuth();

  const [workspaceAbility, setWorkspaceAbility] = useState<WorkspaceAbility>(
    defineAbility(() => {
      return;
    }) as WorkspaceAbility
  );

  const workspaceCan = useCallback(
    (ability: WorkspaceActions): boolean => {
      if (!currentWorkspace) {
        return false;
      }

      return workspaceAbility.can(
        ability,
        subject("Workspace", currentWorkspace)
      );
    },
    [currentWorkspace, workspaceAbility]
  );

  const workspaceCannot = useCallback(
    (ability: WorkspaceActions): boolean => {
      if (!currentWorkspace) {
        return false;
      }

      return workspaceAbility.cannot(
        ability,
        subject("Workspace", currentWorkspace)
      );
    },
    [currentWorkspace, workspaceAbility]
  );

  useEffect(() => {
    if (user && currentWorkspace) {
      defineAbilityForWorkspace(user, currentWorkspace.idWorkspace).then(
        (ability) => setWorkspaceAbility(ability)
      );
    }
  }, [user, currentWorkspace]);

  return (
    <WorkspaceAbilityContext.Provider value={{ workspaceCan, workspaceCannot }}>
      {children}
    </WorkspaceAbilityContext.Provider>
  );
};

export const useWorkspaceAbility = (): ContextProps => {
  const context = React.useContext(WorkspaceAbilityContext);
  if (context === undefined) {
    throw new Error(
      "useWorkspaceAbility must be used within an WorkspaceAbilityProvider"
    );
  }
  return context;
};
