import React from "react";
import { DraftInlineStyle, ContentState } from "draft-js";
import { convertFromHTML } from "draft-convert";
import sanitize, { Attributes } from "sanitize-html";
import { FormType } from "@interface/types";
import { Tooltip } from "@mui/material";
import { Accessibility, ListAlt, PersonAdd } from "@mui/icons-material";
import i18next from "i18next";

export const splitCamelCase = (text: string): string => {
  return text.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
};

/*
const tagBlacklist = [
  "APPLET",
  "AUDIO",
  "BASE",
  "BGSOUND",
  "EMBED",
  // Blacklisted by default, can be allowed using allowFormTag.
  "FORM",
  // NOTE: can remove this for old browser behavior
  "IFRAME",
  // Can result in network requests
  "ISINDEX",
  // Unused and just unnecessarily increase attack surface
  "KEYGEN",
  "LAYER",
  "LINK",
  "META",
  "OBJECT",
  "SCRIPT",
  // Can result in an XSS in FF
  // https://bugzilla.mozilla.org/show_bug.cgi?id=1205631
  //"SVG",
  // Blacklisted by default, can be allowed using allowStyleTag.
  "STYLE",
  // Unsafe in most cases, and sanitizing its contents is not supported by the
  // underlying SafeDomTreeProcessor.
  "TEMPLATE",
  "VIDEO"
];

*/
/*exclusiveFilter: frame => {
  return tagBlacklist.includes(frame.tag.toUpperCase());
}*/

export const createSvgNode = (data: string): JSX.Element => {
  const sanitized = sanitizeCustomIconData(data);
  return <svg dangerouslySetInnerHTML={{ __html: sanitized }}></svg>;
};

export const createSvgMarkup = (data: string): { __html: string } => {
  const sanitized = sanitizeCustomIconData(data);
  return { __html: sanitized };
};

const sanitizeCustomIconData = (data: string): string => {
  return sanitize(data, {
    allowedTags: [
      "svg",
      "path",
      "circle",
      "rect",
      "polygon",
      "ellipse",
      "g",
      "line",
      "linearGradient",
      "stop"
    ],
    // dont filter attributes
    allowedAttributes: false,
    transformTags: {
      svg: (tagName: string, attribs: Attributes) => {
        // Remove width and height from svg
        const { width, height, fill, ...rest } = attribs; //eslint-disable-line @typescript-eslint/no-unused-vars -- We want to omit some attributes
        return { tagName, attribs: rest };
      }
      // rect: (tagName: string, attribs: any) => {
      //   // Remove fill
      //   const { fill, ...rest } = attribs;
      //   return { tagName, attribs: rest };
      // },
      // circle: (tagName: string, attribs: any) => {
      //   // Remove fill
      //   const { fill, ...rest } = attribs;
      //   return { tagName, attribs: rest };
      // },
      // path: (tagName: string, attribs: any) => {
      //   // Remove fill
      //   const { fill, stroke, ...rest } = attribs;
      //   return { tagName, attribs: rest };
      // },
      // line: (tagName: string, attribs: any) => {
      //   // Remove fill
      //   const { stroke, ...rest } = attribs;
      //   return { tagName, attribs: rest };
      // },
      // g: (tagName: string, attribs: any) => {
      //   // Remove fill
      //   const { fill, ...rest } = attribs;
      //   return { tagName, attribs: rest };
      // }
    },
    parser: {
      lowerCaseAttributeNames: false
    }
  });
};

export const sanitizeHTML = (
  htmlText: string,
  htmlStyler?: (
    tagName: string,
    attribs: { [index: string]: string }
  ) => { tagName: string; attribs: { [index: string]: string } }
): string => {
  const extraOptions: Partial<sanitize.IOptions> = { transformTags: undefined };
  if (htmlStyler) {
    extraOptions.transformTags = { "*": htmlStyler };
  }

  const additionalAttributes = {
    h1: ["class"],
    h2: ["class"],
    h3: ["class"],
    h4: ["class"],
    h5: ["class"],
    h6: ["class"],
    p: ["class"],
    mark: ["class"]
  };

  return sanitize(htmlText, {
    allowedTags: [
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "p",
      "b",
      "i",
      "strong",
      "div",
      "ul",
      "li",
      "sup",
      "sub",
      "u",
      "s",
      "pre",
      "code",
      "q",
      "td",
      "tr",
      "table",
      "br",
      "a",
      "mark"
    ],
    allowedAttributes: Object.assign(
      additionalAttributes,
      sanitize.defaults.allowedAttributes
    ),
    ...extraOptions
  });
};

export const convertToRTF = (translatedText: string): ContentState => {
  // 1. Create ContentState from HTML
  const convertedText = convertFromHTML({
    htmlToStyle: (
      nodeName: string,
      node: HTMLElement,
      currentStyle
    ): DraftInlineStyle => {
      if (nodeName === "mark") {
        return currentStyle.add("HIGHLIGHT");
      }
      return currentStyle;
    },
    htmlToEntity: (nodeName: string, node: HTMLElement, createEntity) => {
      if (nodeName === "a") {
        const htmlLinkNode = node as HTMLLinkElement;
        return createEntity("LINK", "MUTABLE", {
          url: htmlLinkNode.href
        });
      }
      return;
    }
  })(translatedText);

  return convertedText;
};

export const getFormTypeIcon = (formType: FormType) => {
  switch (formType) {
    case FormType.Standard:
      return (
        <Tooltip title={i18next.t("glossary:form")}>
          <ListAlt />
        </Tooltip>
      );
    case FormType.GAS:
      return (
        <Tooltip title={i18next.t("glossary:form", { context: "gas" })}>
          <Accessibility />
        </Tooltip>
      );
    case FormType.Intake:
      return (
        <Tooltip title={i18next.t("glossary:form", { context: "intake" })}>
          <PersonAdd />
        </Tooltip>
      );
  }
};
