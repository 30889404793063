import React, { lazy, Suspense, useEffect } from "react";
import { Container, Theme } from "@mui/material";
import { makeStyles, StudyAbilityProvider } from "@providers";
import { Route, Routes, Navigate } from "react-router-dom";
import routes from "@routes/routes";
import AppFooter from "@client/components/AppFooter";
import { StudyProvider } from "@providers";
import StudyRoute from "@routes/StudyRoute";
import ParticipantRoute from "@routes/ParticipantRoute";
import PublicRoute from "@routes/PublicRoute";
const LandingContainer = lazy(() => import("@client/views/LandingContainer"));
const SignIn = lazy(() => import("@client/views/SignIn/SignIn"));
const SSOLogin = lazy(() => import("@client/views/SignIn/SSOLogin"));
const SignUp = lazy(() => import("@client/views/SignUp"));
const MFAEnrollContainer = lazy(
  () => import("@client/views/MFAEnrollContainer")
);
const ForgotPw = lazy(() => import("@client/views/ForgotPw"));
const UnAuthorizedContainer = lazy(
  () => import("@client/views/UnAuthorizedContainer")
);
const NotFoundContainer = lazy(() => import("@client/views/NotFoundContainer"));
const TermsOfServiceContainer = lazy(
  () => import("@client/views/TermsOfServiceContainer")
);
const PrivacyPolicyContainer = lazy(
  () => import("@client/views/PrivacyPoilcyContainer")
);
const AccountActionContainer = lazy(
  () => import("@client/views/AccountActionContainer")
);
const StudyApp = lazy(() => import("@client/AREs/study"));
const AdminApp = lazy(() => import("@client/AREs/admin"));
const PatientApp = lazy(() => import("@client/AREs/patients"));
const ClinicApp = lazy(() => import("@client/AREs/clinic"));
const AccountApp = lazy(() => import("@client/AREs/account"));

//custom route imports
import SubdomainRoute from "@client/routes/SubdomainRoute";
import ValidateRoute from "@client/routes/ValidateRoute";

import { Analytics } from "@aws-amplify/analytics";
import { Auth } from "@aws-amplify/auth";
import { useWorkspace } from "@providers";
import { AnalyticsEndpointAttribute } from "@interface/types";
import { InteractiveTrainingProvider } from "@client/providers/InteractiveTrainingProvider";
import selectors from "@interface/constants/selectors.json";

const useStyles = makeStyles()((theme: Theme) => ({
  appWrapper: {
    padding: 0,
    display: "flex",
    background: theme.palette.background.default,
    minHeight: `calc(100vh - ${theme.spacing(6)})` //100% view height minus footer + margins height
  },
  symptomIcon: {
    color: theme.custom.symptomIconColorList,
    marginLeft: "0.5rem",
    height: "40px",
    width: "auto"
  }
}));

const App = () => {
  const { getWorkspacesList, currentWorkspace } = useWorkspace();

  if (getWorkspacesList().length === 0) {
    throw Error("No Valid Workspaces Exist");
  }

  const { classes } = useStyles();

  useEffect(() => {
    //Configuring Amplify Analytics
    //Disabling if in local env to prevent error message
    if (process.env.NODE_ENV !== "development") {
      const amplifyConfig = {
        Auth: {
          identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
          region: "ca-central-1"
        }
      };
      //Initialize Amplify
      Auth.configure(amplifyConfig);

      const analyticsConfig = {
        AWSPinpoint: {
          // Amazon Pinpoint App Client ID
          appId: process.env.PINPOINT_APP_ID,
          // Amazon service region
          region: "ca-central-1",
          mandatorySignIn: false
        }
      };
      Analytics.configure(analyticsConfig);

      //Attribute values must be an array, even for single values
      //eslint-disable-next-line @typescript-eslint/no-explicit-any -- Attributes are allowed to be any type
      const attributes: { [key: string]: any } = {
        [AnalyticsEndpointAttribute.Resolution]: [
          `${window.screen.width}x${window.screen.height}`
        ],
        [AnalyticsEndpointAttribute.Locale]: [window.navigator.language]
      };

      if (currentWorkspace) {
        attributes[AnalyticsEndpointAttribute.Workspace] = [
          currentWorkspace.Subdomain
        ];
      }

      //Update the endpoint now
      Analytics.updateEndpoint({ attributes });
    } else {
      Analytics.disable();
    }
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        data-testid={selectors.views.App.appWrapper}
        className={classes.appWrapper}
      >
        <Suspense fallback={<div></div>}>
          <StudyProvider>
            <StudyAbilityProvider>
              <InteractiveTrainingProvider>
                <Routes>
                  <Route
                    path={routes.termsOfService}
                    element={<TermsOfServiceContainer />}
                  />
                  <Route
                    path={routes.privacyPolicy}
                    element={<PrivacyPolicyContainer />}
                  />
                  <Route
                    path={routes.accountAction}
                    element={<AccountActionContainer />}
                  />
                  {/* Public Routes */}
                  <Route path={routes.root} element={<PublicRoute />}>
                    <Route path={routes.root} element={<LandingContainer />} />
                    <Route path={routes.root} element={<ValidateRoute />}>
                      {[
                        `${routes.signin}${routes.invite}`,
                        `${routes.signin}`
                      ].map((path, index) => (
                        <Route key={index} path={path} element={<SignIn />} />
                      ))}
                      <Route element={<SubdomainRoute />}>
                        {[
                          `${routes.signup}${routes.invite}`,
                          `${routes.signup}`
                        ].map((path, index) => (
                          <Route key={index} path={path} element={<SignUp />} />
                        ))}
                        <Route
                          path={`${routes.signup}/lumind`}
                          element={
                            <Navigate
                              to={`${routes.signup}/ae0de37c-35a3-4430-a84a-c80dc9e56bec`}
                            />
                          }
                        />
                        <Route
                          path={`${routes.signup}/mysouvenaid`}
                          element={
                            <Navigate
                              to={`${routes.signup}/07329c6d-0ee7-44e1-a9d5-767ba37369a0`}
                            />
                          }
                        />
                        <Route path={routes.forgotpw} element={<ForgotPw />} />
                      </Route>
                      <Route
                        path={routes.unauthorized}
                        element={<UnAuthorizedContainer />}
                      />
                      <Route path={routes.ssoRedirect} element={<SSOLogin />} />
                    </Route>
                  </Route>
                  <Route path={`${routes.root}`} element={<StudyRoute />}>
                    {/* Admin Routes */}
                    {[routes.editStudy, routes.newStudy, routes.admin].map(
                      (path, index) => (
                        <Route
                          key={index}
                          path={`${path}/*`}
                          element={<ValidateRoute />}
                        >
                          <Route path={`*`} element={<AdminApp />} />
                        </Route>
                      )
                    )}
                    {/* Account Routes */}
                    <Route
                      path={`${routes.account}/*`}
                      element={<AccountApp />}
                    />
                    {/* Study Routes */}
                    {[routes.studies, routes.manual].map((path, index) => (
                      <Route
                        key={index}
                        path={`${path}/*`}
                        element={<StudyApp />}
                      />
                    ))}
                    {/* Clinic Routes */}
                    <Route
                      path={`${routes.clinics}/*`}
                      element={<ValidateRoute />}
                    >
                      <Route path={`*`} element={<ClinicApp />} />
                    </Route>
                    {/* MRA Enroll Route */}
                    <Route
                      path={routes.mfaEnroll}
                      element={<MFAEnrollContainer />}
                    />
                  </Route>
                  {/* Participant Routes */}
                  <Route path={`${routes.root}`} element={<ParticipantRoute />}>
                    <Route
                      path={`${routes.participant}/*`}
                      element={<PatientApp />}
                    />
                  </Route>
                  <Route path="*" element={<NotFoundContainer />} />
                </Routes>
              </InteractiveTrainingProvider>
            </StudyAbilityProvider>
          </StudyProvider>
        </Suspense>
      </Container>
      <AppFooter />
    </>
  );
};

export default App;
