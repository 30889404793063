import React from "react";
import { Theme } from "@mui/material";
import { makeStyles } from "@providers/ThemeProvider";
import { sanitizeHTML } from "@helpers/displayHelpers";
import { getRHFEditorDisplayStyles } from "@helpers/themeHelpers";
import selectors from "@interface/constants/selectors.json";

type Props = {
  description: string;
};

const useStyles = makeStyles()((theme: Theme) => ({
  rhfContent: getRHFEditorDisplayStyles(theme)
}));

const HTMLDescription = ({ description }: Props): JSX.Element => {
  const { classes } = useStyles();
  const sanitizedHTML: string = sanitizeHTML(description);
  return (
    <div
      className={classes.rhfContent}
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      data-testid={selectors.components.HTMLDescription.root}
    />
  );
};

export default HTMLDescription;
