export class AuthError extends Error {
    constructor(message) {
        super(message);
        this.name = "AuthError";
    }
}
export class InviteError extends Error {
    constructor(message) {
        super(message);
        this.name = "InviteError";
    }
}
export class CognitoError extends Error {
    constructor(message) {
        super(message);
        this.name = "CognitoError";
    }
}
export class ConnectionError extends Error {
    constructor(message) {
        super(message);
        this.name = "ConnectionError";
    }
}
export class ValidationError extends Error {
    constructor(message) {
        super(message.join(", "));
        this.name = "ValidationError";
    }
}
export class NotFoundError extends Error {
    constructor(message) {
        super(message);
        this.name = "NotFoundError";
    }
}
