import React from "react";
import {
  Subject,
  StudyEventData,
  SubjectData,
  StudyEventDef,
  FormData,
  ItemGroupDef,
  MetaDataVersion,
  YesNo,
  StudyEventType,
  SignatureMeaning
} from "@interface/types";
import i18next from "i18next";
import { Tooltip, IconButton } from "@mui/material";
import {
  CalendarToday,
  Update,
  CheckCircle,
  AssignmentTurnedIn,
  LockOutlined,
  Verified
} from "@mui/icons-material";
import {
  getSortedFormDefs,
  getSortedItemGroupDefs
} from "@interface/helpers/sortHelpers";

const scheduledColour = "#d6e1e0";
const inProgressColour = "#ff8100";
const completedColour = "#17db94";
const signedColour = "#02a2b0";
const lockedColour = "#fd5f40";
const sdvColour = "#7E8CC5"; //"#d6e1e0";

export const isSubjectLocked = (subject: Subject): boolean => {
  if (subject.Locked) {
    return true;
  }

  for (const event of subject.StudyEventsData) {
    if (event.Locked) {
      return true;
    }

    for (const form of event.FormData) {
      if (form.Locked) {
        return true;
      }
    }
  }

  return false;
};

export const getEventData = (
  studyEventsData: StudyEventData[],
  idStudyEvent: number,
  repeatKey?: string
): StudyEventData | undefined => {
  return studyEventsData.find((studyEventDatum: StudyEventData) => {
    if (repeatKey) {
      return (
        studyEventDatum.idStudyEvent === idStudyEvent &&
        studyEventDatum.RepeatKey === repeatKey
      );
    }
    return studyEventDatum.idStudyEvent === idStudyEvent;
  });
};

const isFormComplete = (
  requiredItemGroups: ItemGroupDef[],
  formData?: FormData
): boolean => {
  //A form is complete if the data exists and there is data for each required IG

  if (!formData) {
    return false;
  }

  if (requiredItemGroups.length === 0) {
    //No required groups, so we are considered done
    return true;
  }

  for (const ig of requiredItemGroups) {
    const igData = formData.ItemGroupData.find(
      (val) => val.idItemGroup === ig.idItemGroup
    );

    //We dont have data for this required group, so we are not complete
    if (!igData) {
      return false;
    }
  }

  return true;
};

const isSubjectComplete = (
  subjectData: SubjectData,
  metaDataVersion: MetaDataVersion
): boolean => {
  const scheduledEvents = metaDataVersion.StudyEventDefs.filter(
    (e) => e.Type === StudyEventType.Scheduled
  );

  for (const event of scheduledEvents) {
    const eventData = subjectData.StudyEventsData.find(
      (d) => d.idStudyEvent === event.idStudyEvent
    );

    if (!eventData || !isEventComplete(eventData, event, metaDataVersion)) {
      return false;
    }
  }

  return true;
};

export const isEventComplete = (
  eventData: StudyEventData,
  event: StudyEventDef,
  metaDataVersion: MetaDataVersion
): boolean => {
  //If each form for this event is considered complete, then this event is considered complete
  const formsForEvent = getSortedFormDefs(metaDataVersion, event);

  for (const form of formsForEvent) {
    const formData = eventData.FormData.find(
      (val) => val.idForm === form.idForm
    );
    const itemGroups = getSortedItemGroupDefs(metaDataVersion, form);
    const requiredItemGroups = [];

    for (const ig of itemGroups) {
      const ref =
        form.ItemGroupRefs &&
        form.ItemGroupRefs.find((val) => val.idItemGroup === ig.idItemGroup);

      if (ref && ref.Mandatory === YesNo.Yes) {
        requiredItemGroups.push(ig);
      }
    }

    if (!isFormComplete(requiredItemGroups, formData)) {
      return false;
    }
  }

  return true;
};

//Thses method are for getting the status icon outside of a data explorer
export const getEventStatusIcon = (
  s: Subject | SubjectData,
  e: StudyEventDef,
  mdv: MetaDataVersion,
  forSidebar?: boolean,
  repeatKey?: string,
  iconClass?: string
): JSX.Element | undefined => {
  // Scheduled - subject has no study event data, or the event is not contained in the list.
  if (
    !forSidebar &&
    (!s.StudyEventsData ||
      s.StudyEventsData.length === 0 ||
      !s.StudyEventsData.find((se) => se.idStudyEvent === e.idStudyEvent))
  ) {
    return (
      <Tooltip title={i18next.t("subjectMatrix.status.scheduled")}>
        <CalendarToday
          style={{ color: scheduledColour }}
          className={iconClass}
          fontSize="small"
        />
      </Tooltip>
    );
  }

  const eventData = repeatKey
    ? s.StudyEventsData.find(
        (se) => se.idStudyEvent === e.idStudyEvent && se.RepeatKey === repeatKey
      )
    : s.StudyEventsData.find((se) => se.idStudyEvent === e.idStudyEvent);

  if (!eventData) {
    return undefined;
  }

  if (eventData) {
    if (isEventComplete(eventData, e, mdv)) {
      return (
        <Tooltip title={i18next.t("subjectMatrix.status.completed")}>
          <CheckCircle
            style={{ color: completedColour }}
            className={iconClass}
            fontSize="small"
          />
        </Tooltip>
      );
    } else if (!forSidebar) {
      // event exists so it must be in progress
      return (
        <Tooltip title={i18next.t("subjectMatrix.status.inProgress")}>
          <Update
            style={{ color: inProgressColour }}
            className={iconClass}
            fontSize="small"
          />
        </Tooltip>
      );
    }
  }
  return undefined;
};

export const getEventStatusIconButton = (
  s: Subject | SubjectData,
  e: StudyEventDef,
  mdv: MetaDataVersion,
  navigate?: (
    idMetaDataVersion: number,
    subjectKey: string,
    idStudyEvent: number
  ) => void
): JSX.Element | undefined => {
  // Scheduled - subject has no study event data, or the event is not contained in the list.
  if (
    !s.StudyEventsData ||
    s.StudyEventsData.length === 0 ||
    !s.StudyEventsData.find((se) => se.idStudyEvent === e.idStudyEvent)
  ) {
    return (
      <Tooltip title={i18next.t("subjectMatrix.status.scheduled")}>
        <IconButton
          onClick={() =>
            navigate
              ? navigate(s.idMetaDataVersion, s.SubjectKey, e.idStudyEvent)
              : null
          }
          size="small"
        >
          <CalendarToday style={{ color: scheduledColour }} fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  }
  const eventData = s.StudyEventsData.find(
    (se) => se.idStudyEvent === e.idStudyEvent
  );

  if (!eventData) {
    return;
  }

  if (eventData) {
    if (isEventComplete(eventData, e, mdv)) {
      return (
        <Tooltip title={i18next.t("subjectMatrix.status.completed")}>
          <IconButton
            onClick={() =>
              navigate
                ? navigate(s.idMetaDataVersion, s.SubjectKey, e.idStudyEvent)
                : null
            }
            size="small"
          >
            <CheckCircle style={{ color: completedColour }} fontSize="small" />
          </IconButton>
        </Tooltip>
      );
    } else {
      // event exists so it must be in progress
      return (
        <Tooltip title={i18next.t("subjectMatrix.status.inProgress")}>
          <IconButton
            onClick={() =>
              navigate
                ? navigate(s.idMetaDataVersion, s.SubjectKey, e.idStudyEvent)
                : null
            }
            size="small"
          >
            <Update style={{ color: inProgressColour }} fontSize="small" />
          </IconButton>
        </Tooltip>
      );
    }
  }
  return undefined;
};

export const getFormStatusIcon = (
  formData: FormData | undefined,
  requiredItemGroups: ItemGroupDef[],
  iconClass?: string
): JSX.Element => {
  // Scheduled - subject has no form data, or the event is not contained in the list.
  if (!formData) {
    return (
      <Tooltip title={i18next.t("subjectMatrix.status.scheduled")}>
        <CalendarToday
          style={{ color: scheduledColour }}
          className={iconClass}
          fontSize="small"
        />
      </Tooltip>
    );
  }

  if (isFormComplete(requiredItemGroups, formData)) {
    return (
      <Tooltip title={i18next.t("subjectMatrix.status.completed")}>
        <CheckCircle
          style={{ color: completedColour }}
          className={iconClass}
          fontSize="small"
        />
      </Tooltip>
    );
  } else {
    // event exists so it must be in progress
    return (
      <Tooltip title={i18next.t("subjectMatrix.status.inProgress")}>
        <Update
          style={{ color: inProgressColour }}
          className={iconClass}
          fontSize="small"
        />
      </Tooltip>
    );
  }
};

//These methods are for getting the badge icons outside of a data explorer
const getDataBadges = (
  hasNonSDVSignatures: boolean,
  isLocked: boolean,
  sourceDataVerified: boolean,
  iconClass?: string
) => {
  return (
    <>
      {hasNonSDVSignatures && (
        <Tooltip title={i18next.t("subjectMatrix.status.signed")}>
          <AssignmentTurnedIn
            style={{ color: signedColour }}
            className={iconClass}
            fontSize="small"
          />
        </Tooltip>
      )}
      {isLocked && (
        <Tooltip title={i18next.t("dataCollection.locked")}>
          <LockOutlined
            style={{ color: lockedColour, marginBottom: "2px" }}
            className={iconClass}
            fontSize="small"
          />
        </Tooltip>
      )}
      {sourceDataVerified && (
        <Tooltip title={i18next.t("dataCollection.sourceDataVerified")}>
          <Verified
            style={{ color: sdvColour }}
            className={iconClass}
            fontSize="small"
          />
        </Tooltip>
      )}
    </>
  );
};

export const getSubjectBadges = (
  subjectData: SubjectData,
  iconClass?: string
) => {
  return getDataBadges(
    subjectData.Signatures.length > 0,
    subjectData.Locked,
    false,
    iconClass
  );
};

export const getEventBadges = (
  parentLocked: boolean,
  eventData?: StudyEventData,
  iconClass?: string
) => {
  return getDataBadges(
    (eventData?.Signatures.length ?? 0) > 0,
    (eventData?.Locked ?? false) || parentLocked,
    false,
    iconClass
  );
};

export const getFormBadges = (
  parentLocked: boolean,
  formDatum?: FormData,
  iconClass?: string
) => {
  const hasNonSDVSignatures =
    formDatum?.Signatures.some(
      (s) => s.SignatureMeaning !== SignatureMeaning.SourceDataVerification
    ) ?? false;

  return getDataBadges(
    hasNonSDVSignatures,
    (formDatum?.Locked ?? false) || parentLocked,
    formDatum?.SourceDataVerified ?? false,
    iconClass
  );
};

//These methods are for the data explorers. We combine the status and badges into a single column for space reasons.
export const getAllDataStatusValues = (): string[] => {
  return [
    i18next.t("subjectMatrix.status.scheduled"),
    i18next.t("subjectMatrix.status.completed"),
    i18next.t("subjectMatrix.status.inProgress")
  ];
};

export const getAllDataBadgesValues = (includeSDV?: boolean): string[] => {
  const options = [
    i18next.t("subjectMatrix.status.signed"),
    i18next.t("subjectMatrix.status.locked")
  ];

  if (includeSDV) {
    options.push(i18next.t("dataCollection.sourceDataVerified"));
  }

  return options;
};

const getStatusText = (isComplete: boolean): string => {
  if (isComplete) {
    return i18next.t("subjectMatrix.status.completed");
  }

  return i18next.t("subjectMatrix.status.inProgress");
};

export const getSubjectStatusText = (
  subjectData: SubjectData,
  mdv: MetaDataVersion
): string => {
  return getStatusText(isSubjectComplete(subjectData, mdv));
};

export const getEventStatusText = (
  eventData: StudyEventData,
  eventDef: StudyEventDef,
  mdv: MetaDataVersion
): string => {
  return getStatusText(isEventComplete(eventData, eventDef, mdv));
};

export const getFormStatusText = (
  formData: FormData,
  requiredItemGroups: ItemGroupDef[]
): string => {
  return getStatusText(isFormComplete(requiredItemGroups, formData));
};

const getBadgeText = (
  hasNonSDVSignatures: boolean,
  isLocked: boolean,
  sourceDataVerified: boolean
): string[] => {
  const badges: string[] = [];

  if (hasNonSDVSignatures) {
    badges.push(i18next.t("subjectMatrix.status.signed"));
  }

  if (isLocked) {
    badges.push(i18next.t("subjectMatrix.status.locked"));
  }

  if (sourceDataVerified) {
    badges.push(i18next.t("dataCollection.sourceDataVerified"));
  }

  return badges;
};

export const getSubjectBadgeText = (subjectData: SubjectData): string[] => {
  return getBadgeText(
    subjectData.Signatures.length > 0,
    subjectData.Locked,
    false
  );
};

export const getEventBadgeText = (
  eventData: StudyEventData,
  parentLocked: boolean
): string[] => {
  return getBadgeText(
    eventData.Signatures.length > 0,
    eventData.Locked || parentLocked,
    false
  );
};

export const getFormBadgeText = (
  formData: FormData,
  parentLocked: boolean
): string[] => {
  const hasNonSDVSignatures =
    formData.Signatures.some(
      (s) => s.SignatureMeaning !== SignatureMeaning.SourceDataVerification
    ) ?? false;

  return getBadgeText(
    hasNonSDVSignatures,
    formData.Locked || parentLocked,
    formData.SourceDataVerified
  );
};

export const getIconsForStatusText = (statuses: string[]) => {
  return statuses.map((status) => {
    switch (true) {
      case status === i18next.t("subjectMatrix.status.scheduled"):
        return (
          <Tooltip title={i18next.t("subjectMatrix.status.scheduled")}>
            <CalendarToday
              style={{ color: scheduledColour }}
              fontSize="small"
            />
          </Tooltip>
        );
      case status === i18next.t("subjectMatrix.status.completed"):
        return (
          <Tooltip title={i18next.t("subjectMatrix.status.completed")}>
            <CheckCircle style={{ color: completedColour }} fontSize="small" />
          </Tooltip>
        );
      case status === i18next.t("subjectMatrix.status.inProgress"):
        return (
          <Tooltip title={i18next.t("subjectMatrix.status.inProgress")}>
            <Update style={{ color: inProgressColour }} fontSize="small" />
          </Tooltip>
        );
      case status === i18next.t("subjectMatrix.status.signed"):
        return (
          <Tooltip key="s" title={i18next.t("subjectMatrix.status.signed")}>
            <AssignmentTurnedIn
              style={{ color: signedColour }}
              fontSize="small"
            />
          </Tooltip>
        );
      case status === i18next.t("subjectMatrix.status.locked"):
        return (
          <Tooltip key="l" title={i18next.t("dataCollection.locked")}>
            <LockOutlined
              style={{ color: lockedColour, marginBottom: "2px" }}
              fontSize="small"
            />
          </Tooltip>
        );
      case status === i18next.t("dataCollection.sourceDataVerified"):
        return (
          <Tooltip
            key="sdv"
            title={i18next.t("dataCollection.sourceDataVerified")}
          >
            <Verified style={{ color: sdvColour }} fontSize="small" />
          </Tooltip>
        );
      default:
        return null;
    }
  });
};
