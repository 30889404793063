import { Ability, AbilityBuilder } from "@casl/ability";
import { UserRole, WorkspaceStatus, UserStatus, UserScope, UserPermission } from "../types";
export const defineAbilityForWorkspace = async (user, idWorkspace) => {
    const builder = new AbilityBuilder(Ability);
    // Check whether the given user has an active workspace role
    const workspaceRole = user.workspaceRoles.find((workspaceRole) => {
        return (workspaceRole.idWorkspace === idWorkspace &&
            workspaceRole.status === UserStatus.Available);
    });
    if (!workspaceRole) {
        throw new Error(`User does not have access to workspace ${idWorkspace}.`);
    }
    switch (workspaceRole.scope) {
        case UserScope.Local:
            if (typeof localWorkspaceRolePermissions[workspaceRole.userRole.userRole] === "function") {
                // If the user role does not return a function, this block will fail
                localWorkspaceRolePermissions[workspaceRole.userRole.userRole](workspaceRole, builder);
            }
            else {
                throw new Error(`Trying to use unknown role "${workspaceRole.userRole.userRole}"`);
            }
            break;
        case UserScope.Global:
            if (typeof globalWorkspaceRolePermissions[workspaceRole.userRole.userRole] === "function") {
                // If the user role does not return a function, this block will fail
                globalWorkspaceRolePermissions[workspaceRole.userRole.userRole](workspaceRole, builder);
            }
            else {
                throw new Error(`Trying to use unknown role "${workspaceRole.userRole.userRole}"`);
            }
            break;
        default:
            throw new Error(`Trying to use unknown scope "${workspaceRole.scope}"`);
    }
    return builder.build();
};
export const localWorkspaceRolePermissions = {
    [UserRole.Admin](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.Designer](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.Director](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.DataManager](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.Investigator](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.DataEntry](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.ClinicalResearchCoordinator](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.DataMonitor](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.Sponsor](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.StudyParticipant](_, { can }) {
        can("viewParticipantStudies", "Workspace", {
            status: WorkspaceStatus.Available
        });
    }
};
export const globalWorkspaceRolePermissions = {
    [UserRole.Admin](user, { can }) {
        can("viewStudies", "Workspace", {
            status: WorkspaceStatus.Available
        });
        can("viewAllStudies", "Workspace", { status: WorkspaceStatus.Available });
        if (user.permission === UserPermission.ReadWrite) {
            can("createStudy", "Workspace", {
                status: WorkspaceStatus.Available
            });
        }
    },
    [UserRole.Designer](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
        can("viewAllStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.Director](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
        can("viewAllStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.DataManager](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
        can("viewAllStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.Investigator](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
        can("viewAllStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.DataEntry](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
        can("viewAllStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.ClinicalResearchCoordinator](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
        can("viewAllStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.DataMonitor](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
        can("viewAllStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.Sponsor](_, { can }) {
        can("viewStudies", "Workspace", { status: WorkspaceStatus.Available });
        can("viewAllStudies", "Workspace", { status: WorkspaceStatus.Available });
    },
    [UserRole.StudyParticipant](_, { can }) {
        can("viewParticipantStudies", "Workspace", {
            status: WorkspaceStatus.Available
        });
    }
};
