import React, { useEffect, useState } from "react";
import { useAuth } from "@providers";
import { isParticipantUser } from "@helpers/participantHelpers";
import routes from "@routes/routes";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const StudyRoute = (): JSX.Element | null => {
  const { user } = useAuth();
  const location = useLocation();
  const userHasRequiredRole = user && !isParticipantUser(user);
  const [userWait, setUserWait] = useState(true);

  //Add a small delay to allow the user to load.
  //If we still dont have a user, navigate to the login page
  //Otherwise, render the outlet
  useEffect(() => {
    if (user) {
      setUserWait(false);
      return;
    }

    //Delay and then clear
    const checkUser = setInterval(() => {
      setUserWait(false);
    }, 500);
    return () => clearInterval(checkUser);
  }, []);

  //We are waiting for the user, render nothing
  if (userWait) {
    return null;
  }

  return (
    <>
      {userHasRequiredRole ? (
        <Outlet />
      ) : (
        <Navigate
          to={!user ? routes.signin : routes.root}
          state={{
            from: !user ? location : undefined,
            error: !user
              ? "User is not logged in"
              : "User does not have required role"
          }}
        />
      )}
    </>
  );
};

export default StudyRoute;
