import React, { useEffect, useState } from "react";
import { StudyLanguage } from "@interface/types";
import {
  getLastUsedStudyLanguage,
  setLastUsedStudyLanguage
} from "@helpers/localStorageHelpers";
import { orderLanguages } from "@interface/helpers/studyLanguageHelpers";

interface StudyLanguageContextInterface {
  currentLanguage?: StudyLanguage;
  primaryLanguageTag: string;
  studyLanguages: StudyLanguage[];
  changeStudyLanguage: (lang: StudyLanguage) => void;
}

const StudyLanguageContext = React.createContext<StudyLanguageContextInterface>(
  {
    studyLanguages: [],
    primaryLanguageTag: "en-ca",
    changeStudyLanguage: (lang: StudyLanguage) => {
      throw new Error(
        `${lang.language} cannot be changed as Language Provider has not initialized`
      );
    }
  }
);

interface IStudyLanguageProviderProps {
  studyLanguages: StudyLanguage[];
  children: JSX.Element | JSX.Element[];
}

const StudyLanguageProvider = (
  props: IStudyLanguageProviderProps
): JSX.Element => {
  const { studyLanguages, ...other } = props;
  const orderedStudyLanguages = orderLanguages(studyLanguages);
  const [currentLanguage, setCurrentLanguage] = useState<StudyLanguage>();
  const [primaryLanguageTag, setPrimaryLanguageTag] = useState<string>("en-ca");

  useEffect(() => {
    const primaryStudyLanguage = studyLanguages.find(
      (studyLanguage) => studyLanguage.IsDefault
    );

    if (!primaryStudyLanguage) {
      setCurrentLanguage(undefined);
      return;
    }

    setPrimaryLanguageTag(primaryStudyLanguage.languageTag);

    const savedLang = getLastUsedStudyLanguage(primaryStudyLanguage.OID);
    if (savedLang === null) {
      setCurrentLanguage(primaryStudyLanguage);
      setLastUsedStudyLanguage(
        primaryStudyLanguage.OID,
        primaryStudyLanguage.languageTag
      );
    } else {
      const l = studyLanguages.find((studyLanguage: StudyLanguage) => {
        return studyLanguage.languageTag === savedLang;
      });
      if (!l) {
        setCurrentLanguage(primaryStudyLanguage);
        setLastUsedStudyLanguage(
          primaryStudyLanguage.OID,
          primaryStudyLanguage.languageTag
        );
      } else {
        setCurrentLanguage(l);
        setLastUsedStudyLanguage(primaryStudyLanguage.OID, l.languageTag);
      }
    }
  }, [studyLanguages]);

  const changeStudyLanguage = (lang: StudyLanguage) => {
    const studyLanguage: StudyLanguage | undefined = studyLanguages.find(
      (studyLanguage: StudyLanguage) => {
        return studyLanguage.languageTag === lang.languageTag;
      }
    );

    if (studyLanguage) {
      setCurrentLanguage(studyLanguage);
      setLastUsedStudyLanguage(studyLanguage.OID, studyLanguage.languageTag);
    }
    return;
  };

  return (
    <StudyLanguageContext.Provider
      value={{
        studyLanguages: orderedStudyLanguages,
        currentLanguage,
        primaryLanguageTag,
        changeStudyLanguage
      }}
      {...other}
    />
  );
};

const useStudyLang = (): StudyLanguageContextInterface => {
  const context = React.useContext(StudyLanguageContext);
  if (context === undefined) {
    throw new Error(
      "useStudyLang must be used within an StudyLanguageProvider"
    );
  }

  return context;
};

export { StudyLanguageProvider, useStudyLang };
