import React, { useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem
} from "@mui/material";
import selectors from "@interface/constants/selectors.json";

export interface ConfirmOptions {
  title: string;
  body: string;
  bodyList?: string[];
  postBodyListText?: string;
  confirmationText?: string;
  cancellationText?: string;
  promptForReason?: boolean;
  reasonCharacterLimit?: number;
  reasonPlaceholder?: string;
  validateValue?: string;
  reasonSelectList?: string[];
  confirmationButtonColour?:
    | "error"
    | "inherit"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning";
  disableCancelButton?: boolean;
}

const ConfirmationServiceContext = React.createContext<
  (options: ConfirmOptions) => Promise<void | string>
>(Promise.reject);

type Props = {
  children: JSX.Element | JSX.Element[];
};

const ConfirmationServiceProvider = ({ children }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [confirmationState, setConfirmationState] = useState<ConfirmOptions>();
  const [reasonText, setReasonText] = useState<string>("");
  const [validationText, setValidationText] = useState<string>("");

  const awaitingPromiseRef = useRef<{
    resolve: (value: string | void | PromiseLike<string | void>) => void;
    reject: () => void;
  }>();

  const clearAllState = () => {
    setConfirmationState(undefined);
    setReasonText("");
    setValidationText("");
  };

  const openConfirmation = useCallback((options: ConfirmOptions) => {
    setConfirmationState(options);
    return new Promise<void | string>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const handleCancel = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    clearAllState();
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      if (confirmationState && confirmationState.promptForReason) {
        awaitingPromiseRef.current.resolve(reasonText);
      } else {
        awaitingPromiseRef.current.resolve();
      }
    }
    clearAllState();
  };

  const isConfirmDisabled = (): boolean => {
    //When prompting for a reason, you need to give one!
    if (
      confirmationState &&
      confirmationState.promptForReason &&
      (!reasonText || reasonText.trim() === "")
    ) {
      return true;
    }

    //When asking for validation, make sure the entered text matches exactly
    if (
      confirmationState &&
      confirmationState.validateValue &&
      confirmationState.validateValue !== validationText
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>
      <Dialog
        fullWidth
        open={Boolean(confirmationState)}
        PaperProps={{
          "data-testid":
            selectors.providers.ConfirmationServiceProvider.confirmModalDialog
        }}
      >
        {confirmationState ? (
          <React.Fragment>
            <DialogTitle data-testid="confirm-title">
              {confirmationState.title}
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText data-testid="confirm-body">
                {confirmationState.body}
              </DialogContentText>
              {confirmationState.bodyList &&
                confirmationState.bodyList.map((i) => (
                  <DialogContentText key={i}>{i}</DialogContentText>
                ))}
              {confirmationState.postBodyListText && (
                <DialogContentText style={{ marginTop: "10px" }}>
                  {confirmationState.postBodyListText}
                </DialogContentText>
              )}
              {confirmationState.promptForReason ? (
                <Grid container>
                  <Grid item xs={12}>
                    {confirmationState.reasonSelectList ? (
                      <Select
                        variant="outlined"
                        value={reasonText}
                        fullWidth
                        placeholder={confirmationState.reasonPlaceholder || ""}
                        onChange={(e) => setReasonText(e.target.value)}
                        SelectDisplayProps={{
                          style: {
                            whiteSpace: "normal"
                          }
                        }}
                      >
                        {confirmationState.reasonSelectList.map((v) => {
                          return (
                            <MenuItem
                              style={{
                                whiteSpace: "normal"
                              }}
                              key={v}
                              value={v}
                            >
                              {v}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      <TextField
                        variant="outlined"
                        placeholder={confirmationState.reasonPlaceholder || ""}
                        value={reasonText}
                        onChange={(e) => setReasonText(e.target.value)}
                        data-testid={
                          selectors.providers.ConfirmationServiceProvider
                            .confirmReason
                        }
                        inputProps={{
                          maxLength:
                            confirmationState.reasonCharacterLimit || 255
                        }}
                        fullWidth
                        multiline
                        minRows={2}
                        maxRows={5}
                      />
                    )}
                  </Grid>
                </Grid>
              ) : null}
              {confirmationState.validateValue ? (
                <Grid container>
                  <Grid item xs={12}>
                    <DialogContentText
                      style={{ fontWeight: "bold", color: "black" }}
                    >
                      {confirmationState.validateValue}
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      data-testid={
                        selectors.providers.ConfirmationServiceProvider
                          .confirmValidateInput
                      }
                      variant="outlined"
                      value={validationText}
                      onChange={(e) => setValidationText(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ) : null}
            </DialogContent>
            <DialogActions>
              {!confirmationState.disableCancelButton && (
                <Button
                  data-testid={
                    selectors.providers.ConfirmationServiceProvider
                      .confirmCancel
                  }
                  onClick={handleCancel}
                  variant="outlined"
                >
                  {confirmationState.cancellationText || t("confirm.cancel")}
                </Button>
              )}

              <Button
                data-testid={
                  selectors.providers.ConfirmationServiceProvider.confirmOK
                }
                onClick={handleConfirm}
                color={confirmationState.confirmationButtonColour || "primary"}
                variant="outlined"
                disabled={isConfirmDisabled()}
              >
                {confirmationState.confirmationText || t("confirm.ok")}
              </Button>
            </DialogActions>
          </React.Fragment>
        ) : (
          <div></div>
        )}
      </Dialog>
    </>
  );
};

const useConfirmation = (): ((
  options: ConfirmOptions
) => Promise<void | string>) => React.useContext(ConfirmationServiceContext);

export { ConfirmationServiceProvider, useConfirmation };
