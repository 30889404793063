export var AnalyticsAttribute;
(function (AnalyticsAttribute) {
    AnalyticsAttribute["AppType"] = "AppType";
    AnalyticsAttribute["Host"] = "ApplicationHostName";
    AnalyticsAttribute["LastEventType"] = "LastEventType";
    AnalyticsAttribute["NumOfGoals"] = "NumOfGoals";
    AnalyticsAttribute["StudyName"] = "StudyName";
    AnalyticsAttribute["Menu"] = "Menu";
    AnalyticsAttribute["DataLevel"] = "DataLevel";
    AnalyticsAttribute["FormType"] = "FormType";
    AnalyticsAttribute["SSO"] = "SSOUser";
})(AnalyticsAttribute || (AnalyticsAttribute = {}));
export var AnalyticsEvent;
(function (AnalyticsEvent) {
    AnalyticsEvent["AccessStudy"] = "AccessStudy";
    AnalyticsEvent["AuthFail"] = "_userauth.auth_fail";
    AnalyticsEvent["GoalsAdded"] = "GoalsAdded";
    AnalyticsEvent["LoginEvent"] = "_userauth.sign_in";
    AnalyticsEvent["SearchSubject"] = "SearchSubject";
    AnalyticsEvent["SignupEvent"] = "_userauth.sign_up";
    AnalyticsEvent["StartScheduledVisit"] = "StartScheduledVisit";
    AnalyticsEvent["SubjectAdded"] = "SubjectAdded";
    AnalyticsEvent["HelpPageView"] = "HelpPageView";
    AnalyticsEvent["OpenDataExplorer"] = "OpenDataExplorer";
    AnalyticsEvent["OpenQueriesView"] = "OpenQueriesView";
    AnalyticsEvent["PrintForm"] = "PrintForm";
    AnalyticsEvent["ViewSubject"] = "ViewSubect";
    AnalyticsEvent["ViewAllSubjects"] = "ViewAllSubjects";
    AnalyticsEvent["ViewActiveQuery"] = "ViewActiveQuery";
    AnalyticsEvent["ViewScheduledEvent"] = "ViewScheduledEvent";
    AnalyticsEvent["ViewReports"] = "ViewReports";
    AnalyticsEvent["ApplySignature"] = "ApplySignature";
    AnalyticsEvent["ViewSignatures"] = "ViewSignatures";
    AnalyticsEvent["SaveForm"] = "SaveForm";
})(AnalyticsEvent || (AnalyticsEvent = {}));
export var AnalyticsEndpointAttribute;
(function (AnalyticsEndpointAttribute) {
    AnalyticsEndpointAttribute["Workspace"] = "workspace";
    AnalyticsEndpointAttribute["Resolution"] = "resolution";
    AnalyticsEndpointAttribute["Locale"] = "locale";
})(AnalyticsEndpointAttribute || (AnalyticsEndpointAttribute = {}));
export var AnalyticsMetric;
(function (AnalyticsMetric) {
    AnalyticsMetric["LoginAttempts"] = "LoginAttempts";
    AnalyticsMetric["OpenQueryCount"] = "OpenQueryCount";
    AnalyticsMetric["SignupAttempts"] = "SignupAttempts";
    AnalyticsMetric["TimeSinceLastEvent"] = "TimeSinceLastEvent";
    AnalyticsMetric["TimeToGoal"] = "TimeToGoal";
    AnalyticsMetric["TotalTimeTaken"] = "TotalTimeTaken";
})(AnalyticsMetric || (AnalyticsMetric = {}));
