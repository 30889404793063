import { SoftHard } from "../types";
import { GAS_SYMPTOM_SECTION_KEY } from "./gasHelpers";
import { getSortedItemDefs } from "./sortHelpers";
import { getDescriptionValue } from "./studyLanguageHelpers";
export const ITEMGROUP_IDENTIFIER_PREFIX = "IG";
export const ITEM_IDENTIFIER_PREFIX = "I";
export const buildRegex = (regexString) => {
    const a = regexString.split("/");
    const modifiers = a.pop();
    a.shift();
    const pattern = a.join("/");
    return new RegExp(pattern, modifiers);
};
export const buildFormInitialValuesRHF = ({ metaDataVersion, itemGroupDefs, formDatum, baselineFormDatum, isGasForm }) => {
    const selectedItemGroupDefs = (() => {
        if (!isGasForm) {
            return itemGroupDefs;
        }
        // Reconcile itemGroups from baseline and current followup
        if (!baselineFormDatum) {
            return itemGroupDefs.filter((igd) => igd.Comment !== GAS_SYMPTOM_SECTION_KEY);
        }
        return itemGroupDefs.filter((itemGroupDef) => {
            return (itemGroupDef.Comment !== GAS_SYMPTOM_SECTION_KEY ||
                Boolean(baselineFormDatum.ItemGroupData.find((itemGroupDatum) => {
                    return itemGroupDatum.idItemGroup === itemGroupDef.idItemGroup;
                })));
        });
    })();
    return selectedItemGroupDefs.reduce((formSectionValues, itemGroupDef) => {
        /* Some itemGroups may be of repeating type,
        return all instances of itemGroupDatum for given idItemGroup*/
        const itemGroupData = (() => {
            if (formDatum) {
                return formDatum.ItemGroupData.filter((itemGroupDatum) => {
                    return itemGroupDatum.idItemGroup === itemGroupDef.idItemGroup;
                });
            }
            /* If we have gas baseline data. Return synthetic
            ItemGroupData to maintain consistent repeat keys for all
            instances of baselineItemGroupData that are repeating */
            if (baselineFormDatum) {
                return baselineFormDatum.ItemGroupData.reduce((partialItemGroupData, itemGroupDatum) => {
                    if (itemGroupDatum.idItemGroup !== itemGroupDef.idItemGroup) {
                        return partialItemGroupData;
                    }
                    partialItemGroupData.push({
                        idItemGroup: itemGroupDatum.idItemGroup,
                        Annotations: [],
                        ItemData: [],
                        RepeatKey: itemGroupDatum.RepeatKey
                    });
                    return partialItemGroupData;
                }, []);
            }
            return [];
        })();
        const itemDefs = getSortedItemDefs(metaDataVersion, itemGroupDef);
        // If the form is not baseline and no data exists, push empty formsectionvalues
        if (itemGroupData.length === 0 &&
            (formDatum !== baselineFormDatum || (!formDatum && !baselineFormDatum))) {
            formSectionValues.push({
                idItemGroup: itemGroupDef.idItemGroup,
                QuestionValues: [],
                RepeatKey: undefined
            });
            return formSectionValues;
        }
        itemGroupData.forEach((itemGroupDatum) => {
            const qValues = itemGroupDatum.ItemData.reduce((formQuestionValues, itemDatum) => {
                const itemDef = itemDefs.find((itemDef) => {
                    return itemDef.idItem === itemDatum.idItem;
                });
                if (!itemDef) {
                    return formQuestionValues;
                }
                formQuestionValues.push({
                    idItem: itemDatum.idItem,
                    value: itemDatum.Value,
                    mandatory: false
                });
                return formQuestionValues;
            }, []);
            formSectionValues.push({
                idItemGroup: itemGroupDef.idItemGroup,
                QuestionValues: qValues,
                RepeatKey: itemGroupDatum.RepeatKey || undefined
            });
        });
        return formSectionValues;
    }, []);
};
export const convertFormSectionValuesToForm = (formSectionValues) => {
    return formSectionValues.reduce((result, formSectionValue) => {
        const questionValueTuple = formSectionValue.QuestionValues.map((questionValue) => {
            return [
                `${ITEM_IDENTIFIER_PREFIX}${questionValue.idItem}`,
                questionValue.value
            ];
        });
        const itemDefaultValues = Object.fromEntries(questionValueTuple);
        itemDefaultValues.RepeatKey = formSectionValue.RepeatKey;
        const itemGroupIdentifier = `${ITEMGROUP_IDENTIFIER_PREFIX}${formSectionValue.idItemGroup}`;
        // Check if a repeat of this itemGroup exists. If not initialize a new array
        if (!result[itemGroupIdentifier]) {
            result[itemGroupIdentifier] = [];
        }
        result[itemGroupIdentifier].push(itemDefaultValues);
        return result;
    }, {});
};
export const convertRHFValuesToFormSectionValues = (rhfData, pushEmptyItemGroups = false) => {
    const objectArray = Object.entries(rhfData);
    return objectArray.reduce((newFormSectionValues, [itemGroupIdentifier, itemGroupValueArray]) => {
        if (!Array.isArray(itemGroupValueArray)) {
            throw new Error("Invalid form structure");
        }
        const idItemGroup = parseInt(itemGroupIdentifier.replace(ITEMGROUP_IDENTIFIER_PREFIX, ""));
        itemGroupValueArray.forEach((itemValueObject) => {
            const { RepeatKey: itemGroupRepeatKey } = itemValueObject;
            delete itemValueObject.RepeatKey;
            const itemObjectArray = Object.entries(itemValueObject);
            const mappedQuestionValues = convertRHFValuesToFormQuestionValues(itemObjectArray);
            if (mappedQuestionValues.length === 0 && !pushEmptyItemGroups) {
                // continue
                return;
            }
            // Calling Object.entries reverses the order. Hence we have to add to array in a reverse order to conteract.
            newFormSectionValues.push({
                idItemGroup,
                RepeatKey: typeof itemGroupRepeatKey === "string" &&
                    itemGroupRepeatKey !== ""
                    ? itemGroupRepeatKey
                    : undefined,
                QuestionValues: mappedQuestionValues
            });
        });
        return newFormSectionValues;
    }, []);
};
export const convertRHFValuesToFormQuestionValues = (questionValues) => {
    return questionValues.reduce((formQuestionValues, [idItemString, itemValue]) => {
        const idItem = parseInt(idItemString.replace(ITEM_IDENTIFIER_PREFIX, ""));
        //Skip empty items, but allow the number 0
        if (itemValue === undefined || itemValue === "" || itemValue === false) {
            // continue
            return formQuestionValues;
        }
        if (typeof itemValue === "string" || typeof itemValue === "number") {
            formQuestionValues.push({
                idItem: idItem,
                value: itemValue + "",
                mandatory: false
            });
        }
        else if (typeof itemValue === "boolean") {
            formQuestionValues.push({
                idItem: idItem,
                value: "1", //We only store true booleans.
                mandatory: false
            });
        }
        return formQuestionValues;
    }, []);
};
export const getItemsForItemGroup = (itemDefs, itemGroupDef) => {
    const items = itemDefs.filter((itemDef) => {
        if (!itemGroupDef.ItemRefs) {
            throw new Error("ItemRefs missing in ItemGroupDef");
        }
        const itemRef = itemGroupDef.ItemRefs.find((itemRef) => {
            return itemRef.idItem === itemDef.idItem;
        });
        return itemRef !== undefined;
    });
    return items;
};
//eslint-disable-next-line @typescript-eslint/no-explicit-any -- RHF Error type is strange
export const getRHFErrorByName = (name, errors) => {
    const keys = name.split(".");
    let error = { ...errors };
    keys.forEach((key) => {
        switch (true) {
            case typeof error === "object": {
                error = error[`${key}`];
                break;
            }
            case Array.isArray(error): {
                error = error[parseInt(key)];
                break;
            }
        }
    });
    return error;
};
export const buildRangeCheckWrappers = (itemDef, primaryLanguageTag, currentLanguage) => {
    const softRangeCheckFunctions = [];
    const hardRangeCheckFunctions = [];
    itemDef.RangeChecks.forEach((rc) => {
        //Loop the validation expressions in this range check.
        //There is likely only one, but the structure supports many.
        rc.ValidationExpressions.forEach((ve) => {
            const exp = {
                expression: new Function("value", `return ${ve.Expression}`),
                message: getDescriptionValue(rc.ErrorMessages, primaryLanguageTag, currentLanguage),
                allMessages: rc.ErrorMessages
            };
            if (rc.SoftHard === SoftHard.Soft) {
                softRangeCheckFunctions.push(exp);
            }
            else {
                hardRangeCheckFunctions.push(exp);
            }
        });
    });
    return {
        softRangeCheckFunctions,
        hardRangeCheckFunctions
    };
};
//Given a value and set of range checks, evaluate them and return and errors/warnings generated
//Treats multiple expressions as an AND operation: Any that fail will error the whole question
export const evaluateRangeChecks = (value, expressions) => {
    if (expressions.length === 0 || value === undefined) {
        return true;
    }
    const messages = [];
    for (const check of expressions) {
        if (!check.expression(value)) {
            messages.push(check.message);
        }
    }
    if (messages.length > 0) {
        return messages.join("; ");
    }
    return true;
};
