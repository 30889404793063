import React, { ErrorInfo, ReactNode } from "react";
import { ReactComponent as GNLogo } from "@client/images/logo-full.svg";
import { ReactComponent as ErrorImage } from "@client/images/something-went-wrong.svg";
import "@client/fonts/fonts.css";

interface Props {
  children?: ReactNode;
}

interface State {
  error: Error | undefined;
  errorInfo: ErrorInfo | undefined;
  collapsed: boolean;
  paperWidth: string;
  refreshBtnHoverColor: string;
  copyBtnHoverColor: string;
  helpLinkTextDecoration: string;
  linkTextDecoration: string;
  expandableBtnTransform: string;
  expandableBtnMarginTop: string;
  accordianHoverColor: string;
}

class ErrorBoundary extends React.Component<Props, State> {
  theme = {
    primary: "#7E8CC5",
    primaryDark: "#181F40"
  };

  classes = {
    paper: {
      margin: "auto",
      minHeigh: 420,
      padding: "16px",
      backgroundColor: "#fff",
      borderRadius: "4px",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    h1: {
      textAlign: "center" as const,
      fontSize: "1.3118rem",
      fontFamily: "Romie Regular, serif",
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: "0em"
    },
    errorDetailsStyle: {
      margin: 0,
      fontFamily: "Rules Regular, sans-serif",
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.8,
      letterSpacing: "0.0075em",
      display: "inline"
    },
    body2: {
      margin: 0,
      fontFamily: "Rules Regular, sans-serif",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.8,
      letterSpacing: "0.01071em"
    },
    button: {
      border: "2px solid",
      color: "white",
      padding: "6px 16px",
      cursor: "pointer",
      borderRadius: "4px",
      fontFamily: "Rules Regular, sans-serif",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.8,
      letterSpacing: "0.02857em",
      textTransform: "uppercase" as const,
      minWidth: "64px"
    },
    centerImage: {
      display: "flex",
      padding: "10px",
      justifyContent: "center"
    },
    arrow: {
      border: "solid black",
      borderWidth: "0 4px 4px 0",
      display: "inline-block",
      float: "right" as const,
      padding: "5px",
      marginRight: "16px",
      transformOrigin: "bottom center"
    }
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      errorInfo: undefined,
      collapsed: false,
      paperWidth: window.matchMedia("(max-width: 960px)").matches
        ? "95%"
        : "30%",
      refreshBtnHoverColor: this.theme.primary,
      copyBtnHoverColor: this.theme.primary,
      helpLinkTextDecoration: "none",
      linkTextDecoration: "none",
      expandableBtnTransform: "rotate(-135deg)",
      expandableBtnMarginTop: "0px",
      accordianHoverColor: "white"
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Update state so the next render will show the fallback UI.
    this.setState({ error: error, errorInfo: errorInfo });
  }

  componentDidMount(): void {
    window.matchMedia("(max-width: 960px)").addEventListener("change", (e) => {
      e.matches
        ? this.setState({ paperWidth: "95%" })
        : this.setState({ paperWidth: "30%" });
    });
  }

  render() {
    const authCardStyle = {
      ...this.classes.paper,
      ...{ width: this.state.paperWidth }
    };
    const refreshButtonStyle = {
      ...this.classes.button,
      ...{ backgroundColor: this.state.refreshBtnHoverColor }
    };
    const copyButtonStyle = {
      ...this.classes.button,
      ...{ backgroundColor: this.state.copyBtnHoverColor }
    };

    const expandableStyle = {
      ...this.classes.arrow,
      ...{
        transform: this.state.expandableBtnTransform,
        marginTop: this.state.expandableBtnMarginTop
      }
    };
    if (this.state.error) {
      return (
        <div>
          <div style={this.classes.centerImage}>
            <GNLogo
              style={{ margin: "16px" }}
              height="60"
              width="200"
              preserveAspectRatio="xMinYMid meet"
            />
          </div>
          <div style={authCardStyle}>
            <div style={this.classes.centerImage}>
              <ErrorImage
                height="200"
                width="200"
                preserveAspectRatio="xMinYMid meet"
              />
            </div>
            <h1 style={this.classes.h1}>Something went wrong.</h1>
            <p style={this.classes.body2}>
              Try{" "}
              <a
                style={{
                  margin: 0,
                  font: "inherit",
                  color: this.theme.primary,
                  textDecoration: this.state.helpLinkTextDecoration
                }}
                onMouseEnter={() =>
                  this.setState({ helpLinkTextDecoration: "underline" })
                }
                onMouseLeave={() =>
                  this.setState({ helpLinkTextDecoration: "none" })
                }
                href="https://support.google.com/accounts/answer/32050?hl=en&sjid=10882856784146049296-NA"
                target="_blank"
                rel="noreferrer"
              >
                clearing your browser cache
              </a>{" "}
              and refresh the page. If the problem persists please contact
              support at{" "}
              <a
                style={{
                  margin: 0,
                  font: "inherit",
                  color: this.theme.primary,
                  textDecoration: this.state.linkTextDecoration
                }}
                onMouseEnter={() =>
                  this.setState({ linkTextDecoration: "underline" })
                }
                onMouseLeave={() =>
                  this.setState({ linkTextDecoration: "none" })
                }
                href={"mailto:support@ardeaoutcomes.com"}
              >
                support@goalnav.ca
              </a>{" "}
              providing the error details below.
            </p>
            <div
              style={{
                display: "flex",
                padding: "10px",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <button
                style={refreshButtonStyle}
                onMouseEnter={() =>
                  this.setState({
                    refreshBtnHoverColor: this.theme.primaryDark
                  })
                }
                onMouseLeave={() =>
                  this.setState({ refreshBtnHoverColor: this.theme.primary })
                }
                onClick={() => window.location.reload()}
              >
                Refresh
              </button>
              <button
                style={copyButtonStyle}
                onMouseEnter={() =>
                  this.setState({ copyBtnHoverColor: this.theme.primaryDark })
                }
                onMouseLeave={() =>
                  this.setState({ copyBtnHoverColor: this.theme.primary })
                }
                onClick={() => {
                  const textContent = `Location: ${
                    window.location.href
                  }\n${this.state.error?.toString()}\nStack Trace: ${this.state
                    .errorInfo?.componentStack}`;

                  navigator.clipboard
                    .writeText(textContent)
                    .then(() => {
                      alert("Successfully copied error details");
                    })
                    .catch(() => {
                      alert("Failed to copy error details");
                    });
                }}
              >
                Copy Error Details
              </button>
            </div>
            <div
              style={{
                cursor: "pointer",
                borderRadius: "4px",
                backgroundColor: this.state.accordianHoverColor,
                padding: "4px"
              }}
              onMouseEnter={() =>
                this.setState({ accordianHoverColor: "whitesmoke" })
              }
              onMouseLeave={() =>
                this.setState({ accordianHoverColor: "white" })
              }
              onClick={() => {
                this.setState((state) => {
                  return {
                    collapsed: !state.collapsed,
                    expandableBtnTransform: state.collapsed
                      ? "rotate(-135deg)"
                      : "rotate(45deg)",
                    expandableBtnMarginTop: state.collapsed ? "0px" : "3px"
                  };
                });
              }}
            >
              <h5 style={this.classes.errorDetailsStyle}>Error Details</h5>
              <i style={expandableStyle}></i>
            </div>

            {!this.state.collapsed && (
              <div id="errorDetails" style={{ alignContent: "center" }}>
                <code
                  style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                >
                  Location: {window.location.href}&#10;&#13;
                  {this.state.error && this.state.error.toString()}
                  &#10;&#13;Stack Trace:
                  {this.state.errorInfo && this.state.errorInfo.componentStack}
                </code>
              </div>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
