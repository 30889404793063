import React from "react";
import {
  SnackbarProvider as MuiSnackBarProvider,
  useSnackbar
} from "notistack";

const SnackBarProvider = ({
  children
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  return <MuiSnackBarProvider maxSnack={2}>{children}</MuiSnackBarProvider>;
};
export { useSnackbar, SnackBarProvider };
