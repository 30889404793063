const routes = {
  root: "/",
  signin: "/signin",
  signup: "/signup",
  manual: "/manual",
  invite: `/:inviteId`,
  forgotpw: "/forgotpw",
  studies: "/study",
  clinics: "/clinic",
  study: `/:studyId`,
  table: "/table",
  edit: "/edit",
  newStudy: `/study/edit`,
  editStudy: `/study/edit/:studyId`,
  reports: `/reports`,
  subject: `/:idMetaDataVersion/:subjectKey`,
  subjectSignatures: "/signatures",
  account: "/account",
  profile: "/profile",
  security: "/security",
  admin: "/admin",
  adminWorkspaces: "/workspaces",
  adminUsers: "/users",
  adminUtilities: "/utilities",
  unauthorized: "/unauthorized",
  termsOfService: "/terms",
  privacyPolicy: "/privacy",
  participant: "/pws",
  participantOverview: "/overview",
  participantSymptoms: "/symptoms",
  participantProfile: "/profile",
  contact: "/contact",
  studyEvent: "/studyevent/:idStudyEvent",
  form: "/form/:idForm",
  itemGroup: "/itemgroup/:idItemGroup",
  intake: "/intake",
  queries: "/queries",
  treamentGoals: "/treatmentgoals",
  encounters: "/encounters",
  accountAction: "/action",
  ssoRedirect: "/sso",
  mfaEnroll: "/mfaenroll",
  dataExplorer: "/dataexplorer",
  deForms: "/forms",
  deEvents: "/events",
  deSubjects: "/subjects",
  deDataReviewTables: "/datareviewtables",
  pastForm: "/pastform/:idFormData"
};

export const participantRoutes = [
  {
    path: `${routes.participant}${routes.study}${routes.participantSymptoms}`
  },
  {
    path: `${routes.participant}${routes.study}${routes.participantOverview}`
  },
  {
    path: `${routes.participant}${routes.study}${routes.participantProfile}`
  },
  {
    path: `${routes.participant}${routes.study}${routes.contact}`
  }
];

export const clinicRoutes = [
  { path: `${routes.clinics}${routes.study}${routes.reports}` },
  { path: `${routes.clinics}${routes.study}${routes.queries}` },
  { path: `${routes.clinics}${routes.study}${routes.contact}` },
  { path: `${routes.clinics}${routes.study}${routes.table}` },
  { path: `${routes.clinics}${routes.study}` }
];

export const clinicSubjectRoutes = [
  {
    path: `${routes.clinics}${routes.study}${routes.subject}${routes.treamentGoals}`
  },
  {
    path: `${routes.clinics}${routes.study}${routes.subject}${routes.encounters}`
  },
  {
    path: `${routes.clinics}${routes.study}${routes.subject}${routes.subjectSignatures}`
  },
  { path: `${routes.clinics}${routes.study}${routes.subject}` }
];

export const studyRoutes = [
  { path: routes.studies },
  { path: routes.clinics },
  { path: routes.account },
  { path: `${routes.admin}/*` }
];

export const SE_REPEAT_KEY = "StudyEventRepeatKey";
export const IG_REPEAT_KEY = "ItemGroupRepeatKey";
export const PROFILE_VIEW = "ProfileView";
export const ONBOARDING = "Onboarding";
export const ID_ITEMGROUP = "idItemGroup";
export const SSO_CODE = "code";
export const SSO_STATE = "state";
export const SSO_STATE_WORKSPACE_PARAM = "ws";
export const SSO_STATE_VERIFY_PARAM = "verify";
export const SSO_STATE_INVITE_PARAM = "invite";

export default routes;
