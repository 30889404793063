import { ITEM_GROUP_LEVEL_QUERY } from "@interface/constants";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (): null => {
  const { pathname, search } = useLocation();

  const prevSearchParam = useRef<string>();
  useEffect(() => {
    if (
      prevSearchParam.current?.includes(ITEM_GROUP_LEVEL_QUERY) &&
      !search.toString().includes(ITEM_GROUP_LEVEL_QUERY)
    ) {
      prevSearchParam.current = search;
      return;
    }
    //If Pathname or search query changes then scroll to top
    window.scrollTo(0, 0);

    prevSearchParam.current = search;
  }, [pathname, search]);

  return null;
};

export default ScrollToTop;
