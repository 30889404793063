import React from "react";
import {
  Annotation,
  AnnotationStatus,
  StudyLanguage,
  SystemAnnotationWarnings
} from "@interface/types";
import { getSystemDescriptionValue } from "@interface/helpers";
import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@mui/material";
import { t } from "i18next";
import GNLogo from "@client/images/logo.svg";
import { capitalizeStringFirstLetter } from "./stringHelpers";
import { dateTimeFormatOptions } from "@interface/formats";
import i18n from "@client/i18n";

export const displayComments = (
  a: Annotation,
  primaryLanguageTag: string,
  currentLanguage?: StudyLanguage,
  includeAvatar?: boolean
) => {
  let annotationComments;
  let avatar;
  if (a.isSystemGenerated) {
    const systemWarnings: SystemAnnotationWarnings = JSON.parse(a.Comment);
    const translatedComments = getSystemDescriptionValue(
      a.Comment,
      primaryLanguageTag,
      currentLanguage,
      t("dataCollection.systemAnnotationTitle", {
        context: systemWarnings.type
      })
    );

    //If we do not include an avatar we can return with h6 styling
    if (!includeAvatar) {
      return translatedComments.map((comment, index) => {
        return comment ? (
          <Typography key={index} variant="h6">{`${comment}`}</Typography>
        ) : (
          <br key={index} />
        );
      });
    }
    //If we need to include avatar styling uses body1 and listitems
    avatar = (
      <Avatar
        alt="GN"
        src={GNLogo}
        imgProps={{ sx: { height: "100%", width: "auto" } }}
      />
    );
    annotationComments = translatedComments.map((comment, index) => {
      return comment ? (
        <Typography
          key={index}
          variant="body1"
          variantMapping={{ body1: "span" }}
          display="block"
          align="justify"
        >
          {comment}
        </Typography>
      ) : (
        <br key={index} />
      );
    });
  } else {
    //If we are not including avatar and it is not a system annotation return
    if (!includeAvatar) {
      return <Typography variant="h6">{`${a.Comment}`}</Typography>;
    }
    //If we are including an avatar and it is not a system annotation
    avatar = <Avatar>{a.DisplayUser.substring(0, 2).toUpperCase()}</Avatar>;
    annotationComments = (
      <Typography
        variant="body1"
        variantMapping={{ body1: "span" }}
        display="block"
        align="justify"
      >
        {a.Comment}
      </Typography>
    );
  }

  const date = new Date(a.Timestamp).toLocaleDateString(
    undefined,
    dateTimeFormatOptions
  );
  //Returning Styling For Comment With Avatar
  return (
    <>
      <ListItemAvatar>{avatar}</ListItemAvatar>
      <ListItemText
        primary={capitalizeStringFirstLetter(a.DisplayUser)}
        secondary={
          <React.Fragment>
            <Typography variant="caption">{date}</Typography>
            {annotationComments}
            {a.Status === AnnotationStatus.Removed && (
              <Typography color={"red"}>
                {i18n.t("dataCollection.queryRemoved")}
              </Typography>
            )}
          </React.Fragment>
        }
      />
    </>
  );
};
