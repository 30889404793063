import { getAuthCardStyle } from "@client/helpers/themeHelpers";
import { makeStyles, useAuth, useWorkspace } from "@client/providers";
import routes, {
  SSO_CODE,
  SSO_STATE,
  SSO_STATE_INVITE_PARAM,
  SSO_STATE_WORKSPACE_PARAM,
  SSO_STATE_VERIFY_PARAM
} from "@client/routes/routes";
import { CircularProgress, Paper, Theme, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { ReactComponent as GNLogo } from "@client/images/logo.svg";
import { useAnalytics } from "@client/hooks/useAnalytics";
import {
  AnalyticsAttribute,
  AnalyticsEvent,
  AuthStatus,
  VerifyAction,
  WorkspaceParameterKey
} from "@interface/types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: getAuthCardStyle(theme),
  gnLogo: {
    margin: theme.spacing(1),
    width: 45,
    height: 45
  },
  progress: {
    textAlign: "center",
    margin: theme.spacing(1)
  }
}));

const SSOLogin = () => {
  const { t } = useTranslation();
  const { currentWorkspace, getWorkspacesList, changeWorkspace } =
    useWorkspace();
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const { sendEvent } = useAnalytics();
  const { ssoLogin } = useAuth();

  //In strict mode, hooks run twice. This is a problem as asking for tokens with the same code twice is cause an error.
  //This only affects dev, but it is annoying.
  //Use technique describe here https://taig.medium.com/prevent-react-from-triggering-useeffect-twice-307a475714d7
  //The allows us to not call the ssoLogin twice with the same code.
  const initialized = useRef(false);

  const code = searchParams.get(SSO_CODE);
  const state = searchParams.get(SSO_STATE);

  const stateValues = new URLSearchParams(state ?? "");
  const ws = stateValues.get(SSO_STATE_WORKSPACE_PARAM);
  const idInvite = stateValues.get(SSO_STATE_INVITE_PARAM);

  //This page is used in the SignIn flow and the verify flow
  //The state will tell us what flow we are in
  const verifyAction = stateValues.get(
    SSO_STATE_VERIFY_PARAM
  ) as VerifyAction | null;

  useEffect(() => {
    //We have already submitted an SSO login request so do nothing.
    if (initialized.current) {
      return;
    }

    //Cognito does not support wildcard subdomains in the callback url.
    //Also, we would need to register all vanity doimains as callback urls.
    //We store what subdomain/vanity domain the user was on in the state, redirect to the correct workspace now.
    if (!currentWorkspace && ws) {
      const workspaces = getWorkspacesList();

      const wsByVanityDomain = workspaces.find((w) => {
        const vanityParam = w.Parameters.find(
          (p) => p.ParameterName === WorkspaceParameterKey.VanityDomain
        );
        if (vanityParam) {
          return vanityParam.ParameterValue === window.location.hostname;
        }
        return false;
      });

      if (wsByVanityDomain) {
        //TODO this will use the subdomain of the workspace, not the actual vanity domain
        changeWorkspace(wsByVanityDomain.idWorkspace);
      } else {
        const sd = workspaces.find((d) => d.Subdomain === ws);
        if (sd) {
          changeWorkspace(sd.idWorkspace);
        }
      }
      return;
    }

    //If we have a workspace and a code, attempt to get tokens
    if (currentWorkspace && code) {
      //Set to prevent double login attempt due to strict mode (dev only issue)
      initialized.current = true;
      ssoLogin(
        code,
        !verifyAction ? idInvite ?? undefined : undefined, //Only hand a possible invite ID when not in verify flow
        verifyAction ?? undefined
      )
        .then((res) => {
          if (verifyAction) {
            //We are in verify mode in a popup.
            //Message back the response and close ourselves.
            window.opener.postMessage(res, window.origin);
            window.close();
            return;
          }
          sendEvent(AnalyticsEvent.LoginEvent, {
            [AnalyticsAttribute.SSO]: "true"
          });
        })
        .catch((error) => {
          console.log(error);
          if (verifyAction) {
            //We are in verify mode in a popup.
            //Message back the response and close ourselves.
            window.opener.postMessage(
              { status: AuthStatus.GeneralError },
              window.origin
            );
            window.close();
            return;
          }
          sendEvent(AnalyticsEvent.AuthFail, {
            [AnalyticsAttribute.SSO]: "true"
          });
        });
    }
  }, []);

  //If we get here with no code, close or redirect to signin
  if (!code) {
    if (verifyAction) {
      window.opener.postMessage(
        { status: AuthStatus.GeneralError },
        window.origin
      );
      window.close();
    } else {
      //Redirect to signin
      return <Navigate to={routes.signin} />;
    }
  }

  return (
    <Paper className={classes.paper}>
      <GNLogo className={classes.gnLogo} />
      <Typography component="h1" variant="h5">
        {t("signIn.sso.verifying")}
      </Typography>
      <CircularProgress
        color="primary"
        variant="indeterminate"
        size={60}
        className={classes.progress}
      />
    </Paper>
  );
};

export default SSOLogin;
