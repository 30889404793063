import { ValidationResult } from "@interface/types";
import {
  AuthError,
  InviteError,
  CognitoError,
  ConnectionError
} from "@interface/errors";

class FetchClient {
  private headers: Headers = new Headers();

  constructor() {
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Charset", "utf-8");
  }

  private logResponse = (res: Response, endpoint: string) => {
    if (res.ok) {
      console.debug(`${endpoint} OK: ${res.status} - ${res.statusText}`);
    } else {
      console.error(`${endpoint} ERROR: ${res.status} - ${res.statusText}`);
    }
  };

  private getResponseOrError = async <ResponseType>(
    res: Response,
    asText = false
  ): Promise<ResponseType> => {
    if (!res.ok) {
      switch (res.status) {
        case 400: {
          const error: ValidationResult = await res.json();
          const errorString = JSON.stringify(error);
          console.error(errorString);
          throw new Error(errorString);
        }
        case 401: {
          const error = await res.json();
          throw new AuthError(error.message);
        }
        case 449: {
          const error = await res.json();
          throw new CognitoError(error.message);
        }
        case 406: {
          const error = await res.json();
          throw new InviteError(error.message);
        }
        case 504: {
          throw new ConnectionError("Gateway Timeout");
        }
        default: {
          const error = await res.json();
          throw new Error(error.message);
        }
      }
    }
    const data: ResponseType = asText ? await res.text() : await res.json();
    return data;
  };

  private async fetchJSON<ResponseType>(path: string, options?: RequestInit) {
    const appURL =
      process.env.APP_URL === "placeholder" ? undefined : process.env.APP_URL;
    const newPath = appURL
      ? `${window.location.protocol}//` + appURL.concat(path)
      : path;
    //console.log(`Hitting path ${newPath}`);
    const res = await fetch(newPath, {
      credentials: "same-origin",
      mode: "same-origin",
      ...options,
      headers: this.headers
    });

    this.logResponse(res, path);
    // fix this to handle which response we use (getresponseonerror or returntrueorerror)
    const data: ResponseType = await this.getResponseOrError<ResponseType>(res);
    return data;
  }

  private async fetchText(path: string, options?: RequestInit) {
    const appURL =
      process.env.APP_URL === "placeholder" ? undefined : process.env.APP_URL;
    const newPath = appURL
      ? `${window.location.protocol}//` + appURL.concat(path)
      : path;
    //console.log(`Hitting path ${newPath}`);
    const res = await fetch(newPath, {
      credentials: "same-origin",
      mode: "same-origin",
      ...options,
      headers: this.headers
    });

    this.logResponse(res, path);
    // fix this to handle which response we use (getresponseonerror or returntrueorerror)
    return await this.getResponseOrError<string>(res, true);
  }

  get<ResponseType>(args: { path: string; options?: RequestInit }) {
    return this.fetchJSON<ResponseType>(args.path, {
      ...args.options,
      method: "GET"
    });
  }

  getText(args: { path: string; options?: RequestInit }) {
    return this.fetchText(args.path, {
      ...args.options,
      method: "GET"
    });
  }

  post<ResponseType>(args: {
    path: string;
    body: unknown;
    options?: RequestInit;
  }) {
    return this.fetchJSON<ResponseType>(args.path, {
      ...args.options,
      body: args.body ? JSON.stringify(args.body) : undefined,
      method: "POST"
    });
  }

  put<ResponseType>(args: {
    path: string;
    body: unknown;
    options?: RequestInit;
  }) {
    return this.fetchJSON<ResponseType>(args.path, {
      ...args.options,
      body: args.body ? JSON.stringify(args.body) : undefined,
      method: "PUT"
    });
  }

  checkout<ResponseType>(args: {
    path: string;
    body: unknown;
    options?: RequestInit;
  }) {
    return this.fetchJSON<ResponseType>(args.path, {
      ...args.options,
      body: args.body ? JSON.stringify(args.body) : undefined,
      method: "CHECKOUT"
    });
  }

  copy<ResponseType>(args: { path: string; options?: RequestInit }) {
    return this.fetchJSON<ResponseType>(args.path, {
      ...args.options,
      method: "COPY"
    });
  }

  delete<ResponseType>(args: {
    path: string;
    body?: unknown;
    options?: RequestInit;
  }) {
    return this.fetchJSON<ResponseType>(args.path, {
      ...args.options,
      body: args.body ? JSON.stringify(args.body) : undefined,
      method: "DELETE"
    });
  }

  lock<ResponseType>(args: { path: string; body: unknown }) {
    return this.fetchJSON<ResponseType>(args.path, {
      body: args.body ? JSON.stringify(args.body) : undefined,
      method: "LOCK"
    });
  }

  move<ResponseType>(args: { path: string; body: unknown }) {
    return this.fetchJSON<ResponseType>(args.path, {
      body: args.body ? JSON.stringify(args.body) : undefined,
      method: "MOVE"
    });
  }

  purge<ResponseType>(args: {
    path: string;
    body: unknown;
    options?: RequestInit;
  }) {
    return this.fetchJSON<ResponseType>(args.path, {
      ...args.options,
      body: args.body ? JSON.stringify(args.body) : undefined,
      method: "PURGE"
    });
  }

  report<ResponseType>(args: {
    path: string;
    body?: unknown;
    options?: RequestInit;
  }) {
    return this.fetchJSON<ResponseType>(args.path, {
      ...args.options,
      body: args.body ? JSON.stringify(args.body) : undefined,
      method: "REPORT"
    });
  }

  unlock<ResponseType>(args: { path: string; body: unknown }) {
    return this.fetchJSON<ResponseType>(args.path, {
      body: args.body ? JSON.stringify(args.body) : undefined,
      method: "UNLOCK"
    });
  }

  setHeader(key: string, value: string) {
    this.headers.append(key, value);
    return this;
  }

  getHeader(key: string) {
    return this.headers.get(key);
  }

  setBearerAuth(token: string) {
    this.headers.append("Authorization", `Bearer ${token}`);
    return this;
  }

  setCharset(value: string) {
    this.headers.append("Charset", value);
    return this;
  }
}

export default FetchClient;
