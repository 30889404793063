import { CSSObject } from "@emotion/react";
import { WorkspaceParameter, WorkspaceParameterKey } from "@interface/types";
import { Theme, createTheme } from "@mui/material";

type ThemeParameters = {
  primaryColour?: string;
  primaryColourDark?: string;
  primaryColourLight?: string;
  secondaryColour?: string;
  secondaryColourDark?: string;
  secondaryColourLight?: string;
  tonalOffset?: number;
  backgroundDefault?: string;
};

export const getWorkspaceThemeParameters = (
  parameters: WorkspaceParameter[]
): ThemeParameters => {
  const primaryColour = parameters.find(
    (p) => p.ParameterName === WorkspaceParameterKey.PrimaryColour
  )?.ParameterValue;
  const primaryColourDark = parameters.find(
    (p) => p.ParameterName === WorkspaceParameterKey.PrimaryColourDark
  )?.ParameterValue;
  const primaryColourLight = parameters.find(
    (p) => p.ParameterName === WorkspaceParameterKey.PrimaryColourLight
  )?.ParameterValue;
  const secondaryColour = parameters.find(
    (p) => p.ParameterName === WorkspaceParameterKey.SecondaryColour
  )?.ParameterValue;
  const secondaryColourDark = parameters.find(
    (p) => p.ParameterName === WorkspaceParameterKey.SecondaryColourDark
  )?.ParameterValue;
  const secondaryColourLight = parameters.find(
    (p) => p.ParameterName === WorkspaceParameterKey.SecondaryColourLight
  )?.ParameterValue;
  const tonalOffsetString = parameters.find(
    (p) => p.ParameterName === WorkspaceParameterKey.TonalOffset
  )?.ParameterValue;
  const tonalOffset = tonalOffsetString
    ? parseFloat(tonalOffsetString)
    : undefined;
  const backgroundDefault = parameters.find(
    (p) => p.ParameterName === WorkspaceParameterKey.BackgroundDefault
  )?.ParameterValue;

  return {
    primaryColour,
    primaryColourDark,
    primaryColourLight,
    secondaryColour,
    secondaryColourDark,
    secondaryColourLight,
    tonalOffset,
    backgroundDefault
  };
};

export const buildWorkspaceTheme = (
  baseTheme: Theme,
  parameters: WorkspaceParameter[]
): Theme => {
  const {
    primaryColour,
    primaryColourDark,
    primaryColourLight,
    secondaryColour,
    secondaryColourDark,
    secondaryColourLight,
    tonalOffset,
    backgroundDefault
  } = getWorkspaceThemeParameters(parameters);
  const wsTheme = createTheme({
    palette: {
      primary: {
        main: primaryColour || baseTheme.palette.primary.main,
        light: primaryColourLight || baseTheme.palette.primary.light,
        dark: primaryColourDark || baseTheme.palette.primary.dark
      },
      secondary: {
        main: secondaryColour || baseTheme.palette.secondary.main
      },
      tonalOffset: tonalOffset || baseTheme.palette.tonalOffset,
      background: {
        default: backgroundDefault || baseTheme.palette.background.default
      }
    }
  });

  //We set these after as an empty string breaks the colour interpolation and we dont want to use the base theme values.
  if (secondaryColourDark) {
    wsTheme.palette.secondary.dark = secondaryColourDark;
  }

  if (secondaryColourLight) {
    wsTheme.palette.secondary.light = secondaryColourLight;
  }

  return { ...baseTheme, ...{ palette: wsTheme.palette } };
};

export const getRHFEditorDisplayStyles = (theme: Theme): CSSObject => {
  return {
    p: {
      ...theme.typography.body1,
      margin: 0
    } as CSSObject,
    h1: {
      ...theme.typography.h2,
      margin: 0,
      marginBottom: theme.spacing(1)
    } as CSSObject,
    h2: {
      ...theme.typography.h3,
      margin: 0,
      marginBottom: theme.spacing(1)
    } as CSSObject,
    h3: {
      ...theme.typography.h4,
      margin: 0,
      marginBottom: theme.spacing(1)
    } as CSSObject,
    h4: {
      ...theme.typography.h5,
      margin: 0,
      marginBottom: theme.spacing(1)
    } as CSSObject,
    h5: {
      ...theme.typography.h6,
      margin: 0,
      marginBottom: theme.spacing(1)
    } as CSSObject,
    h6: {
      ...theme.typography.subtitle1,
      margin: 0,
      marginBottom: theme.spacing(1)
    } as CSSObject,
    a: {
      ...theme.typography.body1,
      color: theme.palette.primary.main,
      textDecoration: "none"
    } as CSSObject,
    "a:hover": {
      textDecoration: "underline"
    }
  };
};

//Get a unified style for our sign in and sign up cards
export const getAuthCardStyle = (theme: Theme): CSSObject => {
  return {
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing("auto", 2, "auto", 2),
      width: "100%"
    },
    ["@container auth (width <= 600px)"]: {
      //When shown in the verify auth dialog, fill the width
      width: "100%"
    },
    width: "30%",
    padding: theme.spacing(2),
    display: "flex",
    minHeight: 420,
    flexDirection: "column",
    alignItems: "center"
  };
};
