import { Ability, AbilityBuilder } from "@casl/ability";
import { UserRole, AppType, StudyStatus, UserStatus, UserScope, UserPermission } from "../types";
export const defineAbilityForStudy = async (user, idWorkspace, studyOID, studyUser) => {
    const builder = new AbilityBuilder(Ability);
    // Check whether the given user has an active workspace role
    // getUser function already filters out deactivated Workspaces
    const workspaceRole = user.workspaceRoles.find((workspaceRole) => {
        return (workspaceRole.idWorkspace === idWorkspace &&
            workspaceRole.status === UserStatus.Available);
    });
    if (!workspaceRole) {
        throw new Error(`User does not have access to workspace ${idWorkspace}.`);
    }
    switch (studyUser) {
        case undefined:
            if (workspaceRole.scope === UserScope.Local) {
                throw new Error(`User does not have access to study ${studyOID}`);
            }
            break;
        default:
            if (studyUser.status === UserStatus.Deactivated) {
                throw new Error(`User does not have access to study ${studyOID}`);
            }
            break;
    }
    if (!studyUser) {
        throw new Error(`User does not have access to study ${studyOID}`);
    }
    switch (studyUser.scope) {
        case UserScope.Local:
            if (typeof localStudyPermissions[studyUser.userRole.userRole] === "function") {
                // If the user role does not return a function, this block will fail
                localStudyPermissions[studyUser.userRole.userRole](studyUser, builder);
            }
            else {
                throw new Error(`Trying to use unknown role "${studyUser.userRole.userRole}"`);
            }
            break;
        case UserScope.Global:
            if (typeof globalStudyPermissions[studyUser.userRole.userRole] ===
                "function") {
                // If the user role does not return a function, this block will fail
                globalStudyPermissions[studyUser.userRole.userRole](studyUser, builder);
            }
            else {
                throw new Error(`Trying to use unknown role "${studyUser.userRole.userRole}"`);
            }
            break;
    }
    return { studyUser, studyAbility: builder.build() };
};
export const localStudyPermissions = {
    [UserRole.Admin](user, { can }) {
        //Edit Abilities
        can(["viewStudy"], "StudyDetails", {
            OID: user.OID
        });
        if (user.permission === UserPermission.ReadWrite) {
            can(["editStudy", "manageStudyUsers"], "StudyDetails", {
                OID: user.OID
            });
            can("manageStudyUser", "ManageStudyUser", {
                OID: user.OID,
                email: { $ne: user.email }
            });
        }
        //Data Collection Abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewDataExplorer",
            "viewPastFormData"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "addSubject",
                "viewSubjects",
                "relocateSubject",
                "removeSubject",
                "startStudyEvent",
                "overrideStartStudyEvent",
                "saveFormData",
                "openOrResolveQuery",
                "addComment",
                "removeComment",
                "removeQuery",
                "resetClinicalData",
                "lockData",
                "sourceDataVerification"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
            can("manageSubjectInvite", "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] },
                AppType: { $in: [AppType.CTHybrid, AppType.ClinicHybrid] }
            });
        }
        //Local roles only have access to specific locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID,
            idLocation: { $in: user.locations }
        });
    },
    [UserRole.Designer](user, { can }) {
        //Edit Abilities
        can(["viewStudy"], "StudyDetails", {
            OID: user.OID,
            StudyStatus: StudyStatus.Draft
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "editStudy",
                "editStudyDetails",
                "manageStudyVersions",
                "manageMeasurementUnit",
                "manageIECrtieria",
                "manageForm",
                "manageCodeList",
                "manageStudyEvent",
                "manageProtocol"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: StudyStatus.Draft
            });
            can(["manageLocation", "manageStudyParameters", "manageStudyFeatures"], "StudyDetails", { OID: user.OID });
        }
        //No Data collection abilities
        //Local roles only have access to specific locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID,
            idLocation: { $in: user.locations }
        });
    },
    [UserRole.Director](user, { can }) {
        can(["viewStudy", "exportStudyData", "exportQueries"], "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewDataExplorer",
            "viewPastFormData"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "addSubject",
                "relocateSubject",
                "removeSubject",
                "startStudyEvent",
                "overrideStartStudyEvent",
                "saveFormData",
                "openOrResolveQuery",
                "addComment",
                "removeComment",
                "removeQuery",
                "resetClinicalData"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: {
                    $in: [StudyStatus.Sandbox, StudyStatus.Published]
                }
            });
            can("manageSubjectInvite", "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] },
                AppType: { $in: [AppType.CTHybrid, AppType.ClinicHybrid] }
            });
        }
        //Local roles only have access to specific locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID,
            idLocation: { $in: user.locations }
        });
    },
    [UserRole.DataManager](user, { can }) {
        can(["viewStudy", "exportStudyData", "exportQueries"], "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewDataExplorer",
            "viewPastFormData"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "addSubject",
                "relocateSubject",
                "removeSubject",
                "startStudyEvent",
                "overrideStartStudyEvent",
                "saveFormData",
                "openOrResolveQuery",
                "addComment",
                "removeComment",
                "removeQuery",
                "resetClinicalData",
                "sourceDataVerification"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
            can("manageSubjectInvite", "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] },
                AppType: { $in: [AppType.CTHybrid, AppType.ClinicHybrid] }
            });
        }
        //Local roles only have access to specific locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID,
            idLocation: { $in: user.locations }
        });
    },
    [UserRole.DataMonitor](user, { can }) {
        can("viewStudy", "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewPastFormData",
            "viewDataExplorer"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can(["openOrResolveQuery", "removeQuery", "sourceDataVerification"], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
        }
        //Local roles only have access to specific locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID,
            idLocation: { $in: user.locations }
        });
    },
    [UserRole.Sponsor](user, { can }) {
        can("viewStudy", "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewPastFormData"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can(["openOrResolveQuery", "removeQuery"], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
        }
        //Local roles only have access to specific locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID,
            idLocation: { $in: user.locations }
        });
    },
    [UserRole.Investigator](user, { can }) {
        can("viewStudy", "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewPastFormData",
            "viewDataExplorer"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "addSubject",
                "relocateSubject",
                "startStudyEvent",
                "saveFormData",
                "openOrResolveQuery",
                "addComment",
                "removeComment",
                "removeQuery",
                "resetClinicalData"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
            can("manageSubjectInvite", "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] },
                AppType: { $in: [AppType.CTHybrid, AppType.ClinicHybrid] }
            });
        }
        //Local roles only have access to specific locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID,
            idLocation: { $in: user.locations }
        });
    },
    [UserRole.DataEntry](user, { can }) {
        can("viewStudy", "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewPastFormData"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "addSubject",
                "startStudyEvent",
                "saveFormData",
                "addComment",
                "removeComment",
                "removeQuery",
                "resetClinicalData"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
            can("manageSubjectInvite", "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] },
                AppType: { $in: [AppType.CTHybrid, AppType.ClinicHybrid] }
            });
        }
        //Local roles only have access to specific locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID,
            idLocation: { $in: user.locations }
        });
    },
    [UserRole.ClinicalResearchCoordinator](user, { can }) {
        can("viewStudy", "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewPastFormData"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "addSubject",
                "startStudyEvent",
                "saveFormData",
                "signData",
                "addComment",
                "removeComment",
                "removeQuery",
                "resetClinicalData"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
            can("manageSubjectInvite", "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] },
                AppType: { $in: [AppType.CTHybrid, AppType.ClinicHybrid] }
            });
        }
        //Local roles only have access to specific locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID,
            idLocation: { $in: user.locations }
        });
    },
    [UserRole.StudyParticipant](user, { can }) {
        can("viewParticipantStudy", "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can(["viewSubjects"], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can(["startStudyEvent", "saveFormData"], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
        }
        //Local roles only have access to specific locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID,
            idLocation: { $in: user.locations }
        });
    }
};
export const globalStudyPermissions = {
    [UserRole.Admin](user, { can }) {
        //Edit Abilities
        can(["exportStudyData", "viewReports", "exportQueries"], "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        can(["viewStudy"], "StudyDetails", {
            OID: user.OID
        });
        if (user.permission === UserPermission.ReadWrite) {
            can(["editStudy", "duplicateStudy", "manageStudyUsers"], "StudyDetails", {
                OID: user.OID
            });
            can("deleteStudy", "StudyDetails", {
                OID: user.OID,
                StudyStatus: StudyStatus.Draft
            });
            can([
                "editStudyDetails",
                "manageStudyVersions",
                "manageMeasurementUnit",
                "manageIECrtieria",
                "manageForm",
                "manageCodeList",
                "manageStudyEvent",
                "manageProtocol"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: StudyStatus.Draft
            });
            can([
                "manageStudyParameters",
                "manageLocation",
                "changeStudyStatus",
                "manageStudyFeatures"
            ], "StudyDetails", { OID: user.OID });
            can("manageStudyUser", "ManageStudyUser", {
                OID: user.OID,
                email: { $ne: user.email }
            });
            can("manageLocationInvite", "StudyDetails", {
                OID: user.OID,
                AppType: AppType.RWE
            });
        }
        //Data Collection Abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewDataExplorer",
            "viewPastFormData"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "addSubject",
                "relocateSubject",
                "removeSubject",
                "startStudyEvent",
                "overrideStartStudyEvent",
                "saveFormData",
                "openOrResolveQuery",
                "addComment",
                "removeComment",
                "removeQuery",
                "resetClinicalData",
                "lockData",
                "sourceDataVerification"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
            can("manageSubjectInvite", "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] },
                AppType: { $in: [AppType.CTHybrid, AppType.ClinicHybrid] }
            });
        }
        //Global roles have access to all locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID
        });
    },
    [UserRole.Designer](user, { can }) {
        //Edit Abilities
        can(["viewStudy"], "StudyDetails", { OID: user.OID });
        if (user.permission === UserPermission.ReadWrite) {
            can(["editStudy"], "StudyDetails", {
                OID: user.OID
            });
            can([
                "editStudyDetails",
                "manageStudyVersions",
                "manageMeasurementUnit",
                "manageIECrtieria",
                "manageForm",
                "manageCodeList",
                "manageStudyEvent",
                "manageProtocol",
                "manageProtocol"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: StudyStatus.Draft
            });
            can(["manageStudyParameters", "manageLocation", "manageStudyFeatures"], "StudyDetails", {
                OID: user.OID
            });
        }
        //No Data Collection Abilities
        //Global roles have access to all locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID
        });
    },
    [UserRole.Director](user, { can }) {
        can(["viewStudy", "exportStudyData", "exportQueries"], "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewDataExplorer",
            "viewPastFormData"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "addSubject",
                "relocateSubject",
                "removeSubject",
                "startStudyEvent",
                "overrideStartStudyEvent",
                "saveFormData",
                "openOrResolveQuery",
                "addComment",
                "removeComment",
                "removeQuery",
                "resetClinicalData"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
            can("manageSubjectInvite", "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] },
                AppType: { $in: [AppType.CTHybrid, AppType.ClinicHybrid] }
            });
        }
        //Global roles have access to all locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID
        });
    },
    [UserRole.DataManager](user, { can }) {
        can(["viewStudy", "exportStudyData", "exportQueries"], "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewDataExplorer",
            "viewPastFormData"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "addSubject",
                "relocateSubject",
                "removeSubject",
                "startStudyEvent",
                "overrideStartStudyEvent",
                "saveFormData",
                "openOrResolveQuery",
                "addComment",
                "removeComment",
                "removeQuery",
                "resetClinicalData",
                "sourceDataVerification"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
            can("manageSubjectInvite", "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] },
                AppType: { $in: [AppType.CTHybrid, AppType.ClinicHybrid] }
            });
        }
        //Global roles have access to all locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID
        });
    },
    [UserRole.DataMonitor](user, { can }) {
        can("viewStudy", "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewPastFormData",
            "viewDataExplorer"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can(["openOrResolveQuery", "removeQuery", "sourceDataVerification"], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
        }
        //Global roles have access to all locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID
        });
    },
    [UserRole.Sponsor](user, { can }) {
        can("viewStudy", "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewPastFormData"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can(["openOrResolveQuery", "removeQuery"], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
        }
        //Global roles have access to all locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID
        });
    },
    [UserRole.Investigator](user, { can }) {
        can("viewStudy", "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewPastFormData",
            "viewDataExplorer"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "addSubject",
                "relocateSubject",
                "startStudyEvent",
                "saveFormData",
                "openOrResolveQuery",
                "addComment",
                "removeComment",
                "removeQuery",
                "resetClinicalData"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
            can("manageSubjectInvite", "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] },
                AppType: { $in: [AppType.CTHybrid, AppType.ClinicHybrid] }
            });
        }
        //Global roles have access to all locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID
        });
    },
    [UserRole.DataEntry](user, { can }) {
        can("viewStudy", "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewPastFormData"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "addSubject",
                "startStudyEvent",
                "saveFormData",
                "addComment",
                "removeComment",
                "removeQuery",
                "resetClinicalData"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
            can("manageSubjectInvite", "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] },
                AppType: { $in: [AppType.CTHybrid, AppType.ClinicHybrid] }
            });
        }
        //Global roles have access to all locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID
        });
    },
    [UserRole.ClinicalResearchCoordinator](user, { can }) {
        can("viewStudy", "StudyDetails", {
            OID: user.OID,
            StudyStatus: {
                $in: [StudyStatus.Sandbox, StudyStatus.Published]
            }
        });
        //No study edit abilities
        //Data Collection abilities
        can([
            "viewSubjects",
            "signData",
            "viewQueries",
            "respondToQuery",
            "viewComments",
            "viewFormAuditLog",
            "viewPastFormData"
        ], "StudyDetails", {
            OID: user.OID,
            StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
        });
        if (user.permission === UserPermission.ReadWrite) {
            can([
                "addSubject",
                "startStudyEvent",
                "saveFormData",
                "addComment",
                "removeComment",
                "removeQuery",
                "viewFormAuditLog",
                "viewPastFormData"
            ], "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] }
            });
            can("manageSubjectInvite", "StudyDetails", {
                OID: user.OID,
                StudyStatus: { $in: [StudyStatus.Sandbox, StudyStatus.Published] },
                AppType: { $in: [AppType.CTHybrid, AppType.ClinicHybrid] }
            });
        }
        //Global roles have access to all locations
        can("accessLocation", "AccessLocationReq", {
            OID: user.OID
        });
    },
    [UserRole.StudyParticipant](user, { cannot }) {
        cannot(["viewStudy", "viewParticipantStudy"], "StudyDetails").because(`User ${user.idUser} does not have the right privileges. Participant users cannot be global.`);
    }
};
