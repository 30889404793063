import React, { useEffect, useState } from "react";
import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Button,
  OutlinedInput,
  Theme,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles, useAuth } from "@client/providers";
import {
  AuthResponse,
  AuthResponseMFAPayload,
  VerifyAction
} from "@interface/types";
import { onlyNumbersOrEmpty } from "@interface/validation";
import { verifyUserMFAPhoneNumber } from "@client/api/userApi";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import routes from "@client/routes/routes";

const useStyles = makeStyles()((theme: Theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  submit: {
    fontSize: "1.2em",
    margin: theme.spacing(3, 0, 2)
  }
}));

interface IMultiFactorPromptProps {
  responsePayload: AuthResponseMFAPayload;
  verifyAction?: VerifyAction;
  inviteId?: string;
  verifyPromiseRef?: React.MutableRefObject<
    | {
        resolve: (authRes: AuthResponse) => void;
        reject: () => void;
      }
    | undefined
  >;
  enrollMfa?: boolean;
}

const MultiFactorPrompt = ({
  responsePayload,
  verifyAction,
  inviteId,
  verifyPromiseRef,
  enrollMfa
}: IMultiFactorPromptProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { verifyMFACode } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [mfaCode, setMFACode] = useState<string>("");
  const [mfaCheckInProgress, setMFACheckInProgress] = useState<boolean>(false);

  //When the last digit is entered, auto check the code
  useEffect(() => {
    if (mfaCode && mfaCode.length === 6) {
      mfaCheck();
    }
  }, [responsePayload, mfaCode]);

  const handleRedirect = () => {
    //If we came from security page redirect back there after
    if (state?.name === routes.security) {
      navigate(`${routes.account}${routes.security}`);
    } else {
      navigate(routes.root);
    }
  };

  const mfaCheck = () => {
    if (mfaCode) {
      setMFACheckInProgress(true);
      if (enrollMfa) {
        verifyUserMFAPhoneNumber(mfaCode)
          .then(() => {
            setMFACode("");
            enqueueSnackbar(
              t("mfaEnrollment.verifyMFAMethodStep.validVerificationCode"),
              { variant: "success" }
            );
            handleRedirect();
          })
          .catch((error: Error) => {
            console.log(error);
            enqueueSnackbar(
              t("mfaEnrollment.verifyMFAMethodStep.invalidVerificationCode"),
              { variant: "error" }
            );
          })
          .finally(() => {
            setMFACheckInProgress(false);
          });
      } else {
        verifyMFACode(
          responsePayload.mfaSession,
          responsePayload.userId,
          mfaCode,
          inviteId,
          verifyAction
        )
          .then((authRes) => {
            setMFACode("");
            if (verifyPromiseRef?.current) {
              verifyPromiseRef.current.resolve(authRes);
            }
          })
          .catch((error: Error) => {
            console.log(error);
            if (verifyPromiseRef?.current) {
              verifyPromiseRef.current.reject();
            }
          })
          .finally(() => {
            setMFACheckInProgress(false);
          });
      }
    }
  };

  return (
    <>
      {!enrollMfa && (
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
      )}
      <Typography component="h1" variant="h5">
        {t("signIn.mfa.header")}
      </Typography>
      <OutlinedInput
        value={mfaCode}
        fullWidth
        onChange={(e) => {
          if (onlyNumbersOrEmpty.test(e.target.value)) {
            setMFACode(e.target.value);
          }
        }}
        inputProps={{
          type: "text",
          inputMode: "numeric",
          pattern: "[0-9]{6,6}",
          placeholder: "######",
          autocomplete: "one-time-code"
        }}
      />
      <Button
        onClick={mfaCheck}
        variant="contained"
        color="success"
        className={classes.submit}
        disabled={mfaCheckInProgress}
        fullWidth
      >
        {t("actions.verify")}
      </Button>
      <Typography variant="body1" style={{ textAlign: "center" }}>
        {t("signIn.mfa.body", { sentTo: responsePayload.sentTo })}
      </Typography>
      {!enrollMfa && (
        <Typography
          variant="body1"
          style={{ textAlign: "center", marginTop: "5px" }}
        >
          {t("signIn.mfa.help")}
        </Typography>
      )}
    </>
  );
};
export default MultiFactorPrompt;
