import { AuthResponse, VerifyAction } from "@interface/types";
import FetchClient from "./FetchClient";

const BASE = "/api/auth";

export const postLogin = async (req: {
  email: string;
  password: string;
  verifyAction?: VerifyAction;
}): Promise<AuthResponse> => {
  const path = `${BASE}/login`;
  return new FetchClient().post<AuthResponse>({
    path,
    body: req
  });
};

export const getSessionLogout = async (): Promise<void> => {
  const path = `${BASE}/sessionLogout`;
  new FetchClient().get({ path });
  return;
};

export const createAccount = async (req: {
  email: string;
  password: string;
  idInvite?: string;
}): Promise<AuthResponse> => {
  const path = `${BASE}/createAccount`;

  return new FetchClient().post<AuthResponse>({ path, body: req });
};

export const emailSupport = async (req: {
  title: string;
  description: string;
  attachment?: string;
  email: string;
  studyId: string;
  idWorkspace: string;
}) => {
  const path = `${BASE}/emailSupport`;

  return new FetchClient().post({ path, body: req });
};

export const sendEmailVerification = async (req: { email: string }) => {
  const path = `${BASE}/emailVerification`;

  return new FetchClient().post({ path, body: req });
};

export const sendPasswordResetEmail = async (req: { email: string }) => {
  const path = `${BASE}/passwordReset`;

  return new FetchClient().post({ path, body: req });
};

export const verifyUserEmail = async (req: { token: string }) => {
  const path = `${BASE}/verifyEmail`;
  return new FetchClient().post({ path, body: req });
};

export const verifyResetPassword = async (req: {
  token: string;
  newPassword: string;
}) => {
  const path = `${BASE}/verifyResetPassword`;
  return new FetchClient().post({ path, body: req });
};

export const verifyUpdatePassword = async (req: {
  email: string;
  currentPassword: string;
  newPassword: string;
}) => {
  const path = `${BASE}/verifyUpdatePassword`;
  return new FetchClient().post({ path, body: req });
};

export const compromisedPasswordCheck = async (req: {
  password: string;
}): Promise<boolean> => {
  const path = `${BASE}/compromisedPasswordCheck`;
  return new FetchClient().post({ path, body: req });
};

export const verifyMFA = async (req: {
  mfaSession: string;
  userId: string;
  mfaCode: string;
  verifyAction?: VerifyAction;
}): Promise<AuthResponse> => {
  const path = `${BASE}/verifyMFA`;
  return new FetchClient().post<AuthResponse>({ path, body: req });
};

export const checkEmail = async (req: {
  email: string;
  idInvite?: string;
}): Promise<AuthResponse> => {
  const path = `${BASE}/checkEmail`;
  return new FetchClient().post<AuthResponse>({ path, body: req });
};

export const postSSOLogin = async (req: {
  code: string;
  verifyAction?: VerifyAction;
}): Promise<AuthResponse> => {
  const path = `${BASE}/ssoLogin`;
  return new FetchClient().post<AuthResponse>({ path, body: req });
};
